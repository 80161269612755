import React, { useState } from 'react'
import { BigNumber } from 'ethers'
import { useUpdateMargin } from '../../hooks/contracts/useUpdateMargin'
import { ensureVaultId, useOwnVault } from '../../hooks/query/useOwnVault'
import { useVault } from '../../hooks/query/useVault'
import { toScaled, toUnscaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import { AmountForm } from '../options/trade/AmountForm'
import pendingStore from '../../store/pending'
import PendingIndicator from '../common/PendingIndicator'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import crossIcon from '../../assets/close.png'

type Props = {
  onClose: () => void
}

export const WithdrawModal = ({ onClose }: Props) => {
  const updateMargin = useUpdateMargin()
  const { isPendingTx, setPendingTx } = pendingStore()

  const vaultIds = useOwnVault()
  const vaultId = ensureVaultId(vaultIds)
  const vaultQuery = useVault(vaultId)
  const vaultStatusQuery = useVaultStatus(vaultId)

  const [amount, setAmount] = useState(0)

  const onWithdrawMargin = async (amount: BigNumber) => {
    if (vaultId < 0 || !vaultQuery.isSuccess) {
      return
    }

    const tx = await updateMargin.mutateAsync({
      vaultId,
      deltaMarginAmount: amount.mul(-1)
    })

    await setPendingTx(tx)
  }

  const marginAvailable = vaultStatusQuery.isSuccess
    ? vaultStatusQuery.data.totalValue.sub(vaultStatusQuery.data.minCollateral)
    : BigNumber.from(0)

  const vaultAvailable = vaultStatusQuery.isSuccess
    ? vaultStatusQuery.data.marginValue.lte(marginAvailable)
      ? vaultStatusQuery.data.marginValue
      : marginAvailable
    : BigNumber.from(0)

  const enoughUSDCInVault = vaultAvailable.gte(toScaled(amount, 6))

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-end">
        <div
          className="rounded-lg bg-white text-[#809fb8] w-6 h-6 flex items-center justify-center"
          onClick={onClose}
        >
          <img src={crossIcon} />
        </div>
      </div>
      <div className="flex items-center justify-start text-xl">Withdraw</div>
      <div className="px-6">
        <div className="flex items-center justify-center text-sm text-subtext font-normal">
          Predy is a non-custodial protocol; only the wallet can access the
          vault account. You are responsible for your wallet security and can
          withdraw the available cryptocurrency anytime.
        </div>
        <div className="flex items-center justify-center pt-8">
          <AmountForm
            title={'Amount'}
            unit={'USDC'}
            amount={amount}
            onChange={amount => {
              setAmount(amount)
            }}
          />
        </div>
        <div className="flex items-center justify-between text-subtext px-4 pt-4">
          <div>In Vault Available</div>
          <div>{toUnscaled(vaultAvailable, 6, 2)} USDC</div>
        </div>
        <div className="flex items-center justify-center pt-8">
          <div className="w-40 p-1">
            <PrimaryButton
              disabled={!enoughUSDCInVault || isPendingTx}
              onClick={() => {
                onWithdrawMargin(toScaled(amount, 6))
              }}
            >
              {isPendingTx ? <PendingIndicator /> : 'Withdraw'}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
