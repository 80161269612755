import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Position } from '../../utils/uni'
import { Reader__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { useEffect, useState } from 'react'
import { MARGIN_STAY, REFETCH_INTERVAL, STALE_TIME } from '../../constants'
import { usePrice } from '../usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'
import { isValidPosition } from '../../utils/position'
import { Multicall__factory } from '../../typechain/multicall'

type PositionUpdateResult = {
  requiredAmounts: BigNumber[]
  feeAmounts: BigNumber[]
  positionAmounts: BigNumber[]
  swapAmounts: BigNumber[]
  subVaultsFeeAmounts: BigNumber[][]
  subVaultsPositionAmounts: BigNumber[][]
}

type QuoterResult =
  | {
      error: string
      data: null
    }
  | {
      error: null
      data: PositionUpdateResult
    }

export type CloseQuoterResult =
  | {
      error: string
      data: null
      subVaultId: number
    }
  | {
      error: null
      data: PositionUpdateResult
      subVaultId: number
    }

function convertToPositionUpdateResult(data: any) {
  return {
    requiredAmounts: data[0],
    feeAmounts: data[1],
    positionAmounts: data[2],
    swapAmounts: data[3],
    subVaultsFeeAmounts: [],
    subVaultsPositionAmounts: []
  }
}

export function useOpenPositionQuoter(vaultId: number, position: Position) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const deadline = useDeadline()
  const price = usePrice()

  const [amounts, setAmounts] = useState<QuoterResult>({
    error: 'not loaded',
    data: null
  })

  const quoterQuery = useQuery<QuoterResult>(
    ['position_quoter', account, chainId, vaultId, position],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')
      if (!price.isSuccess) throw new Error('price not set')

      const contract = Reader__factory.connect(addresses.Reader, provider)

      try {
        const data = await contract.callStatic.quoteOpenPosition(
          vaultId,
          position,
          {
            isLiquidationCall: false,
            swapAnyway: true,
            quoterMode: true,
            isQuoteZero: false,
            marginMode0: MARGIN_STAY,
            marginMode1: MARGIN_STAY,
            deltaMarginAmount0: 0,
            deltaMarginAmount1: 0,
            metadata: '0x'
          },
          {
            lowerSqrtPrice: computeLowerSqrtPrice(price.data.sqrtIndexPrice),
            upperSqrtPrice: computeUpperSqrtPrice(price.data.sqrtIndexPrice),
            swapRatio: 0,
            deadline: deadline.data
          },
          { from: account }
        )

        return {
          error: null,
          data: convertToPositionUpdateResult(data)
        }
      } catch (e: any) {
        if (e.error && e.error.message === "Non-200 status code: '429'") {
          throw new Error(e.error.message)
        }
        return {
          error: String(e.reason || e.data.message),
          data: null
        }
      }
    },
    {
      enabled:
        vaultId >= 0 &&
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        deadline.isSuccess &&
        price.isSuccess &&
        isValidPosition(position),
      refetchInterval: REFETCH_INTERVAL
    }
  )

  useEffect(() => {
    if (quoterQuery.isSuccess) {
      setAmounts(quoterQuery.data)
    }
  }, [quoterQuery.isSuccess, quoterQuery.data])

  return amounts
}

export function useClosePositionQuoter(vaultId: number, subVaultIds: number[]) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const deadline = useDeadline()

  const [amounts, setAmounts] = useState<CloseQuoterResult[]>([])

  const quoterQuery = useQuery<CloseQuoterResult[]>(
    ['close_position_quoter', account, chainId, vaultId, subVaultIds],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!deadline.isSuccess) throw new Error('deadline not set')

      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const reader = Reader__factory.connect(addresses.Reader, provider)

      const tradeOption = {
        isLiquidationCall: false,
        swapAnyway: true,
        quoterMode: true,
        isQuoteZero: false,
        marginMode0: MARGIN_STAY,
        marginMode1: MARGIN_STAY,
        deltaMarginAmount0: 0,
        deltaMarginAmount1: 0,
        metadata: '0x'
      }

      const calls = subVaultIds.map(subVaultId => {
        return {
          target: addresses.Reader,
          callData: reader.interface.encodeFunctionData('quoteCloseSubVault', [
            vaultId,
            subVaultId,
            tradeOption,
            {
              lowerSqrtPrice: 0,
              upperSqrtPrice: ethers.constants.MaxUint256,
              swapRatio: 100,
              closeRatio: '10000',
              deadline: deadline.data
            }
          ])
        }
      })

      const results = await multicall.callStatic.tryAggregate(false, calls, {
        from: account
      })

      return results.map((result, i) => {
        if (result.success) {
          const data = reader.interface.decodeFunctionResult(
            'quoteCloseSubVault',
            result.returnData
          )

          return {
            error: null,
            data: convertToPositionUpdateResult(data[0]),
            subVaultId: subVaultIds[i]
          }
        } else {
          return {
            error: String(result.returnData),
            data: null,
            subVaultId: subVaultIds[i]
          }
        }
      })
    },

    {
      enabled:
        vaultId >= 0 &&
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        deadline.isSuccess,
      staleTime: STALE_TIME
    }
  )

  useEffect(() => {
    if (quoterQuery.isSuccess) {
      setAmounts(quoterQuery.data)
    }
  }, [quoterQuery.isSuccess, quoterQuery.data])

  return amounts
}
