import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { decodeMetadata } from '../../utils/metadata'
import {
  VaultHistoryItems,
  VAULT_HISTORY_ITEM_QUERY
} from '../../queries/vaultHistoryItemQuery'
import { calculateSizeFromLPTs, calculateStrikesFromLPTs } from './useSubVaults'
import { usePredyContext } from './protocol/usePredyContext'
import { roundContractSize, tradeTypeToString } from '../../utils'
import { toUnscaled } from '../../utils/bn'
import { toAmountString, toPriceString } from '../../utils/number'
import { TradeType } from '../../constants/enum'

export type SubVaultItem = {
  id: string
  vaultId: number
  strikes: number[]
  size: string
  feeValue: BigNumber | undefined
  pnlValue: BigNumber | undefined
  action: string
  openTimestamp: number
  closeTimestamp: number
  openTxHash: string
  closeTxHash: string | null
}

export const NUM_ONE_PAGE_ITEMS = 500

export function useVaultHistory(vaultId: number) {
  const context = usePredyContext()
  const [skip, setSkip] = useState(0)
  const [vaultHistory, setVaultHistory] = useState<SubVaultItem[]>([])

  const { data } = useQuery<VaultHistoryItems>(VAULT_HISTORY_ITEM_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      vaultId: vaultId.toString(),
      skip: skip,
      first: NUM_ONE_PAGE_ITEMS
    },
    pollInterval: 30000
  })

  useEffect(() => {
    if (data) {
      const newItems = data.vaultHistoryItems.map(entity => {
        let action = ''
        let strikes: number[] = []
        let feeValue = undefined
        let pnlValue = undefined
        let size = ''

        if (entity.action === 'POSITION') {
          const lpts = entity.subVault.lpts.map(lpt => {
            const liquidityHistory = lpt.liquidityHistory.map(item => ({
              liquidity: BigNumber.from(item.liquidity),
              timestamp: Number(item.createdAt)
            }))
            return {
              isCollateral: lpt.isCollateral,
              rangeId: lpt.rangeId,
              liquidity: BigNumber.from(lpt.liquidity),
              liquidityHistory
            }
          })

          strikes = calculateStrikesFromLPTs(lpts)
          feeValue = context.isMarginZero
            ? BigNumber.from(entity.subVault.feeAmount0)
            : BigNumber.from(entity.subVault.feeAmount1)
          pnlValue = context.isMarginZero
            ? BigNumber.from(entity.subVault.totalAmount0).mul(-1)
            : BigNumber.from(entity.subVault.totalAmount1).mul(-1)

          if (entity.metadata) {
            action = tradeTypeToString(decodeMetadata(entity.metadata))
          }

          size = toAmountString(
            roundContractSize(calculateSizeFromLPTs(lpts, true))
          )
        } else if (entity.action === 'MARGIN') {
          const marginAmount = BigNumber.from(entity.size)

          if (
            entity.metadata &&
            decodeMetadata(entity.metadata) === TradeType.MARGIN
          ) {
            action = marginAmount.gte(0) ? 'Deposit' : 'Withdraw'
          } else {
            action = marginAmount.gte(0) ? 'Supply' : 'Withdraw'
          }

          size =
            '$' +
            toPriceString(toUnscaled(BigNumber.from(entity.size).abs(), 6, 2))
        } else if (entity.action === 'LIQUIDATION') {
          action = 'Pay liquidation fee'

          size =
            '$' + toPriceString(toUnscaled(BigNumber.from(entity.size), 6, 2))
        }

        return {
          id: entity.id,
          vaultId,
          action,
          strikes,
          size,
          feeValue,
          pnlValue,
          openTimestamp: Number(entity.openAt),
          closeTimestamp: Number(entity.closeAt),
          openTxHash: entity.openTxHash,
          closeTxHash: entity.closeTxHash
        }
      })
      setVaultHistory(vaultHistory => {
        return vaultHistory
          .concat(newItems)
          .map(item => Object.assign({}, item))
          .sort((a, b) => b.openTimestamp - a.openTimestamp)
          .filter(
            (val, ind, self) =>
              ind === self.findIndex(item => item.id === val.id)
          )
      })
    }
  }, [vaultId, data, context.isMarginZero])

  return {
    vaultHistory,
    skip,
    setSkip
  }
}
