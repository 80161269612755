import { gql } from '@apollo/client'

export const FEE_PREMIUM_GROWTH_DAILY_QUERY = gql`
  query ($lowerTicks: [BigInt]) {
    feeAndPremiumGrowthTxDailies(
      first: 1000
      where: { lowerTick_in: $lowerTicks }
      orderBy: updatedAt
      orderDirection: desc
    ) {
      id
      lowerTick
      upperTick
      fee0Growth
      fee1Growth
      premiumGrowthForBorrower
      premiumGrowthForLender
      updatedAt
    }
  }
`

export type FeeAndPremiumGrowthTxDailies = {
  feeAndPremiumGrowthTxDailies: {
    id: string
    lowerTick: string
    upperTick: string
    fee0Growth: string
    fee1Growth: string
    premiumGrowthForBorrower: string
    premiumGrowthForLender: string
    updatedAt: string
  }[]
}

export const INTEREST_GROWTH_DAILY_QUERY = gql`
  query {
    interestScalerTxDailies(
      first: 100
      orderBy: updatedAt
      orderDirection: desc
    ) {
      id
      assetGrowth0
      debtGrowth0
      assetGrowth1
      debtGrowth1
      updatedAt
    }
  }
`

export type InterestScalerTxDailies = {
  interestScalerTxDailies: {
    id: string
    assetGrowth0: string
    debtGrowth0: string
    assetGrowth1: string
    debtGrowth1: string
    updatedAt: string
  }[]
}

export const UNI_FEE_GROWTH_HOURLY_QUERY = gql`
  query {
    uniFeeGrowthHourlies(first: 50, orderBy: updatedAt, orderDirection: desc) {
      id
      feeGrowthGlobal0X128
      feeGrowthGlobal1X128
      updatedAt
    }
  }
`

export type UniFeeGrowthHoulies = {
  uniFeeGrowthHourlies: {
    id: string
    feeGrowthGlobal0X128: string
    feeGrowthGlobal1X128: string
    updatedAt: string
  }[]
}
