import { BigNumber } from 'ethers'
import { calculateDebtValue } from '../position'
import {
  getAmountsForLiquidity,
  getSqrtRatioAtTick,
  LPT,
  Position,
  priceToSqrtPrice,
  Q96,
  tickToPrice
} from '../uni'
import { sqrt, toScaled, toUnscaled } from '../bn'
import { UNDERLYING_ONE, ZERO } from '../../constants'

export function calculateMinVaultValue(position: Position, price: BigNumber) {
  const positionValue = calculatePositionValue(position, price, false)
  const minValue = calculateMinValue(position, price)
  const debtValue = calculateDebtValue(position, price)

  let minVaultValue = positionValue.sub(minValue)

  if (minVaultValue.lt(0)) {
    minVaultValue = ZERO
  }

  minVaultValue = minVaultValue.add(
    debtValue.mul(calculateRequiredCollateralWithDebt(debtValue)).div(1000000)
  )

  if (minVaultValue.lt('1000000')) {
    return BigNumber.from('1000000')
  }

  return minVaultValue
}

const BASE_MIN_COLLATERAL_WITH_DEBT = BigNumber.from(20000)
const MIN_COLLATERAL_WITH_DEBT_SLOPE = BigNumber.from(50)

function calculateRequiredCollateralWithDebt(debtValue: BigNumber) {
  const a = MIN_COLLATERAL_WITH_DEBT_SLOPE.mul(
    sqrt(debtValue.mul(1000000))
  ).div(1000000)
  const b = BASE_MIN_COLLATERAL_WITH_DEBT
  return a.gt(b) ? a : b
}

export function calculateMinValue(position: Position, price: BigNumber) {
  const lowerValue = calculatePositionValue(
    position,
    price.mul(118).div(100),
    false
  )
  const upperValue = calculatePositionValue(
    position,
    price.mul(100).div(118),
    false
  )

  const values = [lowerValue, upperValue].concat(
    position.lpts
      .filter(lpt => !lpt.isCollateral)
      .map(lpt => {
        const price = calculateMinSqrtPrice(lpt.lowerTick, lpt.upperTick)

        return calculatePositionValue(
          position,
          price,
          true,
          lpt.lowerTick,
          lpt.upperTick
        )
      })
  )

  return toScaled(Math.min(...values.map(value => toUnscaled(value, 6))), 6)
}

export function calculateMinSqrtPrice(lower: number, upper: number) {
  const tick = (lower + upper) / 2
  return tickToPrice(tick)
}

function calculatePositionValue(
  position: Position,
  price: BigNumber,
  isMin: boolean,
  lower?: number,
  upper?: number
) {
  const sqrtPrice = priceToSqrtPrice(price)

  const amounts = getAmounts(position, sqrtPrice, isMin, lower, upper)

  return amounts[0].mul(price).div(UNDERLYING_ONE).add(amounts[1])
}

function calculateLPTMinValue(lpt: LPT) {
  return lpt.liquidity
    .mul(
      getSqrtRatioAtTick(lpt.upperTick).sub(getSqrtRatioAtTick(lpt.lowerTick))
    )
    .div(Q96)
}

function getAmounts(
  position: Position,
  sqrtPrice: BigNumber,
  isMin: boolean,
  lower?: number,
  upper?: number
) {
  let amount0 = BigNumber.from(0)
  let amount1 = BigNumber.from(0)
  amount0 = amount0.add(position.asset0)
  amount1 = amount1.add(position.asset1)
  amount0 = amount0.sub(position.debt0)
  amount1 = amount1.sub(position.debt1)

  for (const lpt of position.lpts) {
    if (isMin && !lpt.isCollateral) {
      if (lpt.lowerTick == lower && upper == lpt.upperTick) {
        amount1 = amount1.sub(calculateLPTMinValue(lpt))
        continue
      }
    }

    const amounts = getAmountsForLiquidity(
      sqrtPrice,
      getSqrtRatioAtTick(lpt.lowerTick),
      getSqrtRatioAtTick(lpt.upperTick),
      lpt.liquidity
    )
    if (lpt.isCollateral) {
      amount0 = amount0.add(amounts[0])
      amount1 = amount1.add(amounts[1])
    } else {
      amount0 = amount0.sub(amounts[0])
      amount1 = amount1.sub(amounts[1])
    }
  }

  return [amount0, amount1]
}
