import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import { UNDERLYING_ONE } from '../../../constants'
import {
  AccumulatedProtocolFeeDailies,
  ACCUMULATED_PROTOCOL_FEE_DAILY
} from '../../../queries/protocolFeeQuery'
import { toUnscaled } from '../../../utils/bn'
import { useCachedPrice } from '../../usePrice'

export function useAccumulatedProtocolFee() {
  const price = useCachedPrice()

  const { data } = useQuery<AccumulatedProtocolFeeDailies>(
    ACCUMULATED_PROTOCOL_FEE_DAILY,
    {
      fetchPolicy: 'cache-and-network',
      variables: {},
      pollInterval: 30000
    }
  )

  const protocolFeeDailies = useMemo(() => {
    if (data) {
      const accumulatedProtocolFeeDailies =
        data.accumulatedProtocolFeeDailies.map(entity => {
          const accumulatedProtocolFee0 = BigNumber.from(
            entity.accumulatedProtocolFee0
          )
          const accumulatedProtocolFee1 = BigNumber.from(
            entity.accumulatedProtocolFee1
          )
          const updateAt = Number(entity.updatedAt)

          return {
            accumulatedProtocolFee0,
            accumulatedProtocolFee1,
            updateAt
          }
        })

      return accumulatedProtocolFeeDailies
        .map(item => {
          return {
            value: toUnscaled(
              item.accumulatedProtocolFee0
                .mul(price.indexPrice)
                .div(UNDERLYING_ONE)
                .add(item.accumulatedProtocolFee1),
              6
            ),
            time: item.updateAt
          }
        })
        .map((item, i, acc) => {
          if (i < acc.length - 1) {
            return {
              value: item.value - acc[i + 1].value,
              time: item.time
            }
          } else {
            return {
              value: item.value,
              time: item.time
            }
          }
        })
    }

    return null
  }, [data, price.indexPrice])

  if (data === undefined || protocolFeeDailies === null) {
    return []
  }

  return protocolFeeDailies
}
