import React from 'react'
import { ensureVaultId, useOwnVault } from '../../../hooks/query/useOwnVault'
import { BigNumber } from 'ethers'
import { toTimeString } from '../../../utils/string'
import { toUnscaled } from '../../../utils/bn'
import {
  NUM_ONE_PAGE_ITEMS,
  useVaultHistory
} from '../../../hooks/query/useVaultHistory'
import { useChainId } from '../../../hooks/network'

interface Props {
  vaultId: number
  productName: string
  strikes: number[]
  size: string
  feeValue?: BigNumber
  pnlValue?: BigNumber
  openTimestamp: number
  closeTimestamp: number
  openTxHash: string
  closeTxHash: string | null
  blockExplorerLink: string
}

const HistoryItem = (props: Props) => {
  return (
    <tr className="h-12">
      <td className="py-2">{props.productName}</td>
      <td>
        <a
          href={`https://${props.blockExplorerLink}/tx/${props.openTxHash}`}
          target="_blank"
          rel="noreferrer"
        >
          {toTimeString(props.openTimestamp)}
        </a>
      </td>
      <td>
        {props.strikes
          .sort()
          .map(strikePrice => strikePrice.toString())
          .join(',')}
      </td>
      <td>{props.size}</td>
      <td>
        {props.closeTxHash ? (
          <a
            href={`https://${props.blockExplorerLink}/tx/${props.closeTxHash}`}
            target="_blank"
            rel="noreferrer"
          >
            {props.closeTimestamp > 0 ? toTimeString(props.closeTimestamp) : ''}
          </a>
        ) : props.closeTimestamp > 0 ? (
          toTimeString(props.closeTimestamp)
        ) : (
          ''
        )}
      </td>
      <td>
        {props.feeValue ? toUnscaled(props.feeValue, 6, 2) + ' USDC' : ''}{' '}
      </td>
      <td>
        {props.pnlValue ? toUnscaled(props.pnlValue, 6, 2) + ' USDC' : ''}{' '}
      </td>
    </tr>
  )
}

const History = () => {
  const vaultIds = useOwnVault()
  const { vaultHistory, setSkip } = useVaultHistory(ensureVaultId(vaultIds))
  const chainId = useChainId()

  return (
    <div className="px-8 py-6">
      <table className="border-collapse table-fixed text-left">
        <thead className="text-sm text-subtext font-normal">
          <tr>
            <th className="w-40 pb-2 font-normal">Contract/Action</th>
            <th className="w-40 font-normal">Opening Date</th>
            <th className="w-40 font-normal">Strike</th>
            <th className="w-40 font-normal">Size</th>
            <th className="w-40 font-normal">Closing Date</th>
            <th className="w-40 font-normal">Interest Paid/Earned</th>
            <th className="w-40 font-normal">Payoff</th>
          </tr>
        </thead>
        <tbody className="text-xs text-subtext font-normal items-center align-center">
          {vaultIds.isAvailable && vaultIds.data.length > 0 ? (
            vaultHistory.map((prop, i) => {
              return (
                <HistoryItem
                  key={i}
                  vaultId={prop.vaultId}
                  strikes={prop.strikes}
                  size={prop.size}
                  feeValue={prop.feeValue}
                  pnlValue={prop.pnlValue}
                  productName={prop.action}
                  openTimestamp={prop.openTimestamp}
                  closeTimestamp={prop.closeTimestamp}
                  openTxHash={prop.openTxHash}
                  closeTxHash={prop.closeTxHash}
                  blockExplorerLink={
                    chainId === 42161 ? 'arbiscan.io' : 'goerli.arbiscan.io'
                  }
                />
              )
            })
          ) : (
            <div>Please trade first.</div>
          )}
        </tbody>
      </table>
      <div className="flex items-center justify-center">
        {vaultHistory.length >= NUM_ONE_PAGE_ITEMS ? (
          <button
            onClick={() => {
              setSkip(vaultHistory.length)
            }}
          >
            More
          </button>
        ) : (
          <div />
        )}
      </div>
    </div>
  )
}

export default History
