import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import {
  ACTION_BORROW_TOKEN,
  ACTION_DEPOSIT_TOKEN,
  ACTION_REPAY_TOKEN,
  ACTION_WITHDRAW_TOKEN,
  MARGIN_STAY,
  MARGIN_USE,
  UNDERLYING_ONE
} from '../../constants'
import { encodeMetadata } from '../../utils/metadata'
import { TradeType } from '../../constants/enum'
import { usePrice } from '../usePrice'

type TradeParams = {
  vaultId: number
  subVaultId: number
  isLong: boolean
  amount: BigNumberish
  subVaultPosition: {
    collateralAmount0: BigNumber
    collateralAmount1: BigNumber
    debtAmount0: BigNumber
    debtAmount1: BigNumber
  }
  lowerSqrtPrice: BigNumberish
  upperSqrtPrice: BigNumberish
}

function getPositionUpdates(
  subVaultId: number,
  subVaultPosition: {
    collateralAmount0: BigNumber
    collateralAmount1: BigNumber
    debtAmount0: BigNumber
    debtAmount1: BigNumber
  },
  isLong: boolean,
  amount: BigNumberish,
  price: BigNumber
) {
  const positionUpdates = []

  function getPositionUpdate(
    positionUpdateType: number,
    isTokenZero: boolean,
    amount: BigNumberish
  ) {
    return {
      positionUpdateType,
      subVaultId,
      zeroForOne: false,
      liquidity: 0,
      lowerTick: 0,
      upperTick: 0,
      param0: isTokenZero ? amount : 0,
      param1: isTokenZero ? 0 : amount
    }
  }

  if (isLong) {
    if (subVaultPosition.debtAmount0.gt(amount)) {
      positionUpdates.push(getPositionUpdate(ACTION_REPAY_TOKEN, true, amount))
      positionUpdates.push(
        getPositionUpdate(
          ACTION_WITHDRAW_TOKEN,
          false,
          subVaultPosition.collateralAmount1
            .mul(amount)
            .div(subVaultPosition.debtAmount0)
        )
      )
    } else {
      if (subVaultPosition.debtAmount0.gt(0)) {
        positionUpdates.push(
          getPositionUpdate(
            ACTION_REPAY_TOKEN,
            true,
            subVaultPosition.debtAmount0
          )
        )
        positionUpdates.push(
          getPositionUpdate(
            ACTION_WITHDRAW_TOKEN,
            false,
            subVaultPosition.collateralAmount1
          )
        )
      }

      const longAmount = BigNumber.from(amount).sub(
        subVaultPosition.debtAmount0
      )
      const usdcAmount = longAmount.mul(price).div(UNDERLYING_ONE)

      positionUpdates.push(
        getPositionUpdate(ACTION_DEPOSIT_TOKEN, true, longAmount)
      )

      positionUpdates.push(
        getPositionUpdate(ACTION_BORROW_TOKEN, false, usdcAmount)
      )
    }
  } else {
    if (subVaultPosition.collateralAmount0.gt(amount)) {
      positionUpdates.push(
        getPositionUpdate(ACTION_WITHDRAW_TOKEN, true, amount)
      )
      positionUpdates.push(
        getPositionUpdate(
          ACTION_REPAY_TOKEN,
          false,
          subVaultPosition.debtAmount1
            .mul(amount)
            .div(subVaultPosition.collateralAmount0)
        )
      )
    } else {
      if (subVaultPosition.collateralAmount0.gt(0)) {
        positionUpdates.push(
          getPositionUpdate(
            ACTION_WITHDRAW_TOKEN,
            true,
            subVaultPosition.collateralAmount0
          )
        )
        positionUpdates.push(
          getPositionUpdate(
            ACTION_REPAY_TOKEN,
            false,
            subVaultPosition.debtAmount1
          )
        )
      }
      const shortAmount = BigNumber.from(amount).sub(
        subVaultPosition.collateralAmount0
      )

      const usdcAmount = shortAmount.mul(price).div(UNDERLYING_ONE)

      positionUpdates.push(
        getPositionUpdate(ACTION_BORROW_TOKEN, true, shortAmount)
      )

      positionUpdates.push(
        getPositionUpdate(ACTION_DEPOSIT_TOKEN, false, usdcAmount)
      )
    }
  }

  return positionUpdates
}

export function useTrade() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const deadline = useDeadline()
  const price = usePrice()

  return useMutation(async (params: TradeParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')
    if (!price.isSuccess) throw new Error('price not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.updatePosition(
      params.vaultId,
      getPositionUpdates(
        params.subVaultId,
        params.subVaultPosition,
        params.isLong,
        params.amount,
        price.data.indexPrice
      ),
      {
        isLiquidationCall: false,
        swapAnyway: true,
        quoterMode: false,
        isQuoteZero: false,
        marginMode0: MARGIN_STAY,
        marginMode1: MARGIN_USE,
        deltaMarginAmount0: 0,
        deltaMarginAmount1: 0,
        metadata: encodeMetadata(TradeType.HEDGE)
      },
      {
        lowerSqrtPrice: params.lowerSqrtPrice,
        upperSqrtPrice: params.upperSqrtPrice,
        swapRatio: 0,
        deadline: deadline.isSuccess ? deadline.data : 0
      }
    )
  })
}
