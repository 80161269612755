import React from 'react'
import { BigNumber } from 'ethers'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import { toUnscaled } from '../../utils/bn'
import HorizontalRule from './HorizontalRule'
import { useOwnVault } from '../../hooks/query/useOwnVault'
import { decodeMetadata, encodeMetadata } from '../../utils/metadata'
import { TradeType } from '../../constants/enum'
import { useVault } from '../../hooks/query/useVault'
import { useWithdraw } from '../../hooks/contracts/useSupply'
import { UNDERLYING_ONE } from '../../constants'
import { useSubVaults } from '../../hooks/query/useSubVaults'

const YourSupplies = ({ vaultId }: { vaultId: number }) => {
  const vaultIds = useOwnVault()
  const vaultStatus = useVaultStatus(vaultId)
  const vault = useVault(vaultId)
  const subVaults = useSubVaults(vaultId) || []

  const withdraw = useWithdraw()

  const lendingSubVaultIndex = getLendingSubVaultIndex()
  const lendingSubVaultId = getLendingSubVaultId()

  const collateralAmount0 =
    lendingSubVaultIndex >= 0 &&
    vaultStatus.isSuccess &&
    vaultStatus.data.subVaults.length > 0
      ? vaultStatus.data.subVaults[lendingSubVaultIndex].amount
          .collateralAmount0
      : BigNumber.from(0)
  const collateralAmount1 =
    lendingSubVaultIndex >= 0 &&
    vaultStatus.isSuccess &&
    vaultStatus.data.subVaults.length > 0
      ? vaultStatus.data.subVaults[lendingSubVaultIndex].amount
          .collateralAmount1
      : BigNumber.from(0)

  function getLendingSubVaultId() {
    if (!vaultIds.isAvailable || vaultIds.data.length == 0) {
      return -1
    }
    const lendingSubVault = subVaults.filter(
      subVault => decodeMetadata(subVault.metadata) === TradeType.LENDING
    )

    if (lendingSubVault.length == 0) {
      return -1
    }

    return lendingSubVault[0].subVaultId
  }
  function getLendingSubVaultIndex() {
    if (!vaultIds.isAvailable || vaultIds.data.length == 0) {
      return -1
    }
    const lendingSubVault = subVaults.filter(
      subVault => decodeMetadata(subVault.metadata) === TradeType.LENDING
    )

    if (lendingSubVault.length == 0) {
      return -1
    }

    return vault.isSuccess
      ? vault.data.subVaults
          .map(id => id.toNumber())
          .indexOf(lendingSubVault[0].subVaultId)
      : 0
  }

  const onWithdraw = async (isUSDC: boolean) => {
    if (!vaultIds.isAvailable) {
      return
    }

    let subVaultId = 0

    if (vaultIds.data.length > 0) {
      subVaultId = subVaults.length

      if (lendingSubVaultId >= 0) {
        subVaultId = lendingSubVaultId
      }
    }

    console.log(subVaultId)

    if (isUSDC) {
      await withdraw.mutateAsync({
        vaultId,
        subVaultId,
        supplyAmount0: 0,
        supplyAmount1: BigNumber.from('100000000'),
        metadata: encodeMetadata(TradeType.LENDING),
        isUSDC
      })
    } else {
      await withdraw.mutateAsync({
        vaultId,
        subVaultId,
        supplyAmount0: UNDERLYING_ONE.mul(21),
        supplyAmount1: 0,
        metadata: encodeMetadata(TradeType.LENDING),
        isUSDC
      })
    }

    // Set pending
    // await setPendingTx(tx)
  }
  return (
    <div className="rounded-lg bg-secondaly leading-5 p-1">
      <div className="flex flex-wrap justify-between items-center px-4 py-4 space-x-0">
        <div className="w-32">Your supplies</div>
      </div>

      <HorizontalRule />

      <div className="p-6">
        <table className="border-collapse table-fixed text-left">
          <thead className="text-[12px] text-subtext font-medium">
            <tr>
              <th className="w-40 pb-2">Asset</th>
              <th className="w-40">Balance</th>
              <th className="w-40"></th>
            </tr>
          </thead>
          <tbody className="text-sm font-normal items-center align-center">
            <tr>
              <td>ETH</td>
              <td>{toUnscaled(collateralAmount0, 18, 6)} ETH</td>
              <td>
                <button
                  onClick={() => {
                    onWithdraw(false)
                  }}
                >
                  Withdraw
                </button>
              </td>
            </tr>
            <tr>
              <td>USDC</td>
              <td>{toUnscaled(collateralAmount1, 6, 6)} USDC</td>
              <td>
                <button
                  onClick={() => {
                    onWithdraw(true)
                  }}
                >
                  Withdraw
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default YourSupplies
