import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { calculateGasMargin } from '../../utils'
import { useDeadline } from '../useBlockTimestamp'
import { MARGIN_USE } from '../../constants'

type CloseSubVaultParams = {
  vaultId: number
  subVaultId: number
  lowerSqrtPrice: BigNumberish
  upperSqrtPrice: BigNumberish
  swapRatio: number
}

export function useCloseSubVault() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const deadline = useDeadline()

  return useMutation(async (params: CloseSubVaultParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    const args = [
      params.vaultId,
      params.subVaultId,
      {
        isLiquidationCall: false,
        swapAnyway: true,
        quoterMode: false,
        isQuoteZero: false,
        marginMode0: MARGIN_USE,
        marginMode1: MARGIN_USE,
        deltaMarginAmount0: 0,
        deltaMarginAmount1: 0,
        metadata: '0x'
      },
      {
        lowerSqrtPrice: params.lowerSqrtPrice,
        upperSqrtPrice: params.upperSqrtPrice,
        swapRatio: params.swapRatio,
        closeRatio: '10000',
        deadline: deadline.isSuccess ? deadline.data : 0
      }
    ] as const

    const gasUsage = await contract.estimateGas.closeSubVault(...args)

    return await contract.closeSubVault(...args, {
      gasLimit: calculateGasMargin(gasUsage)
    })
  })
}
