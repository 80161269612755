import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { STALE_TIME } from '../../constants'
import { toUnscaled } from '../../utils/bn'
import { Position } from '../../utils/uni'
import { useMinVaultValue } from './useMinVaultValue'
import { useVaultStatus } from './useVaultStatus'

interface MarginUtilizing {
  before: number
  after: number
  marginUtilizing: number
}

export function useMarginUtilizing(vaultId: number, position: Position) {
  const vaultStatus = useVaultStatus(vaultId)
  const afterMinVaultValue = useMinVaultValue(vaultId, position)

  const [marginUtilizing, setMarginUtilizing] = useState<MarginUtilizing>({
    before: 0,
    after: 0,
    marginUtilizing: 0
  })

  const query = useQuery(
    ['margin_utilizing', vaultId, position],

    async () => {
      if (!afterMinVaultValue.isSuccess)
        throw new Error('afterMinVaultValue not set')
      if (!vaultStatus.isSuccess) throw new Error('vaultStatus not set')

      const before = toUnscaled(vaultStatus.data.minCollateral, 6, 2)
      const after = toUnscaled(afterMinVaultValue.data, 6, 2)

      return {
        before,
        after,
        marginUtilizing: after - before
      }
    },
    {
      enabled: vaultStatus.isSuccess && afterMinVaultValue.isSuccess,
      staleTime: STALE_TIME,
      refetchInterval: 15000
    }
  )

  useEffect(() => {
    if (query.isSuccess) {
      setMarginUtilizing(query.data)
    }
  }, [query.isSuccess, query.data])

  return marginUtilizing
}
