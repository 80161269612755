import { useQuery } from 'react-query'
import { usePrice } from './usePrice'
import { getTicks, LPRange } from '../utils/ranges'
import { useEffect, useState } from 'react'

export function useTicks() {
  const price = usePrice()

  const [ticks, setTicks] = useState<LPRange[]>([])

  const ticksQuery = useQuery(
    ['ticks'],
    async () => {
      if (!price.isSuccess) throw new Error('price not set')

      return getTicks(price.data.indexPrice)
    },
    {
      enabled: price.isSuccess
    }
  )

  useEffect(() => {
    if (ticksQuery.isSuccess) {
      setTicks(ticksQuery.data)
    }
  }, [ticksQuery.isSuccess, ticksQuery.data, price.isSuccess])

  return ticks
}
