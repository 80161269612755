import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'
import { useCachedPrice } from '../../usePrice'
import { Position, plusPosition } from '../../../utils/uni'
import { computeTokenInterestForPosition } from '../../../utils/position'
import { toUnscaled } from '../../../utils/bn'
import { roundDigit } from '../../../utils/number'
import { useTokenInterestInNext24h } from './useTokenInterestInNext24h'
import { useLPTPremiumInNext24h } from './useLPTPremiumInNext24h'

export function useTradeInterestEst(
  position: Position,
  tradePosition: Position
) {
  const interestEstBefore = usePositionInterestEst(position)

  const interestEstAfter = usePositionInterestEst(
    plusPosition(position, tradePosition),
    tradePosition
  )

  return useQuery(
    ['trade_interest_est', position, tradePosition],
    async () => {
      if (!interestEstBefore.isSuccess)
        throw new Error('interestEstBefore not set')
      if (!interestEstAfter.isSuccess)
        throw new Error('interestEstAfter not set')

      const before = roundDigit(interestEstBefore.data, 2)
      const after = roundDigit(interestEstAfter.data, 2)

      return {
        before,
        after,
        diff: after - before
      }
    },
    {
      enabled: interestEstBefore.isSuccess && interestEstAfter.isSuccess
    }
  )
}

export function usePositionInterestEst(
  position: Position,
  tradePosition?: Position
) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const tokenInterestInNext24h = useTokenInterestInNext24h(tradePosition)
  const lptPremiumInNext24h = useLPTPremiumInNext24h(position, tradePosition)
  const price = useCachedPrice()

  return useQuery(
    ['position_interest_est', chainId, position, tradePosition, price.price],

    async () => {
      if (!account) throw new Error('account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!tokenInterestInNext24h.isSuccess)
        throw new Error('tokenInterestInNext24h not set')
      if (!lptPremiumInNext24h.isSuccess)
        throw new Error('lptPremiumInNext24h not set')

      const tokeInterest = toUnscaled(
        computeTokenInterestForPosition(
          position,
          {
            assetGrowth0: tokenInterestInNext24h.data[0].supplyRate,
            assetGrowth1: tokenInterestInNext24h.data[1].supplyRate,
            debtGrowth0: tokenInterestInNext24h.data[0].borrowRate,
            debtGrowth1: tokenInterestInNext24h.data[1].borrowRate,
            timestamp: 0
          },
          price.price
        ),
        6
      )

      const lptPremium = lptPremiumInNext24h.data

      return tokeInterest + lptPremium
    },

    {
      enabled:
        supportedChain &&
        !!account &&
        !!provider &&
        !!addresses &&
        tokenInterestInNext24h.isSuccess &&
        lptPremiumInNext24h.isSuccess &&
        price.price.gt(0),
      staleTime: 1000
    }
  )
}
