import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../../network'
import {
  LPT_RESERVE_FACTOR,
  ONE,
  STALE_TIME,
  UNDERLYING_ONE,
  ZERO
} from '../../../constants'
import { BigNumber, ethers } from 'ethers'
import { LPT, Position, priceToTick, Q128 } from '../../../utils/uni'
import { createRangeId } from '../../../utils/rangeId'
import { useAddresses } from '../../useAddress'
import { Multicall__factory } from '../../../typechain/multicall'
import { Controller__factory, Reader__factory } from '../../../typechain'
import { ONE_DAY } from '../../../utils/interest'
import { toUnscaled } from '../../../utils/bn'
import { useUniswapTradeFee24H } from './useUniswapTradeFee'
import { useCachedPrice } from '../../usePrice'
import { useLPTIRMParams } from '../useIRMParams'
import { calculateLPTValue } from '../../../utils/position'
import { calculateInterestRate } from '../../../utils/irm'

export function useLPTPremiumInNext24h(
  afterPosition: Position,
  tradePosition?: Position
) {
  const { provider } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const uniFee24h = useUniswapTradeFee24H()
  const price = useCachedPrice()
  const lptIRMParams = useLPTIRMParams()

  return useQuery(
    [
      'lpt_premium_within_24h',
      chainId,
      afterPosition,
      tradePosition,
      price.price
    ],

    async () => {
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!uniFee24h.isSuccess) throw new Error('uniFee24h not set')

      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const reader = Reader__factory.connect(addresses.Reader, provider)
      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      let tradeFee = uniFee24h.data.fee0
        .mul(price.price)
        .div(UNDERLYING_ONE)
        .add(uniFee24h.data.fee1)

      const isOutOfRangFlags: boolean[] = []

      const calls = afterPosition.lpts.map(lpt => {
        const lower = lpt.lowerTick
        const upper = lpt.upperTick
        const rangeId = createRangeId(lower, upper)

        // check the LPT is in range or not
        {
          const tick = priceToTick(price.price)

          if (tick < lpt.lowerTick || lpt.upperTick < tick) {
            isOutOfRangFlags.push(true)
            return {
              target: addresses.Controller,
              callData: controller.interface.encodeFunctionData(
                'getUtilizationRatio',
                [rangeId]
              )
            }
          }

          isOutOfRangFlags.push(false)
        }

        let isBorrow = false
        let deltaLiquidity = BigNumber.from(0)

        // Estimates the interest after trade
        if (tradePosition) {
          const tradeLpt = tradePosition.lpts.find(
            lpt => lpt.lowerTick === lower
          )

          if (tradeLpt) {
            deltaLiquidity = tradeLpt.liquidity
            isBorrow = !tradeLpt.isCollateral

            if (tradeLpt.isCollateral) {
              // TODO: how to get total liquidity of Uniswap pool
              const uniLiquidity = BigNumber.from('300000000000000000')
              const estUniUtilization = uniLiquidity
                .mul(ONE)
                .div(uniLiquidity.add(tradeLpt.liquidity))

              tradeFee = tradeFee.mul(estUniUtilization).div(ONE)
            }
          }
        }

        return {
          target: addresses.Reader,
          callData: reader.interface.encodeFunctionData('calculateLPTPremium', [
            rangeId,
            isBorrow,
            deltaLiquidity,
            ONE_DAY * 60,
            tradeFee
          ])
        }
      })

      const calculateLPTPremiumResult = await multicall.callStatic.tryAggregate(
        false,
        calls
      )

      const estimations = calculateLPTPremiumResult.map((r, i) => {
        const lpt: LPT = afterPosition.lpts[i]

        if (r.success) {
          if (isOutOfRangFlags[i]) {
            const decoded = controller.interface.decodeFunctionResult(
              'getUtilizationRatio',
              r.returnData
            )

            let supply = decoded[0]
            let borrow = decoded[1]

            if (tradePosition) {
              const tradeLpt = tradePosition.lpts.find(
                tradeLPT => tradeLPT.lowerTick === lpt.lowerTick
              )

              if (tradeLpt) {
                if (tradeLpt.isCollateral) {
                  supply = supply.add(tradeLpt.liquidity)
                } else {
                  borrow = borrow.add(tradeLpt.liquidity)
                }
              }
            }

            if (supply.eq(0)) {
              return 0
            }

            const borrowRate = calculateInterestRate(
              lptIRMParams,
              borrow.mul(ONE).div(supply)
            ).div(365)
            const supplyRate = supply.gt(0)
              ? borrowRate
                  .mul(borrow)
                  .div(supply)
                  .mul(BigNumber.from(100).sub(LPT_RESERVE_FACTOR))
                  .div(100)
              : ZERO

            const lptValue = calculateLPTValue(
              lpt,
              price.price,
              price.sqrtPrice
            )

            return lpt.isCollateral
              ? toUnscaled(lptValue.mul(supplyRate).div(ONE), 6)
              : -toUnscaled(lptValue.mul(borrowRate).div(ONE), 6)
          } else {
            const decoded = reader.interface.decodeFunctionResult(
              'calculateLPTPremium',
              r.returnData
            )

            const borrowInterest = decoded[0]
              .mul(lpt.liquidity)
              .div(UNDERLYING_ONE)
            const supplyInterest = decoded[1]
              .mul(lpt.liquidity)
              .div(UNDERLYING_ONE)
            const fee = decoded[3].mul(lpt.liquidity).div(Q128)

            return lpt.isCollateral
              ? toUnscaled(supplyInterest.add(fee), 6)
              : -toUnscaled(borrowInterest, 6)
          }
        } else {
          console.warn('error on Reader', r.returnData.toString())
          const fee = tradeFee.mul(lpt.liquidity).div(Q128)

          return lpt.isCollateral ? toUnscaled(fee, 6) : 0
        }
      })

      // sum all
      return estimations.reduce((acc, item) => acc + item, 0)
    },

    {
      enabled:
        supportedChain &&
        !!provider &&
        !!addresses &&
        uniFee24h.isSuccess &&
        price.price.gt(0),
      staleTime: STALE_TIME
    }
  )
}
