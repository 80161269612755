/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import { Provider } from '@ethersproject/providers'
import type { IReader, IReaderInterface } from '../IReader'

const _abi = [
  {
    inputs: [],
    name: 'getIndexPrice',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getPrice',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'isMarginZero',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
]

export class IReader__factory {
  static readonly abi = _abi
  static createInterface(): IReaderInterface {
    return new utils.Interface(_abi) as IReaderInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IReader {
    return new Contract(address, _abi, signerOrProvider) as IReader
  }
}
