import React from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line
} from 'recharts'
import { useAccumulatedProtocolFee } from '../../hooks/query/protocol/useAccumulatedProtocolFee'
import { toPriceString } from '../../utils/number'
import { toDateString } from '../../utils/string'

export const ProtocolRevenueChart = () => {
  const data = useAccumulatedProtocolFee()

  if (data.length === 0) {
    return <div>Loading</div>
  }

  const CustomizedXAxisTick = (props: any) => {
    const { x, y, payload } = props

    const timestamp = payload ? payload.value : 0

    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text
          x={0}
          y={0}
          dy={14}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {toDateString(timestamp)}
        </text>
      </g>
    )
  }

  const CustomizedYAxisTick = (props: any) => {
    const { x, y, payload } = props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={0}
          dy={0}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          ${payload ? toPriceString(payload.value) : ''}
        </text>
      </g>
    )
  }

  return (
    <div>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={data}>
          <CartesianGrid stroke="#4c525f" />
          <XAxis
            type="number"
            dataKey="time"
            tickCount={19}
            interval={3}
            tickLine={false}
            domain={['dataMin', 'dataMax']}
            tick={<CustomizedXAxisTick />}
          />
          <YAxis
            type="number"
            domain={[0, 50]}
            tickCount={5}
            tickLine={false}
            tick={<CustomizedYAxisTick />}
          />
          <Tooltip />
          <Line
            type="monotone"
            name="Value"
            dataKey={'value'}
            stroke="#257EFF"
            strokeWidth={1}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
