import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { calculateGasMargin } from '../../utils'
import { useDeadline } from '../useBlockTimestamp'
import { MARGIN_STAY, MARGIN_USE } from '../../constants'
import { encodeMetadata } from '../../utils/metadata'
import { TradeType } from '../../constants/enum'

type UpdateMarginParams = {
  vaultId: number
  deltaMarginAmount: BigNumberish
}

export function useUpdateMargin() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const deadline = useDeadline()

  return useMutation(async (params: UpdateMarginParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    const args = [
      params.vaultId,
      [] as {
        positionUpdateType: BigNumberish
        subVaultId: BigNumberish
        zeroForOne: boolean
        liquidity: BigNumberish
        lowerTick: BigNumberish
        upperTick: BigNumberish
        param0: BigNumberish
        param1: BigNumberish
      }[],
      {
        isLiquidationCall: false,
        swapAnyway: true,
        quoterMode: false,
        isQuoteZero: false,
        marginMode0: MARGIN_STAY,
        marginMode1: MARGIN_USE,
        deltaMarginAmount0: 0,
        deltaMarginAmount1: params.deltaMarginAmount,
        metadata: encodeMetadata(TradeType.MARGIN)
      },
      {
        lowerSqrtPrice: 0,
        upperSqrtPrice: ethers.constants.MaxUint256,
        swapRatio: 0,
        deadline: deadline.isSuccess ? deadline.data : 0
      }
    ] as const

    const gasUsage = await contract.estimateGas.updatePosition(...args)

    return await contract.updatePosition(...args, {
      gasLimit: calculateGasMargin(gasUsage)
    })
  })
}
