import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import { UNDERLYING_ONE } from '../../../constants'
import {
  LPRevenueDaily,
  LP_REVENUE_DAILY_QUERY
} from '../../../queries/protocolFeeQuery'
import { toUnscaled } from '../../../utils/bn'
import { useCachedPrice } from '../../usePrice'

export function useLPRevenueDaily() {
  const price = useCachedPrice()

  const { data } = useQuery<LPRevenueDaily>(LP_REVENUE_DAILY_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {},
    pollInterval: 30000
  })

  const lpRevenueDailies = useMemo(() => {
    if (data) {
      const lpRevenueDaily = data.lprevenueDailies.map(entity => {
        const fee0 = BigNumber.from(entity.fee0)
        const fee1 = BigNumber.from(entity.fee1)
        const premiumSupply = BigNumber.from(entity.premiumSupply)
        const premiumBorrow = BigNumber.from(entity.premiumBorrow)
        const supplyInterest0 = BigNumber.from(entity.supplyInterest0)
        const supplyInterest1 = BigNumber.from(entity.supplyInterest1)
        const borrowInterest0 = BigNumber.from(entity.borrowInterest0)
        const borrowInterest1 = BigNumber.from(entity.borrowInterest1)
        const updateAt = Number(entity.updatedAt)

        return {
          fee0,
          fee1,
          premiumSupply,
          premiumBorrow,
          supplyInterest0,
          supplyInterest1,
          borrowInterest0,
          borrowInterest1,
          updateAt
        }
      })

      return lpRevenueDaily.map(item => {
        return {
          lptRevenue: toUnscaled(
            item.fee0
              .mul(price.indexPrice)
              .div(UNDERLYING_ONE)
              .add(item.fee1)
              .add(item.premiumSupply),
            6
          ),
          premiumRevenue: toUnscaled(item.premiumSupply, 6),
          tokenRevenue: toUnscaled(
            item.supplyInterest0
              .mul(price.indexPrice)
              .div(UNDERLYING_ONE)
              .add(item.supplyInterest1),
            6
          ),
          tokenPaid: toUnscaled(
            item.borrowInterest0
              .mul(price.indexPrice)
              .div(UNDERLYING_ONE)
              .add(item.borrowInterest1),
            6
          ),
          time: item.updateAt
        }
      })
    }

    return null
  }, [data, price.indexPrice])

  if (data === undefined || lpRevenueDailies === null) {
    return []
  }

  return lpRevenueDailies
}
