import { gql } from '@apollo/client'

export const LP_REVENUE_DAILY_QUERY = gql`
  query {
    lprevenueDailies(first: 1000, orderBy: updatedAt, orderDirection: desc) {
      id
      fee0
      fee1
      premiumSupply
      premiumBorrow
      supplyInterest0
      supplyInterest1
      borrowInterest0
      borrowInterest1
      updatedAt
    }
  }
`

export type LPRevenueDaily = {
  lprevenueDailies: {
    id: string
    fee0: string
    fee1: string
    premiumSupply: string
    premiumBorrow: string
    supplyInterest0: string
    supplyInterest1: string
    borrowInterest0: string
    borrowInterest1: string
    updatedAt: string
  }[]
}

export const ACCUMULATED_PROTOCOL_FEE_DAILY = gql`
  query {
    accumulatedProtocolFeeDailies(
      first: 1000
      orderBy: updatedAt
      orderDirection: desc
    ) {
      id
      accumulatedProtocolFee0
      accumulatedProtocolFee1
      updatedAt
    }
  }
`

export type AccumulatedProtocolFeeDailies = {
  accumulatedProtocolFeeDailies: {
    id: string
    accumulatedProtocolFee0: string
    accumulatedProtocolFee1: string
    updatedAt: string
  }[]
}
