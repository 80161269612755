import { useQuery } from '@apollo/client'
import { BigNumber } from 'ethers'
import { useMemo } from 'react'
import {
  FeeAndPremiumGrowthTxDailies,
  FEE_PREMIUM_GROWTH_DAILY_QUERY,
  InterestScalerTxDailies,
  INTEREST_GROWTH_DAILY_QUERY,
  UniFeeGrowthHoulies,
  UNI_FEE_GROWTH_HOURLY_QUERY
} from '../../../queries/interestQuery'
import { LPTInterestGrowth } from '../../../utils/interest'
import { UNDERLYING_ONE } from '../../../constants'
import { getStrikePriceOfRange } from '../../../utils'

const POLLING_INTERVAL = 120000

type PremiumGrowthHistoryItem = {
  lowerTick: number
  upperTick: number
  fee0Growth: BigNumber
  fee1Growth: BigNumber
  premiumGrowthForBorrower: BigNumber
  premiumGrowthForLender: BigNumber
  timestamp: number
}

function convertPremiumGrowthHistory(
  premiumGrowth: PremiumGrowthHistoryItem[]
) {
  return premiumGrowth
    .map(premiumGrowthItem => {
      const { premiumGrowthForBorrower, premiumGrowthForLender } =
        premiumGrowthItem

      const strike = getStrikePriceOfRange(
        premiumGrowthItem.lowerTick,
        premiumGrowthItem.upperTick
      )

      const feeReceived = premiumGrowthItem.fee0Growth
        .mul(strike)
        .div(UNDERLYING_ONE)
        .add(premiumGrowthItem.fee1Growth)

      return {
        lowerTick: premiumGrowthItem.lowerTick,
        upperTick: premiumGrowthItem.upperTick,
        fee0Growth: premiumGrowthItem.fee0Growth,
        fee1Growth: premiumGrowthItem.fee1Growth,
        premiumGrowthForBorrower,
        premiumGrowthForLender,
        feeReceived,
        totalReceived: premiumGrowthForLender.add(feeReceived),
        totalPaid: premiumGrowthForBorrower,
        timestamp: premiumGrowthItem.timestamp
      }
    })
    .filter(item => item !== null) as LPTInterestGrowth[]
}

export function useLPTInterestGrowthHistory(lowerTicks: number[]) {
  const { data, loading } = useQuery<FeeAndPremiumGrowthTxDailies>(
    FEE_PREMIUM_GROWTH_DAILY_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {
        lowerTicks: lowerTicks
      },
      pollInterval: POLLING_INTERVAL
    }
  )

  const premiumGrowth = useMemo(() => {
    if (data) {
      return convertPremiumGrowthHistory(
        data.feeAndPremiumGrowthTxDailies.map(item => ({
          lowerTick: Number(item.lowerTick),
          upperTick: Number(item.upperTick),
          fee0Growth: BigNumber.from(item.fee0Growth),
          fee1Growth: BigNumber.from(item.fee1Growth),
          premiumGrowthForBorrower: BigNumber.from(
            item.premiumGrowthForBorrower
          ),
          premiumGrowthForLender: BigNumber.from(item.premiumGrowthForLender),
          timestamp: Math.floor(Number(item.updatedAt) / 60)
        }))
      )
    }

    return null
  }, [data])

  if (loading || premiumGrowth === null) {
    return null
  }

  return [...premiumGrowth].reverse()
}

export function useTokenInterestGrowthHistory() {
  const { data, loading } = useQuery<InterestScalerTxDailies>(
    INTEREST_GROWTH_DAILY_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {},
      pollInterval: POLLING_INTERVAL
    }
  )

  const interestGrowth = useMemo(() => {
    if (data) {
      return data.interestScalerTxDailies.map(item => ({
        assetGrowth0: BigNumber.from(item.assetGrowth0),
        debtGrowth0: BigNumber.from(item.debtGrowth0),
        assetGrowth1: BigNumber.from(item.assetGrowth1),
        debtGrowth1: BigNumber.from(item.debtGrowth1),
        timestamp: Math.floor(Number(item.updatedAt) / 60)
      }))
    }
    return null
  }, [data])

  if (loading || interestGrowth === null) {
    return null
  }

  return [...interestGrowth].reverse()
}

export function useUniFeeGrowthHourly() {
  const { data, loading } = useQuery<UniFeeGrowthHoulies>(
    UNI_FEE_GROWTH_HOURLY_QUERY,
    {
      fetchPolicy: 'cache-first',
      variables: {},
      pollInterval: 15000
    }
  )

  const uniFeeGrowth = useMemo(() => {
    if (data) {
      return data.uniFeeGrowthHourlies.map(item => ({
        feeGrowthGlobal0X128: BigNumber.from(item.feeGrowthGlobal0X128),
        feeGrowthGlobal1X128: BigNumber.from(item.feeGrowthGlobal1X128),
        timestamp: Math.floor(Number(item.updatedAt) / 60)
      }))
    }
    return null
  }, [data])

  if (loading || uniFeeGrowth === null) {
    return null
  }

  return [...uniFeeGrowth].reverse()
}
