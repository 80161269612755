import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useQuery } from 'react-query'
import { STALE_TIME } from '../../../constants'
import { Controller__factory } from '../../../typechain'
import { useChainId, useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'

export function useTokenState() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  return useQuery(
    ['token_state', chainId],

    async () => {
      if (!account) throw new Error('account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const tokenStatus = await controller.callStatic.getTokenState()

      return tokenStatus.map(item => {
        return {
          totalCompoundDeposited: item[0],
          totalCompoundBorrowed: item[1],
          totalNormalDeposited: item[2],
          totalNormalBorrowed: item[3],
          assetScaler: item[4],
          debtScaler: item[5],
          assetGrowth: item[6],
          debtGrowth: item[7]
        }
      })
    },

    {
      enabled: supportedChain && !!account && !!provider && !!addresses,
      staleTime: STALE_TIME
    }
  )
}
