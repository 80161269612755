import React from 'react'
import { BigNumber } from 'ethers'
import { useAssetStatusUnscaled } from '../../hooks/query/useAssetStatus'
import { useLPTStatus } from '../../hooks/query/useLPTStatus'
import { useCachedPrice } from '../../hooks/usePrice'
import { toUnscaled } from '../../utils/bn'
import { toFundingRateString } from '../../utils/number'
import {
  getAmountsForLiquidity,
  getSqrtRatioAtTick,
  sqrtPriceToPrice,
  tickToPrice
} from '../../utils/uni'
import HorizontalRule from '../common/HorizontalRule'
import { UNDERLYING_ONE } from '../../constants'
import { getTicks } from '../../utils/ranges'
import { useTokenInterestInNext24h } from '../../hooks/query/interest/useTokenInterestInNext24h'

const AssetStatus = () => {
  const assetStatus = useAssetStatusUnscaled()
  const price = useCachedPrice()
  const ticks = getTicks(price.indexPrice)
  const lptStatuses = useLPTStatus(ticks)
  const interestRate = useTokenInterestInNext24h()

  return (
    <div className="rounded-lg bg-secondaly leading-5 p-1">
      <div className="flex flex-wrap md:flex-no-wrap justify-between items-center px-6 py-2 space-x-0">
        <div className="w-32">Asset Status</div>
      </div>

      <HorizontalRule />

      <div className="p-6">
        <table className="border-collapse table-fixed text-left">
          <thead className="text-[12px] text-subtext font-medium">
            <tr>
              <th className="w-40 pb-2">Asset</th>
              <th className="w-40">Collateral</th>
              <th className="w-40">Debt</th>
              <th className="w-40">Utilization Ratio</th>
              <th className="w-40">APY</th>
              <th className="w-40">Borrow APY</th>
            </tr>
          </thead>
          <tbody className="text-sm font-normal items-center align-center">
            <tr className="border-b border-third">
              <td className="py-2">ETH</td>
              <td>{assetStatus[0].collateralAmount} ETH</td>
              <td>{assetStatus[0].debtAmount} ETH</td>
              <td>{toFundingRateString(assetStatus[0].utilizationRatio)} %</td>
              <td>
                {interestRate.isSuccess
                  ? toUnscaled(
                      interestRate.data[0].supplyRate.mul(36500),
                      18,
                      6
                    )
                  : 0}{' '}
                %
              </td>
              <td>
                {interestRate.isSuccess
                  ? toUnscaled(
                      interestRate.data[0].borrowRate.mul(36500),
                      18,
                      6
                    )
                  : 0}{' '}
                %
              </td>
            </tr>
            <tr>
              <td className="py-2">USDC</td>
              <td>{assetStatus[1].collateralAmount} USDC</td>
              <td>{assetStatus[1].debtAmount} USDC</td>
              <td>{toFundingRateString(assetStatus[1].utilizationRatio)} %</td>
              <td>
                {interestRate.isSuccess
                  ? toUnscaled(
                      interestRate.data[1].supplyRate.mul(36500),
                      18,
                      6
                    )
                  : 0}{' '}
                %
              </td>
              <td>
                {interestRate.isSuccess
                  ? toUnscaled(
                      interestRate.data[1].borrowRate.mul(36500),
                      18,
                      6
                    )
                  : 0}{' '}
                %
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-6">
        <table className="border-collapse table-fixed text-left">
          <thead className="text-[12px] text-subtext font-medium">
            <tr>
              <th className="w-32 pb-2">Range No.</th>
              <th className="w-32">Price Range</th>
              <th className="w-32">Collateral</th>
              <th className="w-32">Debt</th>
              <th className="w-40">Utilization Ratio</th>
              <th className="w-60">APY</th>
              <th className="w-40">Borrow APY</th>
            </tr>
          </thead>
          <tbody className="text-sm font-normal items-center align-center">
            {lptStatuses.map((lptStatus, i) => {
              const yearlyPremiumPerLiquidity = BigNumber.from(0)
              const ONE = UNDERLYING_ONE

              const amounts = getAmountsForLiquidity(
                price.sqrtPrice,
                getSqrtRatioAtTick(ticks[i].lower),
                getSqrtRatioAtTick(ticks[i].upper),
                ONE
              )

              const value = amounts[0]
                .mul(sqrtPriceToPrice(price.sqrtPrice))
                .div(ONE)
                .add(amounts[1])

              return (
                <tr key={i} className="border-b border-third">
                  <td className="py-2">{i}</td>
                  <td>
                    {toUnscaled(tickToPrice(ticks[i].lower), 6, 2)}-
                    {toUnscaled(tickToPrice(ticks[i].upper), 6, 2)}
                  </td>
                  <td>{lptStatus.collateralAmount}</td>
                  <td>{lptStatus.debtAmount}</td>
                  <td>{lptStatus.utilizationRatio * 100} %</td>
                  <td>
                    {lptStatus.collateralAmount === 0 || value.eq(0)
                      ? 0
                      : toFundingRateString(
                          (toUnscaled(
                            yearlyPremiumPerLiquidity.mul(ONE).div(value),
                            18,
                            6
                          ) *
                            lptStatus.debtAmount) /
                            lptStatus.collateralAmount
                        )}{' '}
                    % + Trade Fee*{(1 - lptStatus.utilizationRatio).toFixed(2)}
                  </td>
                  <td>
                    {value.eq(0)
                      ? 0
                      : toFundingRateString(
                          toUnscaled(
                            yearlyPremiumPerLiquidity.mul(ONE).div(value),
                            18,
                            6
                          )
                        )}{' '}
                    %
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AssetStatus
