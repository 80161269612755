import { BigNumber } from 'ethers'
import { TradeType } from '../../constants/enum'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { SubVaultEntities, SUBVAULTS_QUERY } from '../../queries/subVaultQuery'
import { usePredyContext } from './protocol/usePredyContext'
import { decodeMetadata } from '../../utils/metadata'
import {
  calculatePositionValue,
  calculateTradeAmountByLiquidity
} from '../../utils/position'
import { TICKS_WITH_RANGEID, UNDERLYING_ONE, ZERO } from '../../constants'
import { getStrikePriceByLowerTick } from '../../utils'
import { Position } from '../../utils/uni'
import { usePrice } from '../usePrice'
import { getTickIndexesFromLowerTicks } from '../../utils/ranges'
import { useVaultStatus } from './useVaultStatus'

export type SubVaultItem = {
  vaultId: number
  subVaultId: number
  subVaultIndex: number
  isRemoved: boolean
  isClosing: boolean
  strikes: number[]
  size: BigNumber
  feeValue: BigNumber
  pnlValue: BigNumber
  positionValue: BigNumber
  position: Position
  tickIndexes: number[]
  tradeType: TradeType
  metadata: string
  timestamp: number
  closeTimestamp: number
  openTxHash: string
  closeTxHash?: string
  swapRatio: BigNumber
}

function convertHistory(
  data: SubVaultEntities,
  isMarginZero: boolean,
  price: BigNumber,
  liveSubVaultIds?: BigNumber[]
) {
  const history: SubVaultItem[] = data.subVaultEntities.map(subVault => {
    let feeValue = BigNumber.from(0)
    {
      const feeAmount0 = BigNumber.from(subVault.feeAmount0)
      const feeAmount1 = BigNumber.from(subVault.feeAmount1)

      if (isMarginZero) {
        feeValue = feeAmount0
      } else {
        feeValue = feeAmount1
      }
    }

    let pnlValue = BigNumber.from(0)

    {
      const positionAmount0 = BigNumber.from(subVault.totalAmount0)
      const positionAmount1 = BigNumber.from(subVault.totalAmount1)

      if (isMarginZero) {
        pnlValue = positionAmount0.mul(-1)
      } else {
        pnlValue = positionAmount1.mul(-1)
      }
    }

    const lpts = subVault.lpts.map(lpt => {
      const liquidityHistory = lpt.liquidityHistory.map(item => ({
        liquidity: BigNumber.from(item.liquidity),
        timestamp: Number(item.createdAt)
      }))
      return {
        isCollateral: lpt.isCollateral,
        rangeId: lpt.rangeId,
        liquidity: BigNumber.from(lpt.liquidity),
        liquidityHistory
      }
    })

    const position: Position = {
      subVaultId: Number(subVault.subVaultId),
      asset0: BigNumber.from(subVault.asset0),
      asset1: BigNumber.from(subVault.asset1),
      debt0: BigNumber.from(subVault.debt0),
      debt1: BigNumber.from(subVault.debt1),
      lpts: lpts.map(lpt => {
        const tick = TICKS_WITH_RANGEID.filter(tick => {
          return tick.rangeId === lpt.rangeId
        })[0]

        return {
          isCollateral: lpt.isCollateral,
          lowerTick: tick.lower,
          upperTick: tick.upper,
          liquidity: lpt.liquidity
        }
      })
    }

    const positionValue = calculatePositionValue(position, price)

    const isClosing = liveSubVaultIds
      ? !liveSubVaultIds.find(subvaultId =>
          subvaultId.eq(Number(subVault.subVaultId))
        )
      : false

    let swapRatio = ZERO
    {
      const swapAmount0 = BigNumber.from(subVault.openTx.swapAmount0)
      const swapAmount1 = BigNumber.from(subVault.openTx.swapAmount1)

      if (!swapAmount0.eq(0)) {
        swapRatio = swapAmount1.mul(UNDERLYING_ONE).div(swapAmount0)
      }
    }

    return {
      vaultId: Number(subVault.vault.vaultId),
      subVaultId: Number(subVault.subVaultId),
      subVaultIndex: Number(subVault.subVaultIndex),
      isRemoved: !!subVault.isRemoved,
      isClosing,
      feeValue,
      pnlValue,
      positionValue,
      position,
      strikes: calculateStrikesFromLPTs(lpts),
      size: calculateSizeFromLPTs(lpts, subVault.isRemoved),
      tickIndexes: getTickIndexesFromLowerTicks(
        position.lpts.map(lpt => lpt.lowerTick)
      ),
      tradeType: decodeMetadata(subVault.openTx.metadata),
      metadata: subVault.openTx.metadata,
      timestamp: Number(subVault.openTx.createdAt),
      closeTimestamp: subVault.closeTx ? Number(subVault.closeTx.createdAt) : 0,
      openTxHash: subVault.openTx.id,
      closeTxHash: subVault.isRemoved ? subVault.closeTx.id : undefined,
      swapRatio
    }
  })

  return history.sort((a, b) => b.timestamp - a.timestamp)
}

export function useSubVaults(vaultId: number) {
  const context = usePredyContext()
  const price = usePrice()
  const vault = useVaultStatus(vaultId)

  const { data } = useQuery<SubVaultEntities>(SUBVAULTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      vaultId: vaultId.toString(),
      isRemoved: false,
      first: 100,
      skipCount: 0
    },
    pollInterval: 10000
  })

  const subVaults = useMemo(() => {
    if (data && vault.isSuccess) {
      return convertHistory(
        data,
        context.isMarginZero,
        price.isSuccess ? price.data.price : BigNumber.from(0),
        vault.data.subVaultIds
      )
    }
  }, [
    data,
    context.isMarginZero,
    price.isSuccess,
    price.data,
    vault.isSuccess,
    vault.data
  ])

  if (data === undefined || subVaults === null || subVaults === undefined) {
    return null
  }

  return subVaults
}

export function calculateStrikesFromLPTs(
  lpts: { rangeId: string; liquidity: BigNumber }[]
) {
  return lpts.map(lpt => {
    const tick = TICKS_WITH_RANGEID.filter(tick => {
      return tick.rangeId === lpt.rangeId
    })[0]

    if (tick === undefined) {
      return 0
    }

    return getStrikePriceByLowerTick(tick.lower)
  })
}

export function calculateSizeFromLPTs(
  lpts: {
    rangeId: string
    liquidity: BigNumber
    liquidityHistory: {
      liquidity: BigNumber
      timestamp: number
    }[]
  }[],
  isClosed: boolean
) {
  const sizes = lpts.map(lpt => {
    const tick = TICKS_WITH_RANGEID.filter(tick => {
      return tick.rangeId === lpt.rangeId
    })[0]

    if (tick === undefined) {
      return ZERO
    }

    const tradeAmount = calculateTradeAmountByLiquidity({
      isCollateral: true,
      lowerTick: tick.lower,
      upperTick: tick.upper,
      liquidity:
        lpt.liquidityHistory.length > 0
          ? lpt.liquidityHistory[isClosed ? 0 : lpt.liquidityHistory.length - 1]
              .liquidity
          : lpt.liquidity
    })

    return tradeAmount
  })

  if (sizes.length === 0) {
    return BigNumber.from(0)
  }

  return sizes[0]
}
