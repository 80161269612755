import { BigNumber } from 'ethers'
import { IRMParams } from '../hooks/query/useIRMParams'

const ONE = BigNumber.from('1000000000000000000')

export function calculateInterestRate(params: IRMParams, ur: BigNumber) {
  let ir = params.baseRate

  if (ur.lte(params.kinkRate)) {
    ir = ir.add(ur.mul(params.slope1).div(ONE))
  } else {
    ir = ir.add(params.kinkRate.mul(params.slope1).div(ONE))
    ir = ir.add(params.slope2.mul(ur.sub(params.kinkRate)).div(ONE))
  }

  return ir
}
