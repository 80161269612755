import { BigNumber } from 'ethers'
import { arrayify, hexlify, hexConcat, hexDataSlice } from 'ethers/lib/utils'
import { TradeType } from '../constants/enum'

export function encodeMetadata(card: TradeType) {
  return hexConcat([arrayify(hexlify(card))])
}

export function decodeMetadata(metadata: string): TradeType {
  let card

  try {
    card = BigNumber.from(hexDataSlice(arrayify(metadata), 0, 1))
  } catch {
    card = BigNumber.from(0)
  }

  return <TradeType>card.toNumber()
}
