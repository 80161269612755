/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import { Provider } from '@ethersproject/providers'
import type { IController, IControllerInterface } from '../IController'

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'subVaultId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'asset0',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'asset1',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'debt0',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'debt1',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'bool',
                name: 'isCollateral',
                type: 'bool'
              },
              {
                internalType: 'uint128',
                name: 'liquidity',
                type: 'uint128'
              },
              {
                internalType: 'int24',
                name: 'lowerTick',
                type: 'int24'
              },
              {
                internalType: 'int24',
                name: 'upperTick',
                type: 'int24'
              }
            ],
            internalType: 'struct DataType.LPT[]',
            name: 'lpts',
            type: 'tuple[]'
          }
        ],
        internalType: 'struct DataType.Position[]',
        name: '_positions',
        type: 'tuple[]'
      },
      {
        components: [
          {
            internalType: 'bool',
            name: 'isLiquidationCall',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'swapAnyway',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'quoterMode',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isQuoteZero',
            type: 'bool'
          },
          {
            internalType: 'uint8',
            name: 'marginMode0',
            type: 'uint8'
          },
          {
            internalType: 'uint8',
            name: 'marginMode1',
            type: 'uint8'
          },
          {
            internalType: 'int256',
            name: 'deltaMarginAmount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'deltaMarginAmount1',
            type: 'int256'
          },
          {
            internalType: 'bytes',
            name: 'metadata',
            type: 'bytes'
          }
        ],
        internalType: 'struct DataType.TradeOption',
        name: '_tradeOption',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'lowerSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'upperSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'swapRatio',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'closeRatio',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        internalType: 'struct DataType.ClosePositionOption',
        name: '_closePositionOptions',
        type: 'tuple'
      }
    ],
    name: 'closePosition',
    outputs: [
      {
        components: [
          {
            internalType: 'int256',
            name: 'amount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'amount1',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TokenAmounts',
        name: 'requiredAmounts',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'int256',
            name: 'amount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'amount1',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TokenAmounts',
        name: 'swapAmounts',
        type: 'tuple'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: '_subVaultIndex',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'bool',
            name: 'isLiquidationCall',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'swapAnyway',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'quoterMode',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isQuoteZero',
            type: 'bool'
          },
          {
            internalType: 'uint8',
            name: 'marginMode0',
            type: 'uint8'
          },
          {
            internalType: 'uint8',
            name: 'marginMode1',
            type: 'uint8'
          },
          {
            internalType: 'int256',
            name: 'deltaMarginAmount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'deltaMarginAmount1',
            type: 'int256'
          },
          {
            internalType: 'bytes',
            name: 'metadata',
            type: 'bytes'
          }
        ],
        internalType: 'struct DataType.TradeOption',
        name: '_tradeOption',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'lowerSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'upperSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'swapRatio',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'closeRatio',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        internalType: 'struct DataType.ClosePositionOption',
        name: '_closePositionOptions',
        type: 'tuple'
      }
    ],
    name: 'closeSubVault',
    outputs: [
      {
        components: [
          {
            internalType: 'int256',
            name: 'amount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'amount1',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TokenAmounts',
        name: 'requiredAmounts',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'int256',
            name: 'amount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'amount1',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TokenAmounts',
        name: 'swapAmounts',
        type: 'tuple'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getSqrtPrice',
    outputs: [
      {
        internalType: 'uint160',
        name: 'sqrtPriceX96',
        type: 'uint160'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      }
    ],
    name: 'getVault',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'vaultId',
            type: 'uint256'
          },
          {
            internalType: 'int256',
            name: 'marginAmount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'marginAmount1',
            type: 'int256'
          },
          {
            internalType: 'uint256[]',
            name: 'subVaults',
            type: 'uint256[]'
          }
        ],
        internalType: 'struct DataType.Vault',
        name: '',
        type: 'tuple'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      }
    ],
    name: 'getVaultValue',
    outputs: [
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'subVaultId',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'asset0',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'asset1',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'debt0',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'debt1',
            type: 'uint256'
          },
          {
            components: [
              {
                internalType: 'bool',
                name: 'isCollateral',
                type: 'bool'
              },
              {
                internalType: 'uint128',
                name: 'liquidity',
                type: 'uint128'
              },
              {
                internalType: 'int24',
                name: 'lowerTick',
                type: 'int24'
              },
              {
                internalType: 'int24',
                name: 'upperTick',
                type: 'int24'
              }
            ],
            internalType: 'struct DataType.LPT[]',
            name: 'lpts',
            type: 'tuple[]'
          }
        ],
        internalType: 'struct DataType.Position',
        name: '_position',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'bool',
            name: 'isLiquidationCall',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'swapAnyway',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'quoterMode',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isQuoteZero',
            type: 'bool'
          },
          {
            internalType: 'uint8',
            name: 'marginMode0',
            type: 'uint8'
          },
          {
            internalType: 'uint8',
            name: 'marginMode1',
            type: 'uint8'
          },
          {
            internalType: 'int256',
            name: 'deltaMarginAmount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'deltaMarginAmount1',
            type: 'int256'
          },
          {
            internalType: 'bytes',
            name: 'metadata',
            type: 'bytes'
          }
        ],
        internalType: 'struct DataType.TradeOption',
        name: '_tradeOption',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'lowerSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'upperSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'swapRatio',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        internalType: 'struct DataType.OpenPositionOption',
        name: '_openPositionOptions',
        type: 'tuple'
      }
    ],
    name: 'openPosition',
    outputs: [
      {
        internalType: 'uint256',
        name: 'vaultId',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'int256',
            name: 'amount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'amount1',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TokenAmounts',
        name: 'requiredAmounts',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'int256',
            name: 'amount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'amount1',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TokenAmounts',
        name: 'swapAmounts',
        type: 'tuple'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_vaultId',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'enum DataType.PositionUpdateType',
            name: 'positionUpdateType',
            type: 'uint8'
          },
          {
            internalType: 'uint256',
            name: 'subVaultId',
            type: 'uint256'
          },
          {
            internalType: 'bool',
            name: 'zeroForOne',
            type: 'bool'
          },
          {
            internalType: 'uint128',
            name: 'liquidity',
            type: 'uint128'
          },
          {
            internalType: 'int24',
            name: 'lowerTick',
            type: 'int24'
          },
          {
            internalType: 'int24',
            name: 'upperTick',
            type: 'int24'
          },
          {
            internalType: 'uint256',
            name: 'param0',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'param1',
            type: 'uint256'
          }
        ],
        internalType: 'struct DataType.PositionUpdate[]',
        name: 'positionUpdates',
        type: 'tuple[]'
      },
      {
        components: [
          {
            internalType: 'bool',
            name: 'isLiquidationCall',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'swapAnyway',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'quoterMode',
            type: 'bool'
          },
          {
            internalType: 'bool',
            name: 'isQuoteZero',
            type: 'bool'
          },
          {
            internalType: 'uint8',
            name: 'marginMode0',
            type: 'uint8'
          },
          {
            internalType: 'uint8',
            name: 'marginMode1',
            type: 'uint8'
          },
          {
            internalType: 'int256',
            name: 'deltaMarginAmount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'deltaMarginAmount1',
            type: 'int256'
          },
          {
            internalType: 'bytes',
            name: 'metadata',
            type: 'bytes'
          }
        ],
        internalType: 'struct DataType.TradeOption',
        name: '_tradeOption',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'uint256',
            name: 'lowerSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'upperSqrtPrice',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'swapRatio',
            type: 'uint256'
          },
          {
            internalType: 'uint256',
            name: 'deadline',
            type: 'uint256'
          }
        ],
        internalType: 'struct DataType.OpenPositionOption',
        name: '_openPositionOptions',
        type: 'tuple'
      }
    ],
    name: 'updatePosition',
    outputs: [
      {
        internalType: 'uint256',
        name: 'vaultId',
        type: 'uint256'
      },
      {
        components: [
          {
            internalType: 'int256',
            name: 'amount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'amount1',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TokenAmounts',
        name: 'requiredAmounts',
        type: 'tuple'
      },
      {
        components: [
          {
            internalType: 'int256',
            name: 'amount0',
            type: 'int256'
          },
          {
            internalType: 'int256',
            name: 'amount1',
            type: 'int256'
          }
        ],
        internalType: 'struct DataType.TokenAmounts',
        name: 'swapAmounts',
        type: 'tuple'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export class IController__factory {
  static readonly abi = _abi
  static createInterface(): IControllerInterface {
    return new utils.Interface(_abi) as IControllerInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IController {
    return new Contract(address, _abi, signerOrProvider) as IController
  }
}
