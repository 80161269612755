import { useAssetStatus } from '../useAssetStatus'
import { useIRMParams } from '../useIRMParams'
import { calculateInterestRate } from '../../../utils/irm'
import { useQuery } from 'react-query'
import { useChainId } from '../../network'
import { ONE, TOKEN_RESERVE_FACTOR, ZERO } from '../../../constants'
import { BigNumber } from 'ethers'
import { Position } from '../../../utils/uni'

export function useTokenInterestInNext24h(tradePosition?: Position) {
  const chainId = useChainId()
  const assetStatus = useAssetStatus()
  const irmParams = useIRMParams()

  return useQuery(
    ['token_interest_within_24h', chainId, tradePosition],

    async () => {
      if (!assetStatus.isSuccess) throw new Error('assetStatus not set')

      return assetStatus.data.map((status, tokenNo) => {
        let supply = status.collateralAmount
        let borrow = status.debtAmount

        if (tradePosition) {
          supply = status.collateralAmount.add(
            tokenNo === 0 ? tradePosition.asset0 : tradePosition.asset1
          )
          borrow = status.debtAmount.add(
            tokenNo === 0 ? tradePosition.debt0 : tradePosition.debt1
          )
        }

        const borrowRate = calculateInterestRate(
          irmParams,
          borrow.mul(ONE).div(supply)
        ).div(365)

        const supplyRate = status.collateralAmount.gt(0)
          ? borrowRate
              .mul(borrow)
              .div(supply)
              .mul(BigNumber.from(100).sub(TOKEN_RESERVE_FACTOR))
              .div(100)
          : ZERO

        return {
          supplyRate,
          borrowRate
        }
      })
    },

    {
      enabled: assetStatus.isSuccess
    }
  )
}
