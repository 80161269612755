import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject,
  split,
  HttpLink
} from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/client/link/ws'

export const goerliArbitrum = {
  Predy: {
    http: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/predy-dev/predyv3arbitrumgoerli'
    }),
    ws: new WebSocketLink({
      uri: 'wss://api.thegraph.com/subgraphs/name/predy-dev/predyv3arbitrumgoerli',
      options: {
        reconnect: true
      }
    })
  }
}

export const arbitrum = {
  Predy: {
    http: new HttpLink({
      uri: 'https://api.thegraph.com/subgraphs/name/predy-dev/predyv3arbitrum'
    }),
    ws: new WebSocketLink({
      uri: 'wss://api.thegraph.com/subgraphs/name/predy-dev/predyv3arbitrum',
      options: {
        reconnect: true
      }
    })
  }
}

const splitLink = (wsLink: any, httpLink: any) => {
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink
  )
}

const predyArbitrumGoerli = new ApolloClient({
  link: splitLink(goerliArbitrum.Predy.ws, goerliArbitrum.Predy.http),
  cache: new InMemoryCache()
})

const predyArbitrum = new ApolloClient({
  link: splitLink(arbitrum.Predy.ws, arbitrum.Predy.http),
  cache: new InMemoryCache()
})

export const PredyClient: {
  [key: number]: ApolloClient<NormalizedCacheObject>
} = {
  42161: predyArbitrum,
  421613: predyArbitrumGoerli
}
