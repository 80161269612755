import React, { useState } from 'react'
import { ensureVaultId, useOwnVault } from '../../../hooks/query/useOwnVault'
import History from './History'
import OpenPositions from './OpenPositions'
import gtIcon from '../../../assets/gt.svg'
import { useVaultStatusWithDefaultValue } from '../../../hooks/query/useVaultStatus'
import { toUnscaled } from '../../../utils/bn'
import { useSubVaults } from '../../../hooks/query/useSubVaults'

enum PortfolioMenu {
  OpenPositions,
  History
}

const VaultSummary = ({ vaultId }: { vaultId: number }) => {
  const vaultStatus = useVaultStatusWithDefaultValue(vaultId)

  return (
    <div className="flex items-center justify-center space-x-8">
      <div>
        <div className="pt-2 text-subtext text-sm">Vault Value</div>
        <div className="pt-2 text-[13px]">
          {toUnscaled(vaultStatus.totalValue, 6, 2)}
        </div>
      </div>
      <div>
        <img className="pt-2" src={gtIcon} />
      </div>
      <div>
        <div className="pt-2 text-subtext text-sm">Min. Value</div>
        <div className="pt-2 text-[13px]">
          {toUnscaled(vaultStatus.minCollateral, 6, 2)}
        </div>
      </div>
      <div></div>
      <div>
        <div className="pt-2 text-subtext text-sm">Margin Available</div>
        <div className="pt-2 text-[13px]">
          {toUnscaled(
            vaultStatus.totalValue.sub(vaultStatus.minCollateral),
            6,
            2
          )}
        </div>
      </div>
    </div>
  )
}

const PortfolioTab = () => {
  const vaultIds = useOwnVault()
  const [selectedTab, setTab] = useState(PortfolioMenu.OpenPositions)

  const vaultId = ensureVaultId(vaultIds)
  const subVaults = useSubVaults(vaultId)

  const numPositions =
    vaultIds.isAvailable && vaultIds.data.length > 0
      ? subVaults === null
        ? '...'
        : subVaults.length.toString()
      : 0

  return (
    <div className="rounded-lg bg-fifth container mx-auto mt-8">
      <div className="flex items-center justify-between">
        <ul className="flex flex-wrap md:flex-no-wrap justify-start items-center px-6 py-3 space-x-0">
          <li
            className={`w-48 text-xl cursor-pointer ${
              selectedTab === PortfolioMenu.History ? 'text-deactivetext' : ''
            }`}
            onClick={() => {
              setTab(PortfolioMenu.OpenPositions)
            }}
          >
            Open Positions({numPositions})
          </li>
          <li
            className={`w-48 text-xl cursor-pointer ${
              selectedTab === PortfolioMenu.OpenPositions
                ? 'text-deactivetext'
                : ''
            }`}
            onClick={() => {
              setTab(PortfolioMenu.History)
            }}
          >
            History
          </li>
        </ul>
        <VaultSummary vaultId={vaultId} />
        <div></div>
      </div>

      <div className="px-8 py-3">
        {selectedTab === PortfolioMenu.OpenPositions ? (
          <OpenPositions vaultId={vaultId} subVaults={subVaults} />
        ) : (
          <History />
        )}
      </div>
    </div>
  )
}

export default PortfolioTab
