import { useQuery } from 'react-query'
import { useEffect, useState } from 'react'
import {
  getDelta,
  getGamma,
  getValue,
  Position,
  priceToTick
} from '../../utils/uni'
import { toScaled, toUnscaled } from '../../utils/bn'
import { usePrice } from '../usePrice'
import { usePredyContext } from './protocol/usePredyContext'
import { calculateMinVaultValue } from '../../utils/helpers/minVaultValue'

type ChartCalculationOptions = {
  requestMinValue: boolean
}

type SpotPositionValue = {
  price: number
  value: number
}

export type SpotPnLChartData = {
  values: SpotPositionValue[]
  domainLow: number
  domainHigh: number
  amounts: number[]
  price: number
}

function getPrices(price: number) {
  const basePrice = Math.floor(price / 100) * 100
  const step = 50

  const prices = Array.from({ length: 18 }, (v, i) =>
    toScaled((i - 8) * step + basePrice, 6)
  )

  return prices
}

function getValues(
  position: Position,
  price: number,
  isMarginZero: boolean,
  options: ChartCalculationOptions
) {
  const prices = getPrices(price)

  const values = prices.map(p => {
    const tick = priceToTick(p)

    return {
      price: Number(toUnscaled(p, 6, 0)),
      value: toUnscaled(getValue(position, tick, isMarginZero), 6, 2),
      delta: toUnscaled(getDelta(position, tick, isMarginZero), 18, 4),
      gamma: toUnscaled(getGamma(position, tick, isMarginZero), 18, 6),
      minValue: options.requestMinValue
        ? toUnscaled(calculateMinVaultValue(position, p), 6)
        : 0
    }
  })

  return values
}

export function useValueChartData(
  position: Position,
  options: ChartCalculationOptions = { requestMinValue: false }
) {
  const [chartData, setChartData] = useState<SpotPnLChartData>({
    values: [],
    domainLow: 0,
    domainHigh: 0,
    amounts: [0, 0],
    price: 0
  })
  const price = usePrice()
  const context = usePredyContext()

  const chartDataQuery = useQuery(
    ['spot_pnl_chart', position],
    async () => {
      if (!price.isSuccess) throw new Error('price is not loaded')

      const unscaledPrice = toUnscaled(price.data.price, 6, 0)

      return {
        values: getValues(
          position,
          unscaledPrice,
          context.isMarginZero,
          options
        ),
        domainLow: 0,
        domainHigh: 500,
        amounts: [0, 0], //getAmounts(position, getSqrtRatioAtTick(202560)).map(a => a.toNumber()),
        price: unscaledPrice
      }
    },
    {
      enabled: price.isSuccess
    }
  )

  useEffect(() => {
    if (chartDataQuery.isSuccess) {
      setChartData(chartDataQuery.data)
    }
  }, [chartDataQuery.isSuccess, chartDataQuery.data])

  return chartData
}
