import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { toUnscaled } from '../../utils/bn'
import { useEffect, useState } from 'react'
import { createRangeId } from '../../utils/rangeId'
import { LPRange } from '../../utils/ranges'
import { getAmount0ForLiquidity, getSqrtRatioAtTick } from '../../utils/uni'
import { roundContractSize } from '../../utils'
import { Multicall__factory } from '../../typechain/multicall'
import { STALE_TIME } from '../../constants'

export type LPTStatus = {
  collateralAmount: number
  debtAmount: number
  utilizationRatio: number
}

export function useLPTStatus(ticks: LPRange[]) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  const [lptStatus, setLPTStatus] = useState<LPTStatus[]>([])

  const lptStatusQuery = useQuery(
    ['lpt_status', account, chainId, ticks],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const calls = ticks.map(range => {
        const lower = range.lower
        const upper = range.upper
        const rangeId = createRangeId(lower, upper)

        const callData = controller.interface.encodeFunctionData(
          'getUtilizationRatio',
          [rangeId]
        )

        return {
          target: addresses.Controller,
          callData
        }
      })

      const results = await multicall.callStatic.tryAggregate(false, calls)

      const decodedResult = results.map(result => {
        if (result.success) {
          const utilizationRatio = controller.interface.decodeFunctionResult(
            'getUtilizationRatio',
            result.returnData
          )
          return {
            supply: utilizationRatio[0],
            borrow: utilizationRatio[1],
            utilizationRatio: utilizationRatio[2]
          }
        } else {
          return {
            supply: BigNumber.from(0),
            borrow: BigNumber.from(0),
            utilizationRatio: BigNumber.from(0)
          }
        }
      })

      return decodedResult.map((result, i) => {
        const lower = ticks[i].lower
        const upper = ticks[i].upper

        const supply = getAmount0ForLiquidity(
          getSqrtRatioAtTick(lower),
          getSqrtRatioAtTick(upper),
          result.supply
        )

        const borrow = getAmount0ForLiquidity(
          getSqrtRatioAtTick(lower),
          getSqrtRatioAtTick(upper),
          result.borrow
        )

        return {
          collateralAmount: roundContractSize(supply),
          debtAmount: roundContractSize(borrow),
          utilizationRatio: toUnscaled(result.utilizationRatio, 18, 2)
        }
      })
    },

    {
      enabled:
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        ticks.length > 0,
      staleTime: STALE_TIME
    }
  )

  useEffect(() => {
    if (lptStatusQuery.isSuccess) {
      setLPTStatus(lptStatusQuery.data)
    }
  }, [lptStatusQuery.isSuccess, lptStatusQuery.data])

  return lptStatus
}
