import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { useEffect, useState } from 'react'
import { STALE_TIME } from '../../constants'
import { usePrice } from '../usePrice'
import { Multicall__factory } from '../../typechain/multicall'
import { Position } from '../../utils/uni'

type VaultStatus = {
  positions: Position[]
  subVaultIds: BigNumber[]
  totalValue: BigNumber
  positionValue: BigNumber
  marginValue: BigNumber
  minCollateral: BigNumber
  subVaults: {
    id: BigNumber
    values: {
      collateralValue: BigNumber
      debtValue: BigNumber
      premiumValue: BigNumber
    }
    amount: {
      collateralAmount0: BigNumber
      collateralAmount1: BigNumber
      debtAmount0: BigNumber
      debtAmount1: BigNumber
    }
    interest: {
      assetFee0: BigNumber
      assetFee1: BigNumber
      debtFee0: BigNumber
      debtFee1: BigNumber
    }
    premium: {
      receivedTradeAmount0: BigNumber
      receivedTradeAmount1: BigNumber
      receivedPremium: BigNumber
      paidpremium: BigNumber
    }
  }[]
}

export function useVaultStatusWithDefaultValue(vaultId: number) {
  const [vaultStatus, setVaultStatus] = useState<VaultStatus>({
    positions: [],
    subVaultIds: [],
    totalValue: BigNumber.from(0),
    positionValue: BigNumber.from(0),
    marginValue: BigNumber.from(0),
    minCollateral: BigNumber.from(0),
    subVaults: []
  })

  const vaultStatusQuery = useVaultStatus(vaultId, true)

  useEffect(() => {
    if (vaultStatusQuery.isSuccess) {
      setVaultStatus(vaultStatusQuery.data)
    }
  }, [vaultStatusQuery.isSuccess, vaultStatusQuery.data])

  return vaultStatus
}

export function useVaultStatus(vaultId: number, useIndexPrice = true) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const price = usePrice()

  const vaultStatusQuery = useQuery<VaultStatus>(
    ['vault_status', chainId, vaultId, useIndexPrice],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!price.isSuccess) throw new Error('price not loaded')

      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const calls = [
        {
          target: addresses.Controller,
          callData: controller.interface.encodeFunctionData('getVaultStatus', [
            vaultId,
            useIndexPrice ? price.data.sqrtIndexPrice : price.data.sqrtPrice
          ])
        },
        {
          target: addresses.Controller,
          callData: controller.interface.encodeFunctionData('getPosition', [
            vaultId
          ])
        },
        {
          target: addresses.Controller,
          callData: controller.interface.encodeFunctionData('getVault', [
            vaultId
          ])
        }
      ]

      const result = await multicall.callStatic.aggregate(calls)

      const vaultStatus = controller.interface.decodeFunctionResult(
        'getVaultStatus',
        result.returnData[0]
      )[0]
      const positionsResult = controller.interface.decodeFunctionResult(
        'getPosition',
        result.returnData[1]
      )[0]
      const vaultResult = controller.interface.decodeFunctionResult(
        'getVault',
        result.returnData[2]
      )[0]

      const positions = positionsResult.map((position: any) => ({
        subVaultIndex: position[0].toNumber(),
        asset0: position[1],
        asset1: position[2],
        debt0: position[3],
        debt1: position[4],
        lpts: position[5].map((lpt: any) => ({
          isCollateral: lpt[0],
          liquidity: lpt[1],
          lowerTick: lpt[2],
          upperTick: lpt[3]
        }))
      }))

      const subVaultIds = vaultResult[3]

      return {
        positions,
        subVaultIds,
        totalValue: vaultStatus[0].add(vaultStatus[1]),
        positionValue: vaultStatus[0],
        marginValue: vaultStatus[1],
        minCollateral: vaultStatus[2],
        subVaults: vaultStatus[3].map((subVault: any, i: number) => {
          return {
            id: subVaultIds[i],
            values: {
              collateralValue: subVault[0][0],
              debtValue: subVault[0][1],
              premiumValue: subVault[0][2]
            },
            amount: {
              collateralAmount0: subVault[1][0],
              collateralAmount1: subVault[1][1],
              debtAmount0: subVault[1][2],
              debtAmount1: subVault[1][3]
            },
            interest: {
              assetFee0: subVault[2][0],
              assetFee1: subVault[2][1],
              debtFee0: subVault[2][2],
              debtFee1: subVault[2][3]
            },
            premium: {
              receivedTradeAmount0: subVault[3][0],
              receivedTradeAmount1: subVault[3][1],
              receivedPremium: subVault[3][2],
              paidpremium: subVault[3][3]
            }
          }
        })
      }
    },

    {
      enabled:
        vaultId >= 0 &&
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        price.isSuccess,
      staleTime: STALE_TIME,
      refetchInterval: 15000
    }
  )

  return vaultStatusQuery
}
