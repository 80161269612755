import React from 'react'
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  LineChart,
  Line,
  ReferenceLine
} from 'recharts'
import { useValueChartData } from '../../../hooks/query/useValueChartData'
import useTrade from '../../../store/trade'
import { createPositionFromTradeData } from '../../../utils/position'
import { getStrikePrice } from '../../../utils'
import { useTicks } from '../../../hooks/useTicks'
import { toPriceString } from '../../../utils/number'
import { usePrice } from '../../../hooks/usePrice'
import { BigNumber } from 'ethers'
import { usePredyContext } from '../../../hooks/query/protocol/usePredyContext'

export enum ChartMode {
  Value,
  Delta,
  Gamma
}

function createTrianglePath(x: number, y: number) {
  return `${x},${y} ${x - 5},${y + 8} ${x + 5},${y + 8}`
}

export const RangeChart = ({ chartMode }: { chartMode: ChartMode }) => {
  const { strategyType, rangeIds } = useTrade()

  const ticks = useTicks()
  const price = usePrice()
  const context = usePredyContext()

  const position = createPositionFromTradeData(
    {
      subVaultIndex: 0,
      strategyType,
      rangeIds,
      optionAmount: 1
    },
    price.isSuccess ? price.data.price : BigNumber.from(0),
    context.isMarginZero
  )

  const spotPnL = useValueChartData(position)

  if (ticks.length === 0 || spotPnL.values.length === 0) {
    return <div />
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const dataDefs = (payload as { dataKey: string; value: number }[]).map(
        p => ({
          name: p.dataKey,
          value: p.value
        })
      )

      // sort by value
      dataDefs.sort((a: any, b: any) => b.value - a.value)

      return (
        <div className="text-xs rounded-lg p-1">
          {dataDefs.map((dataDef, i) => {
            return (
              <div key={i} className="text-white text-xs p-1">
                <p>
                  {dataDef.name}:{' '}
                  {dataDef.name === 'value'
                    ? '$' + toPriceString(dataDef.value)
                    : dataDef.value}
                </p>
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  const CustomizedLabel = (params: any) => {
    const x = params.viewBox.x
    const y = params.viewBox.y + 210

    const points = createTrianglePath(x, y)

    return (
      <g>
        <polygon points={points} fill={params.content.props.fill} />
      </g>
    )
  }

  const CustomizedXAxisTick = (props: any) => {
    const x = props.x || 0
    const y = props.y || 0
    const payload = props.payload

    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text
          x={0}
          y={0}
          dy={14}
          textAnchor="middle"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          ${payload ? payload.value : ''}
        </text>
      </g>
    )
  }

  const CustomizedYAxisTick = (props: any) => {
    const x = props.x || 0
    const y = props.y || 0
    const payload = props.payload

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={0}
          dy={0}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {chartMode === ChartMode.Value ? '$' : ''}
          {payload ? payload.value : ''}
        </text>
      </g>
    )
  }

  const CustomizedCursor = (props: any) => {
    const { points, payload } = props

    const triangle = createTrianglePath(points[1].x, points[1].y)

    return (
      <g>
        <path
          d={`M ${points[0].x} ${points[0].y} L ${points[1].x} ${points[1].y}`}
          stroke="#ccc"
        ></path>
        <polygon points={triangle} fill="#ccc" />
        <text
          x={points[1].x + 10}
          y={points[1].y + 17}
          dx={0}
          dy={0}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={10}
        >
          ${payload.length > 0 ? payload[0].payload.price : 0}
        </text>
      </g>
    )
  }
  return (
    <div>
      <div className="flex items-center justify-end text-[11px] space-x-2 py-1">
        <div className="rounded-full bg-blue w-2 h-2"></div>
        <div>Strike</div>
        <div className="rounded-full bg-green w-2 h-2"></div>
        <div>Index</div>
      </div>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={spotPnL.values}>
          <CartesianGrid stroke="#4c525f" />
          <XAxis
            type="number"
            dataKey="price"
            tickCount={19}
            interval={3}
            tickLine={false}
            domain={['dataMin', 'dataMax']}
            tick={<CustomizedXAxisTick />}
          />
          <YAxis
            type="number"
            domain={
              chartMode === ChartMode.Value
                ? [-500, 500]
                : chartMode === ChartMode.Delta
                ? [-2, 2]
                : [-0.02, 0.02]
            }
            tickCount={5}
            tick={<CustomizedYAxisTick />}
            tickLine={false}
          />
          <Tooltip cursor={<CustomizedCursor />} content={<CustomTooltip />} />
          <ReferenceLine
            x={spotPnL.price || 0}
            stroke="#04ECA6"
            strokeDasharray="5 5"
            opacity={0.5}
            label={
              <CustomizedLabel price={spotPnL.price} fill="#04ECA6">
                Current Price
              </CustomizedLabel>
            }
          />
          {rangeIds.map((rangeId, i) => (
            <ReferenceLine
              key={i}
              x={getStrikePrice(rangeId)}
              stroke="#5259FA"
              strokeDasharray="5 5"
              opacity={0.5}
              label={
                <CustomizedLabel price={getStrikePrice(rangeId)} fill="#7279FA">
                  Strike Price
                </CustomizedLabel>
              }
            />
          ))}
          <Line
            type="monotone"
            name="Value"
            dataKey={
              chartMode === ChartMode.Value
                ? 'value'
                : chartMode === ChartMode.Delta
                ? 'delta'
                : 'gamma'
            }
            stroke="#257EFF"
            strokeWidth={1}
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}
