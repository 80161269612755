/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from 'ethers'
import { Provider, TransactionRequest } from '@ethersproject/providers'
import type { BlackScholes, BlackScholesInterface } from '../BlackScholes'

const _abi = [
  {
    inputs: [
      {
        internalType: 'uint256',
        name: '_maturity',
        type: 'uint256'
      }
    ],
    name: 'getSqrtMaturity',
    outputs: [
      {
        internalType: 'int256',
        name: '',
        type: 'int256'
      }
    ],
    stateMutability: 'pure',
    type: 'function'
  }
]

const _bytecode =
  '0x610206610026600b82828239805160001a60731461001957fe5b30600052607381538281f3fe73000000000000000000000000000000000000000030146080604052600436106100355760003560e01c8063c2bcddc11461003a575b600080fd5b6100576004803603602081101561005057600080fd5b5035610069565b60408051918252519081900360200190f35b6000808211801561007d57506301e1338082105b6100b85760405162461bcd60e51b81526004018080602001828103825260448152602001806101586044913960600191505060405180910390fd5b6482c0226c666100c7836100de565b662386f26fc1000002816100d757fe5b0592915050565b60008082121561011f5760405162461bcd60e51b815260040180806020018281038252603581526020018061019c6035913960400191505060405180910390fd5b5080600260018201055b818112156101515780915060028182858161014057fe5b05018161014957fe5b059050610129565b5091905056fe507269636543616c63756c61746f723a206d61747572697479206d757374206e6f742068617665206578706972656420616e64206c657373207468616e2031207965617263616e6e6f742063616c63756c617465207468652073717561726520726f6f74206f662061206e65676174697665206e756d626572a2646970667358221220ef2fa160ae0665c21db03eba033ba31f44d583827061e9a2030a5ef8e7aac5c564736f6c63430007060033'

export class BlackScholes__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0])
    } else {
      super(...args)
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<BlackScholes> {
    return super.deploy(overrides || {}) as Promise<BlackScholes>
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {})
  }
  attach(address: string): BlackScholes {
    return super.attach(address) as BlackScholes
  }
  connect(signer: Signer): BlackScholes__factory {
    return super.connect(signer) as BlackScholes__factory
  }
  static readonly bytecode = _bytecode
  static readonly abi = _abi
  static createInterface(): BlackScholesInterface {
    return new utils.Interface(_abi) as BlackScholesInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): BlackScholes {
    return new Contract(address, _abi, signerOrProvider) as BlackScholes
  }
}
