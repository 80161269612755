import React from 'react'
import styled from 'styled-components'

const LoadingIndicator = () => <Container>Loading</Container>

const Container = styled.div`
  width: 100%;
  height: 100%;
`

export default LoadingIndicator
