export type Addresses = {
  Controller: string
  Reader: string
  VaultNFT: string
  Multicall2: string
  QuoteToken: string
  UnderlyingToken: string
}

export const arbitrum = {
  Controller: '0x4006A8840F8640A7D8F46D2c3155a58c76eCD56e',
  Reader: '0xAB99F8ec662eE36C369a99312062F82261BFDD66',
  VaultNFT: '0xaA8C588e8aCD1849C04159b492e622A8d6B85b1C',
  Multicall2: '0x842eC2c7D803033Edf55E478F461FC547Bc54EB2',
  QuoteToken: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
  UnderlyingToken: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1'
}

export const goerliArbitrum = {
  Controller: '0x88D62D7D7C91a6ae5A362f2dC92321e2415889Ce',
  Reader: '0x6c981b970AC1507a0BF085742b17207bC1914880',
  VaultNFT: '0xF6a8f5773D490ed930C592d9a373D8064bdAC2E7',
  Multicall2: '0x108B25170319f38DbED14cA9716C54E5D1FF4623',
  QuoteToken: '0xE060e715B6D20b899A654687c445ed8BC35f9dFF',
  UnderlyingToken: '0x163691b2153F4e18F3c3F556426b7f5C74a99FA4'
}

export const NETWORKS: { [key: number]: Addresses } = {
  [421613]: goerliArbitrum,
  [42161]: arbitrum
}

export const UNI_ETH_USDC_POOL_ADDRESS = [
  '0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8',
  '0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640'
]
