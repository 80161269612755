import { useQuery } from '@apollo/client'
import { VaultEntities, VAULTS_QUERY } from '../../queries/vaultQuery'
import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import { useMemo } from 'react'

export type VaultObject = {
  vaultId: number
  owner: string
}

type OwnVaultResult =
  | { isAvailable: false; data: null }
  | {
      isAvailable: true
      data: VaultObject[]
    }

export function ensureVaultId(vaultIds: OwnVaultResult) {
  return vaultIds.isAvailable
    ? vaultIds.data.length > 0
      ? vaultIds.data[0].vaultId
      : 0
    : -1
}

export function useOwnVault(): OwnVaultResult {
  const { account } = useWeb3React<ethers.providers.Web3Provider>()

  const { data } = useQuery<VaultEntities>(VAULTS_QUERY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      owner: (account || '').toLowerCase()
    },
    pollInterval: 10000
  })

  const vaults = useMemo(() => {
    if (data) {
      const ownVaults = data.vaultEntities.map(entity => ({
        vaultId: Number(entity.vaultId),
        owner: entity.owner,
        originalOwner: entity.originalOwner
      }))

      const filter = ownVaults.filter(
        vault => vault.originalOwner === vault.owner
      )

      if (filter.length > 0) {
        return filter
      } else {
        return ownVaults
      }
    }
    return null
  }, [data])

  if (data === undefined || vaults === null) {
    return {
      isAvailable: false,
      data: null
    }
  }

  return {
    isAvailable: true,
    data: vaults
  }
}
