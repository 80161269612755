import { useQuery } from 'react-query'
import { Controller__factory, Reader__factory } from '../typechain'
import { useAddresses } from './useAddress'
import { useWeb3React } from '@web3-react/core'
import { BigNumber, ethers } from 'ethers'
import { useIsSupportedChain } from './network'
import { useEffect, useState } from 'react'
import { ZERO } from '../constants'
import { Multicall__factory } from '../typechain/multicall'

interface PriceResult {
  sqrtPrice: BigNumber
  sqrtIndexPrice: BigNumber
  price: BigNumber
  indexPrice: BigNumber
}

export function useCachedPrice() {
  const [priceResult, setPriceResult] = useState<PriceResult>({
    sqrtPrice: ZERO,
    sqrtIndexPrice: ZERO,
    price: ZERO,
    indexPrice: ZERO
  })

  const query = usePrice()

  useEffect(() => {
    if (query.isSuccess) {
      setPriceResult(query.data)
    }
  }, [query])

  return priceResult
}

export function usePrice() {
  const { provider } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const addresses = useAddresses()

  return useQuery<PriceResult>(
    ['price'],
    async () => {
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('Address not loaded')

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )
      const reader = Reader__factory.connect(addresses.Reader, provider)
      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const calls = [
        {
          target: addresses.Controller,
          callData: controller.interface.encodeFunctionData('getSqrtPrice')
        },
        {
          target: addresses.Controller,
          callData: controller.interface.encodeFunctionData('getSqrtIndexPrice')
        },
        {
          target: addresses.Reader,
          callData: reader.interface.encodeFunctionData('getPrice')
        },
        {
          target: addresses.Reader,
          callData: reader.interface.encodeFunctionData('getIndexPrice')
        }
      ]

      const result = await multicall.callStatic.tryAggregate(true, calls)

      const sqrtPrice = controller.interface.decodeFunctionResult(
        'getSqrtPrice',
        result[0].returnData
      )[0]
      const sqrtIndexPrice = controller.interface.decodeFunctionResult(
        'getSqrtIndexPrice',
        result[1].returnData
      )[0]
      const price = reader.interface.decodeFunctionResult(
        'getPrice',
        result[2].returnData
      )[0]
      const indexPrice = reader.interface.decodeFunctionResult(
        'getIndexPrice',
        result[3].returnData
      )[0]

      return {
        sqrtPrice,
        sqrtIndexPrice,
        price: price.div(100),
        indexPrice: indexPrice.div(100)
      }
    },
    {
      enabled: !!provider && supportedChain && !!addresses,
      refetchInterval: 7000,
      staleTime: 2000
    }
  )
}
