import React from 'react'
import { BigNumber } from 'ethers'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import { toUnscaled } from '../../utils/bn'
import HorizontalRule from '../common/HorizontalRule'
import { VaultValueChart } from './VaultValueChart'
import { useSubVaults } from '../../hooks/query/useSubVaults'
import { roundContractSize, tradeTypeToString } from '../../utils'
import { toAmountString, toPriceString } from '../../utils/number'
import { usePositionInterestEst } from '../../hooks/query/interest/useInterestEst'
import { concatPosition } from '../../utils/uni'
import { EmptyPosition } from '../../utils/position'

const VaultStatus = ({ vaultId }: { vaultId: number }) => {
  const vaultStatus = useVaultStatus(vaultId)
  const subVaults = useSubVaults(vaultId)
  const interestEst = usePositionInterestEst(
    vaultStatus.isSuccess
      ? concatPosition(vaultStatus.data.positions)
      : EmptyPosition
  )

  return (
    <div className="rounded-lg bg-secondaly leading-5 p-2">
      <div className="flex flex-wrap md:flex-no-wrap justify-between items-center px-6 space-x-0">
        <div className="w-32">Vault {vaultId} Status</div>
      </div>

      <HorizontalRule />

      <div className="p-1">
        <div className="flex justify-around items-center">
          <div className="p-2 w-80">
            <h3>Summary</h3>
            <div className="flex justify-between items-center">
              <span>Position Value: </span>
              <span>
                {vaultStatus.isSuccess
                  ? toUnscaled(
                      vaultStatus.data.positionValue.add(
                        vaultStatus.data.marginValue
                      ),
                      6,
                      2
                    )
                  : 0}{' '}
                USDC
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span>Min Collateral:</span>
              <span>
                {vaultStatus.isSuccess
                  ? toUnscaled(vaultStatus.data.minCollateral, 6, 2)
                  : 0}{' '}
                USDC
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span>Margin Value:</span>
              <span>
                {vaultStatus.isSuccess
                  ? toUnscaled(vaultStatus.data.marginValue, 6, 6)
                  : 0}{' '}
                USDC
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span>Interest Est.</span>
              <span>
                ${interestEst.isSuccess ? toPriceString(interestEst.data) : 0}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span>Delta:</span>
              <span>
                {vaultStatus.isSuccess
                  ? toUnscaled(
                      vaultStatus.data.subVaults.reduce((acc, item) => {
                        return acc.add(
                          item.amount.collateralAmount0.sub(
                            item.amount.debtAmount0
                          )
                        )
                      }, BigNumber.from(0)),
                      18
                    )
                  : 0}
              </span>
            </div>
          </div>

          <div className="p-2 w-[420px]">
            <h3>Position Value Chart</h3>
            <VaultValueChart vaultId={vaultId} />
          </div>
        </div>

        <div className="p-2">
          <h3>SubVaults</h3>
          <table className="border-collapse table-fixed text-left">
            <thead className="text-[12px] text-subtext font-medium">
              <tr>
                <th className="w-40 pb-2">ID</th>
                <th className="w-40">Contract</th>
                <th className="w-40">Strikes</th>
                <th className="w-40">Size</th>
              </tr>
            </thead>
            <tbody className="text-sm font-normal items-center align-center">
              {subVaults ? (
                subVaults.map((subVault, i) => {
                  return (
                    <tr key={i}>
                      <td>{subVault.subVaultId}</td>
                      <td>{tradeTypeToString(subVault.tradeType)}</td>
                      <td>
                        {subVault.strikes
                          .sort()
                          .map(strikePrice => strikePrice.toString())
                          .join(',')}
                      </td>
                      <td>
                        {toAmountString(roundContractSize(subVault.size))}
                      </td>
                    </tr>
                  )
                })
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default VaultStatus
