import create from 'zustand'

interface State {
  vaultId: number
  selectedVaultId: number
  setVaultId: (vaultId: number) => void
  selectVaultId: (vaultId: number) => void
}

const useStore = create<State>(set => ({
  vaultId: 0,
  selectedVaultId: 0,
  setVaultId: (vaultId: number) => set({ vaultId }),
  selectVaultId: (vaultId: number) => set({ selectedVaultId: vaultId })
}))

export default useStore
