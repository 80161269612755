import React from 'react'
import { XAxis, YAxis, CartesianGrid, Tooltip, LineChart, Line } from 'recharts'
import useTrade from '../../../store/trade'
import { useInterestHistory } from '../../../hooks/query/interest/useInterest'
import { toFixedNumber, toPriceString } from '../../../utils/number'
import { createPositionFromTradeData } from '../../../utils/position'
import { usePrice } from '../../../hooks/usePrice'
import { BigNumber } from 'ethers'

export enum HistoryChartMode {
  Interest,
  ImpliedVolatility
}

export const HistoryChart = () => {
  const { strategyType, rangeIds } = useTrade()
  const price = usePrice()
  const historyData = useInterestHistory(
    createPositionFromTradeData(
      {
        subVaultIndex: 0,
        strategyType,
        rangeIds,
        optionAmount: 1
      },
      price.isSuccess ? price.data.price : BigNumber.from(0),
      false
    )
  )

  const data = historyData.isSuccess ? historyData.data : []

  if (data.length === 0) {
    return <div>Loading</div>
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const dataDefs = (payload as { name: string; value: number }[]).map(
        p => ({
          name: p.name,
          value: p.value
        })
      )

      // sort by value
      dataDefs.sort((a: any, b: any) => b.value - a.value)

      return (
        <div className="text-xs rounded-lg p-1">
          {dataDefs.map((dataDef, i) => {
            return (
              <div key={i} className="text-white text-xs p-1">
                <p>
                  {dataDef.name}: ${toPriceString(dataDef.value)}
                </p>
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  const CustomizedXAxisTick = (props: any) => {
    const { x, y, payload } = props

    const elapsedDays = payload ? payload.value : 0

    const daysAgo = data.length - elapsedDays

    if (!Number.isInteger(daysAgo)) {
      return <></>
    }

    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text
          x={0}
          y={0}
          dy={14}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {daysAgo}d
        </text>
      </g>
    )
  }

  const CustomizedYAxisTick = (props: any) => {
    const { x, y, payload } = props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={0}
          dy={0}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          ${payload ? toPriceString(payload.value) : ''}
        </text>
      </g>
    )
  }

  return (
    <div>
      <div className="pt-[18px]"></div>
      <LineChart width={384} height={250} data={data}>
        <CartesianGrid stroke="#4c525f" />
        <XAxis
          type="number"
          dataKey="elapsedDays"
          tickCount={7}
          interval={1}
          tickLine={false}
          tick={<CustomizedXAxisTick />}
          domain={['dataMin', 'dataMax']}
        />
        <YAxis
          type="number"
          domain={[-5, 5]}
          tickCount={5}
          tick={<CustomizedYAxisTick />}
          tickLine={false}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          name="Value"
          dataKey="value"
          stroke="#257EFF"
          strokeWidth={1}
          dot={false}
        />
      </LineChart>
    </div>
  )
}

export const IVHistoryChart = () => {
  const { strategyType, rangeIds } = useTrade()
  const price = usePrice()
  const historyData = useInterestHistory(
    createPositionFromTradeData(
      {
        subVaultIndex: 0,
        strategyType,
        rangeIds,
        optionAmount: 1
      },
      price.isSuccess ? price.data.price : BigNumber.from(0),
      false
    )
  )

  const data = historyData.isSuccess ? historyData.data : []

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const dataDefs = (payload as { name: string; value: number }[]).map(
        p => ({
          name: p.name,
          value: p.value
        })
      )

      // sort by value
      dataDefs.sort((a: any, b: any) => b.value - a.value)

      return (
        <div className="text-xs rounded-lg p-1">
          {dataDefs.map((dataDef, i) => {
            return (
              <div key={i} className="text-white text-xs p-1">
                <p>
                  {dataDef.name}: {toFixedNumber(dataDef.value)}%
                </p>
              </div>
            )
          })}
        </div>
      )
    }
    return null
  }

  const CustomizedXAxisTick = (props: any) => {
    const { x, y, payload } = props

    const elapsedDays = payload ? payload.value : 0

    const daysAgo = data.length - elapsedDays

    if (!Number.isInteger(daysAgo)) {
      return <></>
    }

    return (
      <g transform={`translate(${x},${y + 10})`}>
        <text
          x={0}
          y={0}
          dy={14}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {daysAgo}d
        </text>
      </g>
    )
  }

  const CustomizedYAxisTick = (props: any) => {
    const { x, y, payload } = props

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dx={0}
          dy={0}
          textAnchor="end"
          fill="#FFFFFF"
          transform="rotate(0)"
          fontSize={12}
        >
          {payload ? toPriceString(payload.value) : ''}%
        </text>
      </g>
    )
  }

  return (
    <div>
      <div className="pt-[18px]"></div>
      <LineChart width={384} height={250} data={data}>
        <CartesianGrid stroke="#4c525f" />
        <XAxis
          type="number"
          dataKey="elapsedDays"
          tickCount={7}
          tickLine={false}
          tick={<CustomizedXAxisTick />}
          domain={['dataMin', 'dataMax']}
          allowDataOverflow
        />
        <YAxis
          type="number"
          domain={[0, 200]}
          tickCount={5}
          tick={<CustomizedYAxisTick />}
          tickLine={false}
        />
        <Tooltip content={<CustomTooltip />} />
        <Line
          type="monotone"
          name={rangeIds.length === 1 ? 'IV' : 'IV0'}
          dataKey="iv0"
          stroke="#257EFF"
          strokeWidth={1}
          dot={false}
        />
        {rangeIds.length === 2 ? (
          <Line
            type="monotone"
            name="IV1"
            dataKey="iv1"
            stroke="#257EFF"
            strokeWidth={1}
            dot={false}
          />
        ) : (
          <></>
        )}
      </LineChart>
    </div>
  )
}
