import React from 'react'
import CardList from '../components/options/CardList'
import CenterArea from '../components/options/center/CenterArea'
import TradeResult from '../components/options/trade/TradeResult'
import PortfolioTab from '../components/options/portfolio/PortfolioTab'

const TradeView = () => {
  return (
    <div className="grid grid-cols-trade grid-rows-trade xl:grid-rows-trade-xl gap-4">
      <div className="col-span-1 row-span-1">
        <CardList />
      </div>
      <div className="col-span-1 row-span-1 border-x-[1.5px] border-third">
        <CenterArea />
      </div>
      <div className="col-span-1 row-span-1">
        <TradeResult />
      </div>
      <div className="col-span-3 row-span-1 border-t-[1.5px] border-third">
        <PortfolioTab />
      </div>
    </div>
  )
}

export default TradeView
