import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'
import { VaultNFT__factory } from '../../../typechain'

export function useVaultCount() {
  const { provider } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  return useQuery(
    ['nextVaultId', chainId],

    async () => {
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = VaultNFT__factory.connect(addresses.VaultNFT, provider)
      const nextId = await contract.nextId()

      return nextId.toNumber() - 1
    },

    {
      enabled: supportedChain && !!provider && !!addresses
    }
  )
}
