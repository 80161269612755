import { ethers, BigNumber, ContractTransaction } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useMutation } from 'react-query'
import { MockERC20__factory } from '../../typechain'

type MintParams = {
  amount: BigNumber
  address: string
  account: string
}

export function useMintMutation() {
  const { provider } = useWeb3React<ethers.providers.Web3Provider>()

  return useMutation<ContractTransaction, unknown, MintParams>(
    async ({ address, account, amount }) => {
      if (!provider) throw new Error('not connected')

      const contract = MockERC20__factory.connect(address, provider.getSigner())
      return await contract.mint(account, amount)
    }
  )
}
