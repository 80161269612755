import React from 'react'
import TradeResult from '../components/hedge/TradeForm'

const HedgeView = () => {
  return (
    <div className="max-w-xl mx-auto">
      <TradeResult />
    </div>
  )
}

export default HedgeView
