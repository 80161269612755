import { gql } from '@apollo/client'

export const SUBVAULTS_QUERY = gql`
  query ($vaultId: String, $isRemoved: Boolean, $first: Int, $skipCount: Int) {
    subVaultEntities(
      first: $first
      skip: $skipCount
      orderBy: createdAt
      orderDirection: desc
      where: { vault: $vaultId, isRemoved: $isRemoved }
    ) {
      vault {
        vaultId
      }
      subVaultId
      subVaultIndex
      isRemoved
      asset0
      asset1
      debt0
      debt1
      lpts {
        isCollateral
        rangeId
        liquidity
        liquidityHistory {
          liquidity
          createdAt
        }
      }
      feeAmount0
      feeAmount1
      totalAmount0
      totalAmount1
      amountHistory {
        amount0
        amount1
        value
        positionUpdateTx {
          swapAmount0
          swapAmount1
        }
      }
      openTx {
        id
        metadata
        requiredAmount0
        requiredAmount1
        swapAmount0
        swapAmount1
        positionAmount0
        positionAmount1
        feeAmount0
        feeAmount1
        zeroForOne
        createdAt
      }
      closeTx {
        id
        metadata
        swapAmount0
        swapAmount1
        positionAmount0
        positionAmount1
        createdAt
      }
    }
  }
`

export type SubVaultEntity = {
  vault: { vaultId: string }
  subVaultId: string
  subVaultIndex: string
  isRemoved: boolean
  asset0: string
  asset1: string
  debt0: string
  debt1: string
  lpts: {
    isCollateral: boolean
    rangeId: string
    liquidity: string
    liquidityHistory: {
      liquidity: string
      createdAt: string
    }[]
  }[]
  feeAmount0: string
  feeAmount1: string
  totalAmount0: string
  totalAmount1: string
  amountHistory: {
    amount0: string
    amount1: string
    value: string
    positionUpdateTx: {
      swapAmount0: string
      swapAmount1: string
    }
  }[]
  openTx: {
    id: string
    metadata: string
    requiredAmount0: string
    requiredAmount1: string
    swapAmount0: string
    swapAmount1: string
    positionAmount0: string
    positionAmount1: string
    feeAmount0: string
    feeAmount1: string
    zeroForOne: string
    createdAt: string
  }
  closeTx: {
    id: string
    metadata: string
    swapAmount0: string
    swapAmount1: string
    positionAmount0: string
    positionAmount1: string
    createdAt: string
  }
}

export type SubVaultEntities = {
  subVaultEntities: SubVaultEntity[]
}
