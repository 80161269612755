/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from 'ethers'
import { Provider } from '@ethersproject/providers'
import type {
  IUniswapV3PoolActions,
  IUniswapV3PoolActionsInterface
} from '../IUniswapV3PoolActions'

const _abi = [
  {
    inputs: [
      {
        internalType: 'int24',
        name: 'tickLower',
        type: 'int24'
      },
      {
        internalType: 'int24',
        name: 'tickUpper',
        type: 'int24'
      },
      {
        internalType: 'uint128',
        name: 'amount',
        type: 'uint128'
      }
    ],
    name: 'burn',
    outputs: [
      {
        internalType: 'uint256',
        name: 'amount0',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amount1',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address'
      },
      {
        internalType: 'int24',
        name: 'tickLower',
        type: 'int24'
      },
      {
        internalType: 'int24',
        name: 'tickUpper',
        type: 'int24'
      },
      {
        internalType: 'uint128',
        name: 'amount0Requested',
        type: 'uint128'
      },
      {
        internalType: 'uint128',
        name: 'amount1Requested',
        type: 'uint128'
      }
    ],
    name: 'collect',
    outputs: [
      {
        internalType: 'uint128',
        name: 'amount0',
        type: 'uint128'
      },
      {
        internalType: 'uint128',
        name: 'amount1',
        type: 'uint128'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address'
      },
      {
        internalType: 'uint256',
        name: 'amount0',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amount1',
        type: 'uint256'
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes'
      }
    ],
    name: 'flash',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'observationCardinalityNext',
        type: 'uint16'
      }
    ],
    name: 'increaseObservationCardinalityNext',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint160',
        name: 'sqrtPriceX96',
        type: 'uint160'
      }
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address'
      },
      {
        internalType: 'int24',
        name: 'tickLower',
        type: 'int24'
      },
      {
        internalType: 'int24',
        name: 'tickUpper',
        type: 'int24'
      },
      {
        internalType: 'uint128',
        name: 'amount',
        type: 'uint128'
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes'
      }
    ],
    name: 'mint',
    outputs: [
      {
        internalType: 'uint256',
        name: 'amount0',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'amount1',
        type: 'uint256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address'
      },
      {
        internalType: 'bool',
        name: 'zeroForOne',
        type: 'bool'
      },
      {
        internalType: 'int256',
        name: 'amountSpecified',
        type: 'int256'
      },
      {
        internalType: 'uint160',
        name: 'sqrtPriceLimitX96',
        type: 'uint160'
      },
      {
        internalType: 'bytes',
        name: 'data',
        type: 'bytes'
      }
    ],
    name: 'swap',
    outputs: [
      {
        internalType: 'int256',
        name: 'amount0',
        type: 'int256'
      },
      {
        internalType: 'int256',
        name: 'amount1',
        type: 'int256'
      }
    ],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

export class IUniswapV3PoolActions__factory {
  static readonly abi = _abi
  static createInterface(): IUniswapV3PoolActionsInterface {
    return new utils.Interface(_abi) as IUniswapV3PoolActionsInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IUniswapV3PoolActions {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IUniswapV3PoolActions
  }
}
