import { BigNumber, BigNumberish } from 'ethers'
import { getStrikePriceOfRange } from '.'
import { TICKS } from '../constants'
import { priceToTick } from './uni'

export type LPRange = {
  index: number
  lower: number
  upper: number
}

export function getIndexOfCenter(currentPriceBn: BigNumberish): number {
  for (let i = 0; i < TICKS.length; i++) {
    if (
      getStrikePriceOfRange(TICKS[i].lower, TICKS[i].upper).gt(currentPriceBn)
    ) {
      return i - 1
    }
  }

  return TICKS.length - 1
}

export function getTicks(currentPriceBn: BigNumberish): LPRange[] {
  const centerIndex = getIndexOfCenter(currentPriceBn)

  const ticks = TICKS.slice(centerIndex - 2, centerIndex + 4)

  return ticks
}

export function getTickIndexesFromLowerTicks(lowerTicks: number[]) {
  return TICKS.filter(tick => lowerTicks.indexOf(tick.lower) >= 0).map(
    tick => tick.index
  )
}

const T = 3 / 365
const VOLATILITY = 0.75
const SQRT_T_2PI = Math.sqrt(T / (2 * Math.PI))

export function getStrikes(currentPriceBn: BigNumberish): number[] {
  const strikes = new Array(30)

  const currentPrice = BigNumber.from(currentPriceBn).div(1000000).toNumber()
  const offset = currentPrice % 100
  const base = currentPrice - offset
  for (let i = 0; i < 30; i++) {
    strikes[i] = base - 800 + 200 * i
  }

  return strikes
}

export function getMetaTicks(currentPriceBn: BigNumberish): LPRange[] {
  const strikes = getStrikes(currentPriceBn)

  const ticks = []

  for (const strike of strikes) {
    const a = 1 + VOLATILITY * SQRT_T_2PI
    const b = 1 - VOLATILITY * SQRT_T_2PI
    const lowerPrice = strike * (b / a) * (b / a)
    const upperPrice = strike * (a / b) * (a / b)

    let lower = priceToTick(BigNumber.from(Math.floor(lowerPrice * 1000000)))
    let upper = priceToTick(BigNumber.from(Math.floor(upperPrice * 1000000)))

    lower = Math.round(lower / 10) * 10
    upper = Math.round(upper / 10) * 10

    ticks.push({
      index: ticks.length,
      lower,
      upper
    })
  }

  return ticks
}
