import React, { useEffect, useState } from 'react'
import { toAmountString, toPriceString } from '../../../utils/number'
import useTrade from '../../../store/trade'
import { TradeType } from '../../../constants/enum'
import { getStrikePrice } from '../../../utils'
import { tickToPrice } from '../../../utils/uni'
import { toUnscaled } from '../../../utils/bn'
import ethUsdcIcon from '../../../assets/ethusdc.svg'
import { useCachedPrice } from '../../../hooks/usePrice'
import { ChartMode, RangeChart } from './RangeChart'
import { HistoryChart, HistoryChartMode, IVHistoryChart } from './HistoryChart'
import { Tab, TabList } from '../../common/Tab'
import { getTicks } from '../../../utils/ranges'
import { TICKS } from '../../../constants'
import { useLPTStatus } from '../../../hooks/query/useLPTStatus'

const CenterArea = () => {
  const { strategyType, rangeIds, setRangeIds } = useTrade()
  const [chartMode, setChartMode] = useState(ChartMode.Value)
  const [historyChartMode, setHistoryChartMode] = useState(
    HistoryChartMode.Interest
  )

  const price = useCachedPrice()

  const ticks = getTicks(price.indexPrice)
  const lptStatus = useLPTStatus(ticks)

  useEffect(() => {
    if (ticks.length > 0) {
      setRangeIds([ticks[2].index])
    }
    // eslint-disable-next-line
  }, [ticks.length])

  if (price.price.eq(0)) {
    return <div className="p-4">Loading price from the chain.</div>
  }

  if (ticks.length === 0) {
    return <div />
  }

  const RangeButton = ({ rangeIndex }: { rangeIndex: number }) => {
    return (
      <button
        className={`w-24 h-[62px] bg-secondaly rounded-md p-1 text-sm font-normal ${
          rangeIds.indexOf(rangeIndex) >= 0 ? 'final-gradient-outline' : ''
        }`}
        onClick={() => {
          if (
            strategyType === TradeType.LONG_CALL ||
            strategyType === TradeType.LONG_PUT ||
            strategyType === TradeType.SHORT_CALL ||
            strategyType === TradeType.SHORT_PUT
          ) {
            setRangeIds([rangeIndex])
          } else {
            if (rangeIds.length == 0) {
              setRangeIds([rangeIndex])
            } else if (rangeIds.length == 1) {
              setRangeIds(rangeIds.concat([rangeIndex]))
            } else {
              setRangeIds([rangeIndex])
            }
          }
        }}
      >
        <div>${toPriceString(getStrikePrice(rangeIndex))}</div>
        <div className="text-[10px] text-subtext">
          ${toUnscaled(tickToPrice(TICKS[rangeIndex].lower), 6, 2).toString()}~
          ${toUnscaled(tickToPrice(TICKS[rangeIndex].upper), 6, 2).toString()}
        </div>
      </button>
    )
  }

  return (
    <div className="px-4 py-2 h-[408px] bg-black">
      <div className="flex items-center justify-start pb-2 space-x-12">
        <div className="flex items-center justify-center space-x-2">
          <div>
            <img src={ethUsdcIcon}></img>
          </div>
          <div>
            <div className="text-base">WETH/USDC</div>
            <div className="text-xs lg:text-sm text-subtext whitespace-nowrap">
              Perpetual options
            </div>
          </div>
        </div>
        <div className="w-52">
          <div className="text-subtext">ETH Price</div>
          <div className="flex items-center justify-between justify-items-end pl-8">
            <div>
              <div className="text-[8px] text-subtext text-right">Uniswap</div>
              <div className="text-right pr-2">
                ${toPriceString(toUnscaled(price.price, 6))}
              </div>
            </div>
            <div>
              <div className="text-[8px] text-subtext text-right">TWAP</div>
              <div className="text-right pr-2">
                ${toPriceString(toUnscaled(price.indexPrice, 6))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden xl:block">
        <div className="flex items-center justify-between py-4 space-x-2">
          <div className="w-32 mb-2">
            <div className="text-sm font-normal pb-1">Option&#39;s Strike</div>
            <div className="text-[11px] text-subtext font-normal">
              Uniswap LP Range behind
            </div>
          </div>
          {ticks.map(tick => (
            <RangeButton key={tick.index} rangeIndex={tick.index} />
          ))}
        </div>
      </div>
      <div className="block xl:hidden">
        <div className="grid grid-cols-3 items-center justify-between py-4 space-y-2">
          {ticks.map(tick => (
            <RangeButton key={tick.index} rangeIndex={tick.index} />
          ))}
        </div>
      </div>

      <div className="hidden xl:block pb-6">
        <div className="text-sm font-normal pb-1">LPT behind Contract</div>
        <div className="flex items-center justify-between space-x-2">
          <div className="w-32 mb-0">
            <div className="text-[11px] text-subtext font-normal">
              Borrow / Supply
            </div>
          </div>
          {lptStatus.map((status, i) => (
            <div
              key={i}
              className="w-24 text-[11px] text-subtext font-normal text-center"
            >
              {toAmountString(status.debtAmount)} /{' '}
              {toAmountString(status.collateralAmount)}
            </div>
          ))}
        </div>
      </div>

      <div className="py-2 border-t-[1.5px] border-third">
        <div className="flex items-center justify-around">
          <div>
            <TabList
              onSelect={tabIndex => {
                setChartMode(tabIndex as ChartMode)
              }}
            >
              <Tab
                selected={chartMode === ChartMode.Value}
                tabId={ChartMode.Value}
              >
                P&L
              </Tab>
              <Tab
                selected={chartMode === ChartMode.Delta}
                tabId={ChartMode.Delta}
              >
                Delta
              </Tab>
              <Tab
                selected={chartMode === ChartMode.Gamma}
                tabId={ChartMode.Gamma}
              >
                Gamma
              </Tab>
            </TabList>
            <div className="w-96 h-[254px]">
              <RangeChart chartMode={chartMode} />
            </div>
          </div>
          <div className="hidden xl:block">
            <TabList
              onSelect={tabIndex => {
                setHistoryChartMode(tabIndex as HistoryChartMode)
              }}
            >
              <Tab
                selected={historyChartMode === HistoryChartMode.Interest}
                tabId={HistoryChartMode.Interest}
              >
                24h Interests History
              </Tab>
              <Tab
                selected={
                  historyChartMode === HistoryChartMode.ImpliedVolatility
                }
                tabId={HistoryChartMode.ImpliedVolatility}
              >
                IV History
              </Tab>
            </TabList>

            <div className="w-96 h-[254px]">
              {historyChartMode === HistoryChartMode.Interest ? (
                <HistoryChart />
              ) : (
                <IVHistoryChart />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CenterArea
