import React, { useEffect } from 'react'
import { useLocation, Link } from 'wouter'
import { HeaderConnectButton } from './ConnectButton'
import logo from '../../assets/logo.svg'
import vaultStore from '../../store/vault'
import { useOwnVault } from '../../hooks/query/useOwnVault'
import { useChainId } from '../../hooks/network'

enum SelectableMenu {
  Options,
  Futures,
  Strategy,
  Faucet
}

function getSelectableMenu(path: string) {
  if (path.indexOf('options') >= 0) {
    return SelectableMenu.Options
  } else if (path.indexOf('position') >= 0) {
    return SelectableMenu.Futures
  } else if (path.indexOf('strategy') >= 0) {
    return SelectableMenu.Strategy
  } else if (path.indexOf('faucet') >= 0) {
    return SelectableMenu.Faucet
  }
  return SelectableMenu.Options
}

const Header = () => {
  const chainId = useChainId()
  const [path] = useLocation()
  const selected = getSelectableMenu(path)

  const vaultIds = useOwnVault()
  const { setVaultId } = vaultStore()

  useEffect(() => {
    if (vaultIds.isAvailable) {
      setVaultId(vaultIds.data.length > 0 ? vaultIds.data[0].vaultId : 0)
    }
    // eslint-disable-next-line
  }, [vaultIds.isAvailable, vaultIds.data])

  return (
    <>
      <div className="flex items-center justify-between bg-black border-b-[1.5px] border-third w-full h-[60px] px-4 font-normal">
        <div className="flex items-center">
          <div className="hidden md:block">
            <img src={logo} alt="Logo"></img>
          </div>
        </div>
        <div>
          <div>
            <ul className="flex flex-row items-center space-x-8">
              <li
                className={`${
                  selected === SelectableMenu.Options
                    ? 'text-white text-2xl'
                    : 'text-subtext text-xl'
                }`}
              >
                <Link href="/options">Options</Link>
              </li>
              {chainId !== 42161 ? (
                <li
                  className={`${
                    selected === SelectableMenu.Faucet
                      ? 'text-white text-2xl'
                      : 'text-subtext text-xl'
                  }`}
                >
                  <Link href="/faucet">Faucet</Link>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
        <div></div>

        <div className="flex items-center">
          <div>
            <div className="pr-10 text-2xl">
              <a
                href="https://predy.gitbook.io/predy3/"
                rel="noreferrer"
                target="_blank"
              >
                Docs
              </a>
            </div>
          </div>
          <div>
            <div className="px-2">
              <HeaderConnectButton />
            </div>
          </div>
          <div>
            <div className="pl-2 pr-10"></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
