/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from 'ethers'
import { Provider, TransactionRequest } from '@ethersproject/providers'
import type { MockPriceFeed, MockPriceFeedInterface } from '../MockPriceFeed'

const _abi = [
  {
    inputs: [],
    name: 'latestRoundData',
    outputs: [
      {
        internalType: 'uint80',
        name: 'roundId',
        type: 'uint80'
      },
      {
        internalType: 'int256',
        name: 'answer',
        type: 'int256'
      },
      {
        internalType: 'uint256',
        name: 'startedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint256',
        name: 'updatedAt',
        type: 'uint256'
      },
      {
        internalType: 'uint80',
        name: 'answeredInRound',
        type: 'uint80'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint80',
        name: 'roundId',
        type: 'uint80'
      },
      {
        internalType: 'int256',
        name: 'answer',
        type: 'int256'
      }
    ],
    name: 'setLatestRoundData',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  }
]

const _bytecode =
  '0x608060405234801561001057600080fd5b50610171806100206000396000f3fe608060405234801561001057600080fd5b50600436106100365760003560e01c80639f76840a1461003b578063feaf968c14610069575b600080fd5b6100676004803603604081101561005157600080fd5b506001600160501b0381351690602001356100b5565b005b61007161011c565b60405180866001600160501b03168152602001858152602001848152602001838152602001826001600160501b031681526020019550505050505060405180910390f35b6040805160a0810182526001600160501b0390931680845260208401839052429184018290526060840182905260006080909401849052835469ffffffffffffffffffff199081169091179093556001919091556002819055600355600480549091169055565b6000546001546002546003546004546001600160501b0394851694168556fea2646970667358221220e93d092805eb70942363f932b11d155eb5ec3a5b763984f0a1243140393cccbd64736f6c63430007060033'

export class MockPriceFeed__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0])
    } else {
      super(...args)
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<MockPriceFeed> {
    return super.deploy(overrides || {}) as Promise<MockPriceFeed>
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {})
  }
  attach(address: string): MockPriceFeed {
    return super.attach(address) as MockPriceFeed
  }
  connect(signer: Signer): MockPriceFeed__factory {
    return super.connect(signer) as MockPriceFeed__factory
  }
  static readonly bytecode = _bytecode
  static readonly abi = _abi
  static createInterface(): MockPriceFeedInterface {
    return new utils.Interface(_abi) as MockPriceFeedInterface
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): MockPriceFeed {
    return new Contract(address, _abi, signerOrProvider) as MockPriceFeed
  }
}
