import React from 'react'
import { LPRevenueChart } from '../components/protocol/LPRevenueChart'
import { ProtocolRevenueChart } from '../components/protocol/ProtocolRevenueChart'
import { UNDERLYING_ONE } from '../constants'
import { usePredyContext } from '../hooks/query/protocol/usePredyContext'
import { useVaultCount } from '../hooks/query/protocol/useVaultCount'
import { useAssetStatus } from '../hooks/query/useAssetStatus'
import { usePrice } from '../hooks/usePrice'
import { toUnscaled } from '../utils/bn'
import { toPriceString } from '../utils/number'

const ProtocolFeeView = () => {
  const price = usePrice()
  const predyContext = usePredyContext()
  const vaultCount = useVaultCount()
  const assetStatus = useAssetStatus()

  return (
    <>
      <div className="max-w-4xl mx-auto content-start">
        <div className="grid grid-cols-4 grid-rows-3 gap-4">
          <div className="col-span-1 row-span-1 border-2 border-third rounded-lg p-2">
            <h3 className="py-1 text-lg">Total Vaults</h3>
            <div>{vaultCount.data || 0}</div>
            <h3 className="py-1 text-lg">Total Positions</h3>
            <div>{predyContext.nextSubVaultId - 1}</div>
          </div>
          <div className="col-span-1 row-span-1 border-2 border-third rounded-lg p-2">
            <h3 className="py-1 text-lg">Total Asset</h3>
            <div>
              ${' '}
              {assetStatus.isSuccess && price.isSuccess
                ? toPriceString(
                    toUnscaled(
                      assetStatus.data[0].collateralAmount
                        .mul(price.data?.indexPrice)
                        .div(UNDERLYING_ONE)
                        .add(assetStatus.data[1].collateralAmount),
                      6,
                      2
                    )
                  )
                : 0}
            </div>

            <h3 className="py-1 text-lg">Total Debt</h3>
            <div>
              ${' '}
              {assetStatus.isSuccess && price.isSuccess
                ? toPriceString(
                    toUnscaled(
                      assetStatus.data[0].debtAmount
                        .mul(price.data?.indexPrice)
                        .div(UNDERLYING_ONE)
                        .add(assetStatus.data[1].debtAmount),
                      6,
                      2
                    )
                  )
                : 0}
            </div>
          </div>

          <div className="col-span-1 row-span-1 border-2 border-third rounded-lg p-2">
            <h3 className="py-1 text-lg">Total Protocol Fee</h3>
            <div>
              ${' '}
              {price.isSuccess
                ? toUnscaled(
                    predyContext.accumulatedProtocolFee0
                      .mul(price.data?.indexPrice)
                      .div(UNDERLYING_ONE)
                      .add(predyContext.accumulatedProtocolFee1),
                    6,
                    2
                  )
                : 0}
            </div>
            <div className="text-xs">
              {toUnscaled(predyContext.accumulatedProtocolFee0, 18, 6)} ETH
            </div>
            <div className="text-xs">
              {toPriceString(
                toUnscaled(predyContext.accumulatedProtocolFee1, 6)
              )}{' '}
              USDC
            </div>
          </div>

          <div className="col-span-2 row-span-1 border-2 border-third rounded-lg p-2">
            <h3 className="py-1 text-lg">Daily Protocol Revenue Chart</h3>
            <ProtocolRevenueChart />
          </div>
          <div className="col-span-2 row-span-1 border-2 border-third rounded-lg p-2">
            <h3 className="py-1 text-lg">Daily LP Revenue Chart</h3>
            <LPRevenueChart />
          </div>
        </div>
      </div>
    </>
  )
}

export default ProtocolFeeView
