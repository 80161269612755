import { BigNumberish, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Position } from '../../utils/uni'
import { Reader__factory } from '../../typechain'
import { isValidPosition } from '../../utils/position'
import { STALE_TIME } from '../../constants'

export function useMinVaultValue(vaultId: BigNumberish, position: Position) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  return useQuery(
    ['min_vault_value', chainId, vaultId, position],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = Reader__factory.connect(addresses.Reader, provider)

      return await contract.calculateMinDeposit(vaultId, position, {
        from: account
      })
    },

    {
      enabled:
        vaultId >= 0 &&
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        isValidPosition(position),
      staleTime: STALE_TIME
    }
  )
}
