import React, { useEffect, useMemo, useState } from 'react'
import { Router, Route, Redirect } from 'wouter'
import { useEagerConnect } from './hooks/wallet'
import TradeView from './pages/TradeView'
import FaucetView from './pages/FaucetView'
import AssetView from './pages/AssetView'
import VaultView from './pages/VaultView'
import HedgeView from './pages/HedgeView'
import LendingView from './pages/LendingView'
import ProtocolFeeView from './pages/ProtocolFeeView'
import LoadingIndicator from './components/common/LoadingIndicator'
import NetworkErrorLabel from './components/common/NetworkErrorLabel'
import Header from './components/header/Header'
import { ApolloProvider } from '@apollo/client'
import { PredyClient } from './utils/apollo-client'
import { DEFAULT_CHAIN } from './constants'
import { useChainId, useIsSupportedChain } from './hooks/network'

const App = () => {
  const [loaded, setLoaded] = useState(false)

  const chainId = useChainId()
  const supported = useIsSupportedChain()

  const client = useMemo(() => PredyClient[chainId || DEFAULT_CHAIN], [chainId])

  useEffect(() => {
    ; (async () => {
      // check residence once. if value is set to true, proceed
      setLoaded(true)
    })()
    // eslint-disable-next-line
  }, [])

  const tried = useEagerConnect()
  if (!tried || !loaded) return <LoadingIndicator />

  return (
    <ApolloProvider client={client || PredyClient[DEFAULT_CHAIN]}>
      <div className="block sm:hidden bg-background">
        <div className="p-2 text-md">Mobile version is under developing</div>
      </div>
      <div className="hidden sm:block bg-background">
        <NetworkErrorLabel />
        <Router>
          <Header />
          {supported ? (
            <div className="mx-auto mt-4">
              <Route path="/">
                <Redirect to="/options" />
              </Route>
              <Route path="/options" component={TradeView} />
              <Route path="/hedge" component={HedgeView} />
              <Route path="/lending" component={LendingView} />
              <Route path="/faucet" component={FaucetView} />
              <Route path="/asset" component={AssetView} />
              <Route path="/vault/:vaultId" component={VaultView} />
              <Route path="/protocol" component={ProtocolFeeView} />
            </div>
          ) : (<div />)}
        </Router>
      </div>
    </ApolloProvider >
  )
}

export default App
