import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { STALE_TIME } from '../../constants'

export function useVault(vaultId: number) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  return useQuery(
    ['vault', chainId, vaultId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const vault = await contract.getVault(vaultId)

      return {
        vaultId: vault[0],
        marginAmount0: vault[1],
        marginAmount1: vault[2],
        subVaults: vault[3]
      }
    },

    {
      enabled:
        !!account && supportedChain && !!provider && !!addresses && vaultId > 0,
      staleTime: STALE_TIME
    }
  )
}

export function useSubVault(subVaultId: number) {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  return useQuery(
    ['subvault', chainId, subVaultId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      return await contract.getSubVault(subVaultId)
    },

    {
      enabled: !!account && supportedChain && !!provider && !!addresses
    }
  )
}
