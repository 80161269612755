import React, { useState } from 'react'
import { toUnscaled } from '../../../utils/bn'
import tradeStore from '../../../store/trade'
import pendingStore from '../../../store/pending'
import pvaultSVG from '../../../assets/pvault.svg'
import { AmountForm } from './AmountForm'
import { TradeButtonArea } from './TradeButtonArea'
import { toPriceString } from '../../../utils/number'
import { usePrice } from '../../../hooks/usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../../utils'
import { useMinVaultValue } from '../../../hooks/query/useMinVaultValue'
import {
  calculatePositionValue,
  createPositionFromTradeData,
  EmptyPosition,
  getNumOfLPTsInPosition
} from '../../../utils/position'
import { useOpenPositionQuoter } from '../../../hooks/query/useQuoter'
import { useOpenPosition } from '../../../hooks/contracts/useOpenPosition'
import { encodeMetadata } from '../../../utils/metadata'
import { ensureVaultId, useOwnVault } from '../../../hooks/query/useOwnVault'
import { useVaultStatus } from '../../../hooks/query/useVaultStatus'
import { BigNumber } from 'ethers'
import { TradeType } from '../../../constants/enum'
import { useMarginUtilizing } from '../../../hooks/query/useMarginUtilizing'
import { usePredyContext } from '../../../hooks/query/protocol/usePredyContext'
import { useTradeInterestEst } from '../../../hooks/query/interest/useInterestEst'
import { concatPosition } from '../../../utils/uni'
import { MAX_NUM_OF_LPTS } from '../../../constants'

const TradeResult = () => {
  const [priceCheckError, setPriceCheckError] = useState<string | null>(null)
  const { strategyType, rangeIds, optionAmount, setOptionAmount } = tradeStore()
  const { setPendingTx } = pendingStore()

  const vaultIds = useOwnVault()
  const vaultId = ensureVaultId(vaultIds)

  const tradeData = {
    subVaultIndex: 0,
    strategyType,
    rangeIds,
    optionAmount
  }

  const price = usePrice()
  const context = usePredyContext()
  const position = createPositionFromTradeData(
    tradeData,
    price.isSuccess ? price.data.price : BigNumber.from(0),
    context.isMarginZero
  )
  const vaultStatus = useVaultStatus(vaultId)

  const interestEst = useTradeInterestEst(
    vaultStatus.isSuccess
      ? concatPosition(vaultStatus.data.positions)
      : EmptyPosition,
    position
  )

  const minCollateral = useMinVaultValue(vaultId, position)
  const requiredAmounts = useOpenPositionQuoter(vaultId, position)
  const marginUtilizing = useMarginUtilizing(vaultId, position)

  const openPosition = useOpenPosition()

  const tradePositionValue = calculatePositionValue(
    position,
    price.isSuccess ? price.data.price : BigNumber.from(0)
  )

  const onTrade = async () => {
    if (!vaultIds.isAvailable || optionAmount <= 0 || !price.isSuccess) {
      console.warn('Application has not been initialized for trade')
      return
    }

    const sqrtPrice = price.data.sqrtPrice

    try {
      const tx = await openPosition.mutateAsync({
        vaultId,
        position,
        lowerSqrtPrice: computeLowerSqrtPrice(sqrtPrice),
        upperSqrtPrice: computeUpperSqrtPrice(sqrtPrice),
        metadata: encodeMetadata(strategyType),
        targetMarginAmount0: 0,
        targetMarginAmount1: -2
      })

      // Set pending
      await setPendingTx(tx)
    } catch (e: any) {
      setPriceCheckError(e.reason || e.data.message)
      setTimeout(() => {
        setPriceCheckError(null)
      }, 5000)
    }
  }

  const hasEnoughUSDC = () => {
    if (!vaultStatus.isSuccess || vaultStatus.data.marginValue.lte(0)) {
      return false
    }

    if (requiredAmounts.error !== null) {
      return true
    }
    const totaCost = requiredAmounts.data.requiredAmounts[1]

    return vaultStatus.data.marginValue.gte(totaCost)
  }

  const isEnoughRangeSelected = () => {
    if (
      strategyType === TradeType.BULL_CALL_SPREAD ||
      strategyType === TradeType.BEAR_CALL_SPREAD ||
      strategyType === TradeType.LONG_STRANGLE ||
      strategyType === TradeType.SHORT_STRANGLE
    ) {
      return rangeIds.length === 2
    } else {
      return rangeIds.length === 1
    }
  }

  return (
    <div className="rounded-lg bg-black w-full py-4 pr-8 pl-2">
      <div className="pt-2">
        <img src={pvaultSVG} className="w-[72px] h-[24px]"></img>
      </div>
      <div className="pt-4">
        <div className="grid grid-cols-2 grid-rows-2 text-sm justify-items-end">
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Positions</div>
            <div className="text-right pr-2">
              $
              {toPriceString(
                vaultStatus.isSuccess
                  ? toUnscaled(vaultStatus.data.positionValue, 6, 2)
                  : 0
              )}
            </div>
          </div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Margin</div>
            <div className="text-right pr-2">
              $
              {toPriceString(
                vaultStatus.isSuccess
                  ? toUnscaled(vaultStatus.data.marginValue, 6, 2)
                  : 0
              )}
            </div>
          </div>
          <div className="space-x-2"></div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Total</div>
            <div className="text-right pr-2">
              $
              {toPriceString(
                vaultStatus.isSuccess
                  ? toUnscaled(
                      vaultStatus.data.positionValue.add(
                        vaultStatus.data.marginValue
                      ),
                      6,
                      2
                    )
                  : 0
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <AmountForm
          title={'Contract Size'}
          amount={optionAmount}
          onChange={amount => {
            setOptionAmount(amount)
          }}
        />
      </div>

      {/* Margin Utilizing */}
      <div className="pt-4">
        <h3>Margin Utilizing</h3>
        <div className="grid grid-cols-2 grid-rows-2 text-sm justify-items-end">
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Before</div>
            <div className="text-right pr-2">
              $
              {vaultStatus.isSuccess
                ? toPriceString(toUnscaled(vaultStatus.data.minCollateral, 6))
                : 0}
            </div>
          </div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">After</div>
            <div className="text-right pr-2">
              ${toPriceString(marginUtilizing.after)}
            </div>
          </div>
          <div className="space-x-2"></div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">
              Margin Utilizing
            </div>
            <div className="text-green text-right pr-2">
              ${toPriceString(marginUtilizing.marginUtilizing)}
            </div>
          </div>
        </div>
      </div>

      {/* Interest est. */}
      <div className="py-4">
        <h3>24h Interest est.</h3>
        <div className="grid grid-cols-2 grid-rows-2 text-sm justify-items-end">
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Before</div>
            <div className="text-right pr-2">
              $
              {interestEst.isSuccess
                ? toPriceString(interestEst.data.before)
                : 0}
            </div>
          </div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">After</div>
            <div className="text-right pr-2">
              $
              {interestEst.isSuccess
                ? toPriceString(interestEst.data.after)
                : 0}
            </div>
          </div>
          <div className="space-x-2"></div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Difference</div>
            <div className="text-green text-right pr-2">
              $
              {interestEst.isSuccess ? toPriceString(interestEst.data.diff) : 0}
            </div>
          </div>
        </div>
      </div>

      <TradeButtonArea
        onTrade={onTrade}
        hasEnoughUSDC={hasEnoughUSDC()}
        extraError={requiredAmounts.error || priceCheckError}
        isMarginEnough={
          minCollateral.isSuccess && vaultStatus.isSuccess
            ? minCollateral.data.lt(
                vaultStatus.data.marginValue
                  .add(vaultStatus.data.positionValue)
                  .add(tradePositionValue)
              )
            : true
        }
        isSubVaultsLoaded={true}
        isEnoughRangeSelected={isEnoughRangeSelected()}
        isTradeAmountsZero={optionAmount === 0}
        isPositionHasMaxLPTs={
          getNumOfLPTsInPosition(
            vaultStatus.isSuccess ? vaultStatus.data.positions : [EmptyPosition]
          ) >= MAX_NUM_OF_LPTS
        }
      />
    </div>
  )
}

export default TradeResult
