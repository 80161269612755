import { BigNumber, BigNumberish } from 'ethers'

// minutes in one day
export const ONE_DAY = 60 * 24

interface RawHistoryItem {
  timestamp: number
}

interface BasicHistoryItem {
  value: number
  timestamp: number
}

export function normalizeBasicHistory(data: BasicHistoryItem[]) {
  return normalizeHistory(
    data,
    (
      leftData: BasicHistoryItem,
      rightData: BasicHistoryItem,
      timestamp: number
    ) => {
      const value = computeLinerInterpolation(
        timestamp,
        leftData.timestamp,
        rightData.timestamp,
        leftData.value,
        rightData.value
      ).toNumber()

      return {
        timestamp,
        value
      }
    },
    (timestamp: number) => {
      return {
        timestamp,
        value: 0
      }
    }
  )
}

// interest

export type TokenInterestGrowth = {
  assetGrowth0: BigNumber
  assetGrowth1: BigNumber
  debtGrowth0: BigNumber
  debtGrowth1: BigNumber
  timestamp: number
}

export type LPTInterestGrowth = {
  lowerTick: number
  upperTick: number
  premiumGrowthForBorrower: BigNumber
  premiumGrowthForLender: BigNumber
  fee0Growth: BigNumber
  fee1Growth: BigNumber
  feeReceived: BigNumber
  totalReceived: BigNumber
  totalPaid: BigNumber
  timestamp: number
}

export function normalizeTokenGrowthHistory(data: TokenInterestGrowth[]) {
  return normalizeHistory(
    data,
    (
      leftData: TokenInterestGrowth,
      rightData: TokenInterestGrowth,
      timestamp: number
    ) => {
      const assetGrowth0 = computeLinerInterpolation(
        timestamp,
        leftData.timestamp,
        rightData.timestamp,
        leftData.assetGrowth0,
        rightData.assetGrowth0
      )
      const debtGrowth0 = computeLinerInterpolation(
        timestamp,
        leftData.timestamp,
        rightData.timestamp,
        leftData.debtGrowth0,
        rightData.debtGrowth0
      )
      const assetGrowth1 = computeLinerInterpolation(
        timestamp,
        leftData.timestamp,
        rightData.timestamp,
        leftData.assetGrowth1,
        rightData.assetGrowth1
      )
      const debtGrowth1 = computeLinerInterpolation(
        timestamp,
        leftData.timestamp,
        rightData.timestamp,
        leftData.assetGrowth1,
        rightData.assetGrowth1
      )

      return {
        timestamp,
        assetGrowth0,
        debtGrowth0,
        assetGrowth1,
        debtGrowth1
      }
    },
    (timestamp: number) => {
      return {
        timestamp,
        assetGrowth0: BigNumber.from(0),
        debtGrowth0: BigNumber.from(0),
        assetGrowth1: BigNumber.from(0),
        debtGrowth1: BigNumber.from(0)
      }
    }
  )
}

export function normalizeLPTGrowthHistory(data: LPTInterestGrowth[]) {
  return normalizeHistory(
    data,
    (
      leftData: LPTInterestGrowth,
      rightData: LPTInterestGrowth,
      timestamp: number
    ) => {
      const feeReceived = computeLinerInterpolation(
        timestamp,
        leftData.timestamp,
        rightData.timestamp,
        leftData.feeReceived,
        rightData.feeReceived
      )
      const totalReceived = computeLinerInterpolation(
        timestamp,
        leftData.timestamp,
        rightData.timestamp,
        leftData.totalReceived,
        rightData.totalReceived
      )
      const totalPaid = computeLinerInterpolation(
        timestamp,
        leftData.timestamp,
        rightData.timestamp,
        leftData.totalPaid,
        rightData.totalPaid
      )

      return {
        timestamp,
        lowerTick: leftData.lowerTick,
        upperTick: leftData.upperTick,
        premiumGrowthForBorrower: BigNumber.from(0),
        premiumGrowthForLender: BigNumber.from(0),
        fee0Growth: BigNumber.from(0),
        fee1Growth: BigNumber.from(0),
        feeReceived,
        totalReceived,
        totalPaid
      }
    },
    (timestamp: number) => {
      return {
        timestamp,
        lowerTick: 0,
        upperTick: 0,
        premiumGrowthForBorrower: BigNumber.from(0),
        premiumGrowthForLender: BigNumber.from(0),
        fee0Growth: BigNumber.from(0),
        fee1Growth: BigNumber.from(0),
        feeReceived: BigNumber.from(0),
        totalReceived: BigNumber.from(0),
        totalPaid: BigNumber.from(0)
      }
    }
  )
}

// abstract

function computeLinerInterpolation(
  x: number,
  ax: number,
  bx: number,
  ay: BigNumberish,
  by: BigNumberish
) {
  return bx < ax
    ? BigNumber.from(ay)
        .mul(x - bx)
        .add(BigNumber.from(by).mul(ax - x))
        .div(ax - bx)
    : BigNumber.from(ay)
}

export function normalizeHistory<T extends RawHistoryItem>(
  data: T[],
  f: (left: T, right: T, timestamp: number) => T,
  createEmptyData: (timestamp: number) => T,
  interval = ONE_DAY
) {
  if (data.length === 0) {
    return []
  }

  const descData = [...data].reverse()

  const latestTimestamp = descData[0].timestamp

  const output: T[] = []

  let timestamp = latestTimestamp

  for (let i = 0; i < 31; i++) {
    const leftData = getLeftData(timestamp, descData)
    const rightData = getRightData(timestamp, descData)

    if (leftData === undefined || rightData === undefined) {
      output.push(createEmptyData(timestamp))
    } else {
      output.push(f(leftData, rightData, timestamp))
    }

    timestamp -= interval
  }

  return [...output].reverse()
}

export function getLeftData<T extends RawHistoryItem>(
  timestamp: number,
  data: T[]
) {
  const leftData = data.filter(d => d.timestamp >= timestamp)

  return leftData[leftData.length - 1]
}

export function getRightData<T extends RawHistoryItem>(
  timestamp: number,
  data: T[]
) {
  const rightData = data.filter(d => d.timestamp <= timestamp)

  return rightData[0]
}
