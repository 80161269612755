import { useWeb3React } from '@web3-react/core'
import { ethers } from 'ethers'
import React from 'react'
import HorizontalRule from '../components/common/HorizontalRule'
import { useMintMutation } from '../hooks/contracts/useMint'
import { useERC20BalanceQuery } from '../hooks/query/balance'
import { useAddresses } from '../hooks/useAddress'
import { toScaled, toUnscaled } from '../utils/bn'

const FaucetView = () => {
  const addresses = useAddresses()
  const { account } = useWeb3React<ethers.providers.Web3Provider>()
  const balance = useERC20BalanceQuery(addresses ? addresses.QuoteToken : '')

  const mint = useMintMutation()

  const onGet = async () => {
    if (!addresses || !account) {
      return
    }

    await mint.mutateAsync({
      address: addresses.QuoteToken,
      account: account,
      amount: toScaled(2000, 6)
    })
  }
  return (
    <>
      <div className="max-w-7xl mx-auto">
        <div className="flex items-center justify-center">
          <div className="w-72">
            <h3 className="text-xl">Faucet</h3>
            <HorizontalRule />
            <div className="flex items-center justify-between">
              <div>Balance</div>
              <div>{toUnscaled(balance, 6, 2)} USDC</div>
            </div>
            <div className="flex justify-center mt-6">
              <button
                className="w-40 p-4 final-gradient rounded-md text-md font-semibold"
                onClick={onGet}
              >
                Get Mock USDC
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FaucetView
