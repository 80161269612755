import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Reader__factory } from '../../typechain'
import { toUnscaled } from '../../utils/bn'
import { useTokenState } from './interest/useTokenState'

type AssetStatus = {
  collateralAmount: BigNumber
  debtAmount: BigNumber
  utilizationRatio: BigNumber
}

export function useAssetStatusUnscaled() {
  const assetStatus = useAssetStatus()

  if (assetStatus.isSuccess) {
    return [
      {
        collateralAmount: toUnscaled(
          assetStatus.data[0].collateralAmount,
          18,
          3
        ),
        debtAmount: toUnscaled(assetStatus.data[0].debtAmount, 18, 3),
        utilizationRatio: toUnscaled(assetStatus.data[0].utilizationRatio, 18)
      },
      {
        collateralAmount: toUnscaled(
          assetStatus.data[1].collateralAmount,
          6,
          2
        ),
        debtAmount: toUnscaled(assetStatus.data[1].debtAmount, 6, 2),
        utilizationRatio: toUnscaled(assetStatus.data[1].utilizationRatio, 18)
      }
    ]
  } else {
    return [
      {
        collateralAmount: 0,
        debtAmount: 0,
        utilizationRatio: 0
      },
      {
        collateralAmount: 0,
        debtAmount: 0,
        utilizationRatio: 0
      }
    ]
  }
}

export function useAssetStatus() {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const tokenState = useTokenState()

  return useQuery<AssetStatus[]>(
    ['asset_status', account, chainId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')
      if (!tokenState.isSuccess) throw new Error('addresses not set')

      const reader = Reader__factory.connect(addresses.Reader, provider)

      const result = await reader.getAssetStatus(
        tokenState.data[0],
        tokenState.data[1]
      )

      return [
        {
          collateralAmount: result[0],
          debtAmount: result[1],
          utilizationRatio: result[2]
        },
        {
          collateralAmount: result[3],
          debtAmount: result[4],
          utilizationRatio: result[5]
        }
      ]
    },

    {
      enabled:
        !!account &&
        supportedChain &&
        !!provider &&
        !!addresses &&
        tokenState.isSuccess,
      staleTime: 30000
    }
  )
}
