import create from 'zustand'
import { TradeType } from '../constants/enum'

export type TradeData = {
  subVaultIndex: number
  strategyType: TradeType
  rangeIds: number[]
  optionAmount: number
}

interface State {
  subVaultIndex: number
  strategyType: TradeType
  rangeIds: number[]
  optionAmount: number
  setStrategyType: (strategyType: TradeType) => void
  setRangeIds: (rangeIds: number[]) => void
  setOptionAmount: (optionAmount: number) => void
}

const useStore = create<State>(set => ({
  subVaultIndex: -1,
  strategyType: TradeType.SHORT_CALL,
  rangeIds: [2],
  optionAmount: 1,
  setStrategyType: (strategyType: TradeType) => set({ strategyType }),
  setRangeIds: (rangeIds: number[]) => {
    // rangeIds must be sorted
    rangeIds.sort((a, b) => a - b)
    set({ rangeIds })
  },
  setOptionAmount: (optionAmount: number) => set({ optionAmount })
}))

export default useStore
