import { BigNumber } from 'ethers'
import { getStrikePriceByLowerTick, getStrikePriceOfRange } from '.'
import { TICKS, UNDERLYING_ONE } from '../constants'
import { TradeType } from '../constants/enum'
import { TradeData } from '../store/trade'
import { toScaled, toUnscaled } from './bn'
import { TokenInterestGrowth } from './interest'
import { LPRange } from './ranges'
import {
  getAmount0ForLiquidity,
  getAmount1ForLiquidity,
  getAmounts,
  getAmountsForLiquidity,
  getDebtAmounts,
  getLiquidity,
  getSqrtRatioAtTick,
  getValue,
  LPT,
  Position,
  priceToSqrtPrice,
  priceToTick
} from './uni'

const ONE = UNDERLYING_ONE
const MARGIN_ONE = BigNumber.from('1000000')
const ZERO = BigNumber.from('0')
export const EmptyPosition = {
  subVaultId: 0,
  asset0: ZERO,
  asset1: ZERO,
  debt0: ZERO,
  debt1: ZERO,
  lpts: []
}

export function createPosition(
  subVaultId: number,
  longs: number[],
  shorts: number[],
  asset0: number,
  asset1: number,
  ticks: LPRange[]
): Position {
  const lptLongs = longs.map((long, i) => {
    const lower = ticks[i].lower
    const upper = ticks[i].upper

    if (long) {
      return {
        isCollateral: false,
        lowerTick: lower,
        upperTick: upper,
        liquidity: getLiquidity(
          lower,
          lower,
          upper,
          BigNumber.from(long).mul(ONE)
        )
      }
    } else {
      return {
        isCollateral: true,
        lowerTick: lower,
        upperTick: upper,
        liquidity: getLiquidity(
          lower,
          lower,
          upper,
          BigNumber.from(long).mul(ONE).mul(-1)
        )
      }
    }
  })
  const lptShorts = shorts.map((short, i) => {
    const lower = ticks[i].lower
    const upper = ticks[i].upper

    if (short) {
      return {
        isCollateral: true,
        lowerTick: lower,
        upperTick: upper,
        liquidity: getLiquidity(
          lower,
          lower,
          upper,
          BigNumber.from(short).mul(ONE)
        )
      }
    } else {
      return {
        isCollateral: false,
        lowerTick: lower,
        upperTick: upper,
        liquidity: getLiquidity(
          lower,
          lower,
          upper,
          BigNumber.from(short).mul(ONE).mul(-1)
        )
      }
    }
  })

  const position = {
    subVaultId,
    asset0: BigNumber.from(asset0).mul(ONE),
    asset1: BigNumber.from(asset1).mul(MARGIN_ONE),
    debt0: BigNumber.from(0),
    debt1: BigNumber.from(0),
    lpts: lptLongs.concat(lptShorts).filter(lpt => lpt.liquidity.gt(0))
  }

  return position
}

export function isValidPosition(position: Position) {
  return position.subVaultId >= 0
}

function adjustUSDC(
  position: Position,
  price: BigNumber,
  isMarginZero: boolean
) {
  const value = getValue(position, priceToTick(price), isMarginZero)

  if (position.asset1.gte(value)) {
    position.asset1 = position.asset1.sub(value)
  } else {
    position.debt1 = value.sub(position.asset1)
    position.asset1 = ZERO
  }

  return position
}

/**
 * @description create Position object from TradeData.
 * @param tradeData tradeData.rangeIds must be sorted.
 * @returns
 */
export function createPositionFromTradeData(
  tradeData: TradeData,
  price: BigNumber,
  isMarginZero: boolean
): Position {
  if (price.eq(0)) {
    return EmptyPosition
  }

  const lower = TICKS[tradeData.rangeIds[0]].lower
  const upper = TICKS[tradeData.rangeIds[0]].upper
  const amount = toScaled(tradeData.optionAmount, 18)

  if (tradeData.strategyType === TradeType.LONG_CALL) {
    const liquidity = getLiquidity(lower, lower, upper, amount)

    const position = {
      subVaultId: tradeData.subVaultIndex,
      asset0: amount,
      asset1: ZERO,
      debt0: ZERO,
      debt1: ZERO,
      lpts: [
        {
          isCollateral: false,
          lowerTick: lower,
          upperTick: upper,
          liquidity: liquidity
        }
      ]
    }

    return adjustUSDC(position, price, isMarginZero)
  } else if (tradeData.strategyType === TradeType.LONG_PUT) {
    const liquidity = getLiquidity(lower, lower, upper, amount)

    const usdcAmount = getAmount1ForLiquidity(
      getSqrtRatioAtTick(lower),
      getSqrtRatioAtTick(upper),
      liquidity
    )

    const position = {
      subVaultId: tradeData.subVaultIndex,
      asset0: ZERO,
      asset1: usdcAmount,
      debt0: ZERO,
      debt1: ZERO,
      lpts: [
        {
          isCollateral: false,
          lowerTick: lower,
          upperTick: upper,
          liquidity: liquidity
        }
      ]
    }

    return adjustUSDC(position, price, isMarginZero)
  } else if (tradeData.strategyType === TradeType.SHORT_CALL) {
    const liquidity = getLiquidity(lower, lower, upper, amount)

    const position = {
      subVaultId: tradeData.subVaultIndex,
      asset0: ZERO,
      asset1: ZERO,
      debt0: amount,
      debt1: ZERO,
      lpts: [
        {
          isCollateral: true,
          lowerTick: lower,
          upperTick: upper,
          liquidity: liquidity
        }
      ]
    }

    return adjustUSDC(position, price, isMarginZero)
  } else if (tradeData.strategyType === TradeType.SHORT_PUT) {
    const liquidity = getLiquidity(lower, lower, upper, amount)

    const position = {
      subVaultId: tradeData.subVaultIndex,
      asset0: ZERO,
      asset1: ZERO,
      debt0: ZERO,
      debt1: ZERO,
      lpts: [
        {
          isCollateral: true,
          lowerTick: lower,
          upperTick: upper,
          liquidity: liquidity
        }
      ]
    }

    return adjustUSDC(position, price, isMarginZero)
  } else if (tradeData.strategyType === TradeType.LONG_STRANGLE) {
    if (tradeData.rangeIds.length < 2) {
      return EmptyPosition
    }
    const lower2 = TICKS[tradeData.rangeIds[1]].lower
    const upper2 = TICKS[tradeData.rangeIds[1]].upper

    const liquidity1 = getLiquidity(lower, lower, upper, amount)
    const liquidity2 = getLiquidity(lower2, lower2, upper2, amount)

    const usdcAmount = getAmount1ForLiquidity(
      getSqrtRatioAtTick(lower),
      getSqrtRatioAtTick(upper),
      liquidity1
    )

    const position = {
      subVaultId: tradeData.subVaultIndex,
      asset0: amount,
      asset1: usdcAmount,
      debt0: ZERO,
      debt1: ZERO,
      lpts: [
        {
          isCollateral: false,
          lowerTick: lower,
          upperTick: upper,
          liquidity: liquidity1
        },
        {
          isCollateral: false,
          lowerTick: lower2,
          upperTick: upper2,
          liquidity: liquidity2
        }
      ]
    }

    return adjustUSDC(position, price, isMarginZero)
  } else if (tradeData.strategyType === TradeType.SHORT_STRANGLE) {
    if (tradeData.rangeIds.length < 2) {
      return EmptyPosition
    }
    const lower2 = TICKS[tradeData.rangeIds[1]].lower
    const upper2 = TICKS[tradeData.rangeIds[1]].upper

    const liquidity1 = getLiquidity(lower, lower, upper, amount)
    const liquidity2 = getLiquidity(lower2, lower2, upper2, amount)

    const position = {
      subVaultId: tradeData.subVaultIndex,
      asset0: ZERO,
      asset1: ZERO,
      debt0: amount,
      debt1: ZERO,
      lpts: [
        {
          isCollateral: true,
          lowerTick: lower,
          upperTick: upper,
          liquidity: liquidity1
        },
        {
          isCollateral: true,
          lowerTick: lower2,
          upperTick: upper2,
          liquidity: liquidity2
        }
      ]
    }

    return adjustUSDC(position, price, isMarginZero)
  } else if (tradeData.strategyType === TradeType.BULL_CALL_SPREAD) {
    if (tradeData.rangeIds.length < 2) {
      return EmptyPosition
    }

    const lower2 = TICKS[tradeData.rangeIds[1]].lower
    const upper2 = TICKS[tradeData.rangeIds[1]].upper

    const liquidity1 = getLiquidity(lower, lower, upper, amount)
    const liquidity2 = getLiquidity(lower2, lower2, upper2, amount)

    const position = {
      subVaultId: tradeData.subVaultIndex,
      asset0: ZERO,
      asset1: ZERO,
      debt0: ZERO,
      debt1: ZERO,
      lpts: [
        {
          isCollateral: false,
          lowerTick: lower,
          upperTick: upper,
          liquidity: liquidity1
        },
        {
          isCollateral: true,
          lowerTick: lower2,
          upperTick: upper2,
          liquidity: liquidity2
        }
      ]
    }

    return adjustUSDC(position, price, isMarginZero)
  } else if (tradeData.strategyType === TradeType.BEAR_CALL_SPREAD) {
    if (tradeData.rangeIds.length < 2) {
      return EmptyPosition
    }
    const lower2 = TICKS[tradeData.rangeIds[1]].lower
    const upper2 = TICKS[tradeData.rangeIds[1]].upper

    const liquidity1 = getLiquidity(lower, lower, upper, amount)
    const liquidity2 = getLiquidity(lower2, lower2, upper2, amount)

    const usdcAmount1 = getAmount1ForLiquidity(
      getSqrtRatioAtTick(lower),
      getSqrtRatioAtTick(upper),
      liquidity1
    )
    const usdcAmount2 = getAmount1ForLiquidity(
      getSqrtRatioAtTick(lower2),
      getSqrtRatioAtTick(upper2),
      liquidity2
    )

    const position = {
      subVaultId: tradeData.subVaultIndex,
      asset0: ZERO,
      asset1: usdcAmount2.sub(usdcAmount1),
      debt0: ZERO,
      debt1: ZERO,
      lpts: [
        {
          isCollateral: true,
          lowerTick: lower,
          upperTick: upper,
          liquidity: liquidity1
        },
        {
          isCollateral: false,
          lowerTick: lower2,
          upperTick: upper2,
          liquidity: liquidity2
        }
      ]
    }

    return adjustUSDC(position, price, isMarginZero)
  } else {
    return EmptyPosition
  }
}

export function computeTokenInterestForPosition(
  position: Position,
  interest: TokenInterestGrowth,
  price: BigNumber
): BigNumber {
  const amount0 = position.asset0
    .mul(interest.assetGrowth0)
    .sub(position.debt0.mul(interest.debtGrowth0))
    .div(ONE)
  const amount1 = position.asset1
    .mul(interest.assetGrowth1)
    .sub(position.debt1.mul(interest.debtGrowth1))
    .div(ONE)

  return amount0.mul(price).div(UNDERLYING_ONE).add(amount1)
}

export function computeInterest(
  position: Position,
  premiums: {
    feeReceived: BigNumber
    totalReceived: BigNumber
    totalPaid: BigNumber
  }[],
  interest: TokenInterestGrowth,
  price: BigNumber
): { interest: BigNumber; fee: BigNumber; fees: BigNumber[] } {
  const tokenInterestAmount = computeTokenInterestForPosition(
    position,
    interest,
    price
  )

  const lptInterest = position.lpts
    .map((lpt, i) => {
      if (lpt.isCollateral) {
        return premiums[i].totalReceived.mul(lpt.liquidity).div(UNDERLYING_ONE)
      } else {
        return premiums[i].totalPaid
          .mul(-1)
          .mul(lpt.liquidity)
          .div(UNDERLYING_ONE)
      }
    })
    .reduce((acc, item) => acc.add(item), ZERO)

  const fees = position.lpts.map((lpt, i) => {
    if (lpt.isCollateral) {
      return premiums[i].feeReceived.mul(lpt.liquidity).div(UNDERLYING_ONE)
    } else {
      return ZERO
    }
  })
  const totalFee = fees.reduce((acc, item) => acc.add(item), ZERO)

  return {
    interest: lptInterest.add(tokenInterestAmount),
    fee: totalFee,
    fees
  }
}

export function computeImpliedVolatility(
  position: Position,
  prevPremiums: {
    totalReceived: BigNumber
    totalPaid: BigNumber
  }[],
  premiums: {
    totalReceived: BigNumber
    totalPaid: BigNumber
  }[]
) {
  if (premiums.length === 0) return [0]

  const totalPaid = premiums.map((p, i) =>
    p.totalPaid.sub(prevPremiums[i].totalPaid).mul(365)
  )
  const totalReceived = premiums.map((p, i) =>
    p.totalReceived.sub(prevPremiums[i].totalReceived).mul(365)
  )

  const ivLongs = totalPaid.map((totalPaid, i) => {
    const strike = getStrikePriceOfRange(
      position.lpts[i].lowerTick,
      position.lpts[i].upperTick
    )
    const sqrtPrice = Math.sqrt(toUnscaled(strike, 6))

    return totalPaid.gte(0)
      ? (2 * Math.sqrt(toUnscaled(totalPaid, 6) / sqrtPrice)) / 10
      : 0
  })

  const ivShorts = totalReceived.map((totalReceived, i) => {
    const strike = getStrikePriceOfRange(
      position.lpts[i].lowerTick,
      position.lpts[i].upperTick
    )
    const sqrtPrice = Math.sqrt(toUnscaled(strike, 6))

    return totalReceived.gte(0)
      ? (2 * Math.sqrt(toUnscaled(totalReceived, 6) / sqrtPrice)) / 10
      : 0
  })

  return position.lpts.map((lpt, i) => {
    if (lpt.isCollateral) {
      return ivShorts[i]
    } else {
      return ivLongs[i]
    }
  })
}

export function getNumOfLPTsInPosition(positions: Position[]) {
  return positions
    .map(position => position.lpts.length)
    .reduce((acc, i) => acc + i, 0)
}

export function calculatePositionValue(position: Position, price: BigNumber) {
  const sqrtPrice = priceToSqrtPrice(price)

  const amounts = getAmounts(position, sqrtPrice)

  return amounts[0].mul(price).div(UNDERLYING_ONE).add(amounts[1])
}

export function calculateDebtValue(position: Position, price: BigNumber) {
  const sqrtPrice = priceToSqrtPrice(price)

  const amounts = getDebtAmounts(position, sqrtPrice)

  return amounts[0].mul(price).div(UNDERLYING_ONE).add(amounts[1])
}

export function calculateTradeAmount(position: Position): BigNumber {
  if (position.lpts.length === 0) {
    return ZERO
  }

  return calculateTradeAmountByLiquidity(position.lpts[0])
}

export function calculateTradeAmountByLiquidity(lpt: LPT): BigNumber {
  return getAmount0ForLiquidity(
    getSqrtRatioAtTick(lpt.lowerTick),
    getSqrtRatioAtTick(lpt.upperTick),
    lpt.liquidity
  )
}

export function calculateStrikePrices(lpts: LPT[]) {
  return lpts.map(lpt => getStrikePriceByLowerTick(lpt.lowerTick))
}

export function calculateLPTValue(
  lpt: LPT,
  price: BigNumber,
  sqrtPrice: BigNumber
): BigNumber {
  const amounts = getAmountsForLiquidity(
    sqrtPrice,
    getSqrtRatioAtTick(lpt.lowerTick),
    getSqrtRatioAtTick(lpt.upperTick),
    lpt.liquidity
  )

  return amounts[0].mul(price).div(UNDERLYING_ONE).add(amounts[1])
}
