import React from 'react'
import AssetToSupply from '../components/lending/AssetToSupply'
import YourSupplies from '../components/lending/YourSupplies'
import { ensureVaultId, useOwnVault } from '../hooks/query/useOwnVault'

const LendingView = () => {
  const vaultIds = useOwnVault()
  const vaultId = ensureVaultId(vaultIds)

  if (vaultId === 0) {
    return <div>Please create vault.</div>
  }

  return (
    <div className="grid grid-cols-2 gap-4 max-w-5xl mx-auto">
      <div className="col-span-2 row-span-1">
        <YourSupplies vaultId={vaultId} />
      </div>
      <div className="col-span-2 row-span-1">
        <AssetToSupply vaultId={vaultId} />
      </div>
    </div>
  )
}

export default LendingView
