import { ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../../network'
import { useAddresses } from '../../useAddress'
import { Controller__factory } from '../../../typechain'
import { createRangeId } from '../../../utils/rangeId'
import { useCachedPrice } from '../../usePrice'
import { UNDERLYING_ONE, ZERO } from '../../../constants'
import { Position } from '../../../utils/uni'
import {
  computeImpliedVolatility,
  computeInterest
} from '../../../utils/position'
import {
  LPTInterestGrowth,
  normalizeLPTGrowthHistory,
  normalizeTokenGrowthHistory,
  ONE_DAY,
  TokenInterestGrowth
} from '../../../utils/interest'
import { toUnscaled } from '../../../utils/bn'
import {
  useLPTInterestGrowthHistory,
  useTokenInterestGrowthHistory
} from './useFeeGrowth'
import { Multicall__factory } from '../../../typechain/multicall'
import { LPRange } from '../../../utils/ranges'
import { useTokenState } from './useTokenState'
import { getStrikePriceOfRange } from '../../../utils'

const HISTORY_STALE_TIME = 5 * 60 * 1000

function now() {
  return Math.floor(new Date().getTime() / (1000 * 60))
}

export function useInterestHistory(position: Position) {
  const price = useCachedPrice()

  const latestTokenState = useTokenState()
  const latestLPTInterestGrowth = useLPTInterestGrowth(
    position.lpts.map(lpt => ({
      index: 0,
      lower: lpt.lowerTick,
      upper: lpt.upperTick
    }))
  )

  const lptInterestGrowthHistory = useLPTInterestGrowthHistory(
    position.lpts.map(lpt => lpt.lowerTick)
  )
  const tokenInterestGrowthHistory = useTokenInterestGrowthHistory()

  return useQuery(
    ['interest_history', position],

    async () => {
      if (!latestTokenState.isSuccess)
        throw new Error('latestTokenState is not loaded')
      if (!latestLPTInterestGrowth.isSuccess)
        throw new Error('latestLPTInterestGrowth is not loaded')
      if (lptInterestGrowthHistory === null)
        throw new Error('lptInterestGrowthHistory is not loaded')
      if (tokenInterestGrowthHistory === null)
        throw new Error('tokenInterestGrowthHistory is not loaded')

      const currentTimestamp = now()

      const getLatestTokenState: () => TokenInterestGrowth = () => {
        return {
          assetGrowth0: latestTokenState.data[0].assetGrowth,
          debtGrowth0: latestTokenState.data[0].debtGrowth,
          assetGrowth1: latestTokenState.data[1].assetGrowth,
          debtGrowth1: latestTokenState.data[1].debtGrowth,
          timestamp: 0
        }
      }

      const normalizedTokenInterestGrowthHistory = normalizeTokenGrowthHistory(
        tokenInterestGrowthHistory.concat([
          Object.assign(getLatestTokenState(), {
            timestamp: currentTimestamp
          })
        ])
      )

      const lptHistorySet = position.lpts.map((lpt, i) => {
        const history = lptInterestGrowthHistory.filter(
          lptInterest => lptInterest.lowerTick === lpt.lowerTick
        )

        const latest = latestLPTInterestGrowth.data[i]

        return normalizeLPTGrowthHistory(
          history.concat([
            Object.assign(latest, { timestamp: currentTimestamp })
          ])
        )
      })

      const history = normalizedTokenInterestGrowthHistory
        .map(tokenInterestGrowthHistoryItem => {
          const lptHistoryItems = lptHistorySet.map(lptHistory => {
            const lptHistoryItem = lptHistory.find(
              item =>
                item.timestamp === tokenInterestGrowthHistoryItem.timestamp
            )

            return lptHistoryItem
          })

          return {
            lpts: lptHistoryItems,
            token: tokenInterestGrowthHistoryItem,
            timestamp: tokenInterestGrowthHistoryItem.timestamp
          }
        })
        .filter(item => item !== null) as {
        lpts: LPTInterestGrowth[]
        token: TokenInterestGrowth
        timestamp: number
      }[]

      return history
        .map((historyItem, i, arr) => {
          const interest = computeInterest(
            position,
            historyItem.lpts,
            historyItem.token,
            price.price
          )

          let iv = historyItem.lpts.map(() => 0)
          if (i > 0) {
            iv = computeImpliedVolatility(
              position,
              arr[i - 1].lpts,
              historyItem.lpts
            )
          }

          return {
            fee: toUnscaled(interest.fee, 6),
            fees: interest.fees.map(d => toUnscaled(d, 6)),
            value: toUnscaled(interest.interest, 6),
            iv0: iv[0],
            iv1: iv[1],
            timestamp: historyItem.timestamp,
            elapsedDays: 0,
            daysAgo: (currentTimestamp - historyItem.timestamp) / ONE_DAY
          }
        })
        .map((item, i, arr) => {
          if (i > 0) {
            return {
              fee: item.fee - arr[i - 1].fee,
              fees: item.fees.map((fee, j) => fee - arr[i - 1].fees[j]),
              value: item.value - arr[i - 1].value,
              iv0: item.iv0,
              iv1: item.iv1,
              timestamp: item.timestamp,
              daysAgo: item.daysAgo,
              elapsedDays: history.length - item.daysAgo - 2
            }
          } else {
            return item
          }
        })
        .slice(1)
    },

    {
      enabled:
        price.price.gt(0) &&
        latestTokenState.isSuccess &&
        latestLPTInterestGrowth.isSuccess &&
        lptInterestGrowthHistory !== null &&
        tokenInterestGrowthHistory !== null,
      staleTime: HISTORY_STALE_TIME
    }
  )
}

export function useLPTInterestGrowth(ranges: LPRange[]) {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()
  const price = useCachedPrice()

  return useQuery(
    ['lpt_interest_growth', chainId, ranges],

    async () => {
      if (!account) throw new Error('account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const multicall = Multicall__factory.connect(
        addresses.Multicall2,
        provider
      )

      const controller = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const calls = ranges.map(range => {
        const lower = range.lower
        const upper = range.upper
        const rangeId = createRangeId(lower, upper)

        return {
          target: addresses.Controller,
          callData: controller.interface.encodeFunctionData('getRange', [
            rangeId
          ])
        }
      })

      const results = await multicall.callStatic.tryAggregate(false, calls)

      const rangeStatusSet = results.map((result, i) => {
        if (result.success) {
          const rangeStatus = controller.interface.decodeFunctionResult(
            'getRange',
            result.returnData
          )[0]

          return {
            lower: ranges[i].lower,
            upper: ranges[i].upper,
            premiumGrowthForBorrower: rangeStatus[3],
            premiumGrowthForLender: rangeStatus[4],
            fee0Growth: rangeStatus[5],
            fee1Growth: rangeStatus[6]
          }
        } else {
          return {
            lower: ranges[i].lower,
            upper: ranges[i].upper,
            premiumGrowthForBorrower: ZERO,
            premiumGrowthForLender: ZERO,
            fee0Growth: ZERO,
            fee1Growth: ZERO
          }
        }
      })

      return rangeStatusSet.map(rangeStatus => {
        const strike = getStrikePriceOfRange(
          rangeStatus.lower,
          rangeStatus.upper
        )

        const feeReceived = rangeStatus.fee0Growth
          .mul(strike)
          .div(UNDERLYING_ONE)
          .add(rangeStatus.fee1Growth)

        return {
          lowerTick: rangeStatus.lower,
          upperTick: rangeStatus.upper,
          premiumGrowthForBorrower: rangeStatus.premiumGrowthForBorrower,
          premiumGrowthForLender: rangeStatus.premiumGrowthForLender,
          fee0Growth: rangeStatus.fee0Growth,
          fee1Growth: rangeStatus.fee1Growth,
          feeReceived,
          totalReceived: rangeStatus.premiumGrowthForLender.add(feeReceived),
          totalPaid: rangeStatus.premiumGrowthForBorrower,
          timestamp: 0
        }
      })
    },
    {
      enabled:
        supportedChain &&
        !!account &&
        !!provider &&
        !!addresses &&
        price.price.gt(0),
      staleTime: HISTORY_STALE_TIME
    }
  )
}
