import React from 'react'
import AssetStatus from '../components/asset/AssetStatus'

const AssetView = () => {
  return (
    <>
      <div className="max-w-7xl mx-auto content-start">
        <div className="flex items-center justify-center">
          <AssetStatus />
        </div>
      </div>
    </>
  )
}

export default AssetView
