import { useMutation } from 'react-query'
import { useWeb3React } from '@web3-react/core'
import { BigNumberish, ethers } from 'ethers'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { useDeadline } from '../useBlockTimestamp'
import { MARGIN_STAY } from '../../constants'

type OpenPositionParams = {
  vaultId: number
  subVaultId: number
  isUSDC: boolean
  supplyAmount0: BigNumberish
  supplyAmount1: BigNumberish
  metadata: string
}

export function useSupply() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const deadline = useDeadline()

  return useMutation(async (params: OpenPositionParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.updatePosition(
      params.vaultId,
      [
        {
          positionUpdateType: 1,
          subVaultId: params.subVaultId,
          zeroForOne: true,
          liquidity: 0,
          lowerTick: 0,
          upperTick: 0,
          param0: params.supplyAmount0,
          param1: params.supplyAmount1
        }
      ],
      {
        isLiquidationCall: false,
        swapAnyway: true,
        quoterMode: false,
        isQuoteZero: !params.isUSDC,
        marginMode0: MARGIN_STAY,
        marginMode1: MARGIN_STAY,
        deltaMarginAmount0: 0,
        deltaMarginAmount1: 0,
        metadata: params.metadata
      },
      {
        lowerSqrtPrice: 0,
        upperSqrtPrice: ethers.constants.MaxUint256,
        swapRatio: 0,
        deadline: deadline.isSuccess ? deadline.data : 0
      }
    )
  })
}

export function useWithdraw() {
  const { account, provider } = useWeb3React<ethers.providers.Web3Provider>()
  const addresses = useAddresses()
  const deadline = useDeadline()

  return useMutation(async (params: OpenPositionParams) => {
    if (!account) throw new Error('Account not set')
    if (!provider) throw new Error('provider not set')
    if (!addresses) throw new Error('addresses not set')

    const contract = Controller__factory.connect(
      addresses.Controller,
      provider.getSigner()
    )

    return await contract.updatePosition(
      params.vaultId,
      [
        {
          positionUpdateType: 2,
          subVaultId: params.subVaultId,
          zeroForOne: true,
          liquidity: 0,
          lowerTick: 0,
          upperTick: 0,
          param0: params.supplyAmount0,
          param1: params.supplyAmount1
        }
      ],
      {
        isLiquidationCall: false,
        swapAnyway: true,
        quoterMode: false,
        isQuoteZero: !params.isUSDC,
        marginMode0: MARGIN_STAY,
        marginMode1: MARGIN_STAY,
        deltaMarginAmount0: 0,
        deltaMarginAmount1: 0,
        metadata: params.metadata
      },
      {
        lowerSqrtPrice: 0,
        upperSqrtPrice: ethers.constants.MaxUint256,
        swapRatio: 0,
        deadline: deadline.isSuccess ? deadline.data : 0
      }
    )
  })
}
