import { BigNumber } from 'ethers'
import { createRangeId } from '../utils/rangeId'

export const SUPPORTED_CHAIN_IDS = [42161, 421613]
export const DEFAULT_CHAIN = SUPPORTED_CHAIN_IDS[0]

export const NETWORK_PARAMS: { [key: number]: any } = {
  5: {
    chainId: 5,
    chainName: 'Goerli',
    nativeCurrency: {
      name: 'Goerli ETH',
      symbol: 'GoerliETH',
      decimals: 18
    },
    rpcUrls: ['https://goerli.infura.io/v3/'],
    blockExplorerUrls: ['https://goerli.etherscan.io']
  },
  42161: {
    chainId: 42161,
    chainName: 'Arbitrum',
    nativeCurrency: {
      name: 'AETH',
      symbol: 'AETH',
      decimals: 18
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io']
  },
  421613: {
    chainId: 421613,
    chainName: 'Arbitrum Goerli',
    nativeCurrency: {
      name: 'AETH',
      symbol: 'AETH',
      decimals: 18
    },
    rpcUrls: ['https://goerli-rollup.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://testnet.arbiscan.io']
  }
}

export const TOKEN_RESERVE_FACTOR = 10 // 10%
export const LPT_RESERVE_FACTOR = 5 // 5%

// slippage torelance is 5.0%
export const SLIPPAGE_TORELANCE = 500

// 12 * 240 = 2880 seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 4

export const UNDERLYING_ONE = BigNumber.from('1000000000000000000')
export const MARGIN_ONE = BigNumber.from('1000000')
export const ONE = BigNumber.from('1000000000000000000')
export const ZERO = BigNumber.from(0)

export const MARGIN_STAY = 1
export const MARGIN_USE = 2

export const MAX_NUM_OF_LPTS = 16

export const TICKS = [
  { index: 0, lower: -217490, upper: -215320 },
  { index: 1, lower: -216890, upper: -214710 },
  { index: 2, lower: -216310, upper: -214140 },
  { index: 3, lower: -215770, upper: -213600 },
  { index: 4, lower: -215260, upper: -213090 },
  { index: 5, lower: -214770, upper: -212600 },
  { index: 6, lower: -214310, upper: -212140 },
  { index: 7, lower: -213860, upper: -211690 },
  { index: 8, lower: -213440, upper: -211270 },
  { index: 9, lower: -213030, upper: -210860 },
  { index: 10, lower: -212640, upper: -210470 },
  { index: 11, lower: -211900, upper: -209720 },
  { index: 12, lower: -211210, upper: -209030 },
  { index: 13, lower: -210560, upper: -208390 },
  { index: 14, lower: -209950, upper: -207780 },
  { index: 15, lower: -209380, upper: -207210 },
  { index: 16, lower: -208840, upper: -206670 },
  { index: 17, lower: -208330, upper: -206160 },
  { index: 18, lower: -207840, upper: -205670 },
  { index: 19, lower: -207380, upper: -205200 },
  { index: 20, lower: -206930, upper: -204760 },
  { index: 21, lower: -206510, upper: -204330 },
  { index: 22, lower: -206100, upper: -203930 },
  { index: 23, lower: -205700, upper: -203530 },
  { index: 24, lower: -204960, upper: -202790 },
  { index: 25, lower: -204270, upper: -202100 },
  { index: 26, lower: -203630, upper: -201460 },
  { index: 27, lower: -203020, upper: -200850 },
  { index: 28, lower: -202450, upper: -200280 }, //1800
  { index: 29, lower: -201910, upper: -199740 }, //1900
  { index: 30, lower: -201400, upper: -199220 }, //2000
  { index: 31, lower: -200910, upper: -198740 }, //2100
  { index: 32, lower: -200440, upper: -198270 }, //2200
  { index: 33, lower: -200000, upper: -197830 }, //2300
  { index: 34, lower: -199570, upper: -197400 }, //2400
  { index: 35, lower: -199170, upper: -196990 }, //2500
  { index: 36, lower: -198770, upper: -196600 }, //2600
  { index: 37, lower: -198030, upper: -195860 }, //2800
  { index: 38, lower: -197340, upper: -195170 }, //3000
  { index: 39, lower: -196700, upper: -194520 }, //3200
  { index: 40, lower: -196090, upper: -193920 }, //3400
  { index: 41, lower: -195520, upper: -193350 }, //3600
  { index: 42, lower: -194970, upper: -192810 }, //3800
  { index: 43, lower: -194460, upper: -192300 }, //4000
  { index: 44, lower: -193970, upper: -191810 }, //4200
  { index: 45, lower: -193510, upper: -191340 }, //4400
  { index: 46, lower: -193060, upper: -190900 }, //4600
  { index: 47, lower: -192640, upper: -190470 }, //4800
  { index: 48, lower: -192230, upper: -190060 } //5000
]

export const TICKS_WITH_RANGEID = TICKS.map(tick => ({
  rangeId: createRangeId(tick.lower, tick.upper),
  index: tick.index,
  lower: tick.lower,
  upper: tick.upper
}))

export const STALE_TIME = 5000
export const REFETCH_INTERVAL = 15000

// PositionUpdateType
export const ACTION_DEPOSIT_TOKEN = 1
export const ACTION_WITHDRAW_TOKEN = 2
export const ACTION_BORROW_TOKEN = 3
export const ACTION_REPAY_TOKEN = 4
