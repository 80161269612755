import { BigNumber } from 'ethers'
import { TICKS } from '../constants'
import { TradeType } from '../constants/enum'
import { toUnscaled } from './bn'
import { tickToPrice } from './uni'

export function tradeTypeToString(tradeType: TradeType) {
  if (tradeType === TradeType.LONG_CALL) return 'Long Call'
  else if (tradeType === TradeType.LONG_PUT) return 'Long Put'
  else if (tradeType === TradeType.SHORT_CALL) return 'Short Call'
  else if (tradeType === TradeType.SHORT_PUT) return 'Short Put'
  else if (tradeType === TradeType.LONG_STRANGLE) return 'Long Strangle'
  else if (tradeType === TradeType.SHORT_STRANGLE) return 'Short Strangle'
  else if (tradeType === TradeType.BULL_CALL_SPREAD) return 'Bull Call Spread'
  else if (tradeType === TradeType.BEAR_CALL_SPREAD) return 'Bear Call Spread'
  else if (tradeType === TradeType.LENDING) return 'Lending'
  else if (tradeType === TradeType.HEDGE) return 'Hedge'
  return 'Undefined'
}

export function calculateGasMargin(estimatedGas: BigNumber) {
  return estimatedGas.mul(140).div(100)
}

export function calculateBufferMargin(buffer: BigNumber) {
  return buffer.mul(150).div(100)
}

export function getStrikePriceOfRange(lower: number, upper: number) {
  const tick = (lower + upper) / 2
  return tickToPrice(tick)
}

export function getStrikePrice(rangeIndex: number) {
  return toUnscaled(
    getStrikePriceOfRange(TICKS[rangeIndex].lower, TICKS[rangeIndex].upper),
    6,
    2
  )
}

export function getStrikePriceByLowerTick(lowerTick: number) {
  for (let i = 0; i < TICKS.length; i++) {
    if (TICKS[i].lower === lowerTick) {
      return getStrikePrice(i)
    }
  }

  return 0
}

// slippage tolerance is 0.2%
export function computeLowerSqrtPrice(sqrtPrice: BigNumber) {
  // 0.2%
  // sqrtPrice * sqrt(1000/1002)
  return sqrtPrice.mul(999001).div(1000000)
}

export function computeUpperSqrtPrice(sqrtPrice: BigNumber) {
  // 0.2%
  // sqrtPrice * sqrt(1002/1000)
  return sqrtPrice.mul(1001000).div(1000000)
}

export function roundContractSize(tradeAmount: BigNumber) {
  return Math.round(toUnscaled(tradeAmount.mul(1000), 18, 3)) / 1000
}

export function roundToMinutes(currentTimestamp: number) {
  return Math.floor(currentTimestamp / 60)
}
