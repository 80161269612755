import React from 'react'
import { BigNumber } from 'ethers'
import { TradeType } from '../../constants/enum'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useSupply } from '../../hooks/contracts/useSupply'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { useOwnVault } from '../../hooks/query/useOwnVault'
import { useAddressesAnyway } from '../../hooks/useAddress'
import { toUnscaled } from '../../utils/bn'
import { decodeMetadata, encodeMetadata } from '../../utils/metadata'
import HorizontalRule from './HorizontalRule'
import { UNDERLYING_ONE } from '../../constants'
import { useSubVaults } from '../../hooks/query/useSubVaults'

const AssetToSupply = ({ vaultId }: { vaultId: number }) => {
  const vaultIds = useOwnVault()
  const subVaults = useSubVaults(vaultId) || []

  const addresses = useAddressesAnyway()
  const usdcBalance = useERC20BalanceQuery(addresses.QuoteToken)
  const wethBalance = useERC20BalanceQuery(addresses.UnderlyingToken)
  const supply = useSupply()
  const approve = useApproveMutation()

  const lendingSubVaultId = getLendingSubVaultId()

  function getLendingSubVaultId() {
    if (!vaultIds.isAvailable || vaultIds.data.length == 0) {
      return -1
    }
    const lendingSubVault = subVaults.filter(
      subVault => decodeMetadata(subVault.metadata) === TradeType.LENDING
    )

    if (lendingSubVault.length == 0) {
      return -1
    }

    return lendingSubVault[0].subVaultId
  }

  const onApprove = async (isUSDC: boolean) => {
    if (isUSDC) {
      await approve.mutateAsync({
        spender: addresses.Controller,
        address: addresses.QuoteToken,
        amount: BigNumber.from('1000000000').mul(20)
      })
    } else {
      await approve.mutateAsync({
        spender: addresses.Controller,
        address: addresses.UnderlyingToken,
        amount: UNDERLYING_ONE.mul(10)
      })
    }
  }

  const onSupply = async (isUSDC: boolean) => {
    if (!vaultIds.isAvailable) {
      return
    }

    let subVaultId = 0

    if (lendingSubVaultId >= 0) {
      subVaultId = lendingSubVaultId
    }

    if (isUSDC) {
      await supply.mutateAsync({
        vaultId,
        subVaultId,
        supplyAmount0: 0,
        supplyAmount1: BigNumber.from('1000000000').mul(20),
        metadata: encodeMetadata(TradeType.LENDING),
        isUSDC
      })
    } else {
      await supply.mutateAsync({
        vaultId,
        subVaultId,
        supplyAmount0: UNDERLYING_ONE.mul(10),
        supplyAmount1: 0,
        metadata: encodeMetadata(TradeType.LENDING),
        isUSDC
      })
    }

    // Set pending
    // await setPendingTx(tx)
  }

  return (
    <div className="rounded-lg bg-secondaly leading-5 p-1">
      <div className="flex flex-wrap justify-between items-center px-4 py-4 space-x-0">
        <div className="w-32">Asset to supply</div>
      </div>

      <HorizontalRule />

      <div className="p-6">
        <table className="border-collapse table-fixed text-left">
          <thead className="text-[12px] text-subtext font-medium">
            <tr>
              <th className="w-40 pb-2">Asset</th>
              <th className="w-40">Wallet Balance</th>
              <th className="w-40"></th>
            </tr>
          </thead>
          <tbody className="text-sm font-normal items-center align-center">
            <tr>
              <td>ETH</td>
              <td>{toUnscaled(wethBalance, 18, 6)} ETH</td>
              <td>
                <button
                  onClick={() => {
                    onApprove(false)
                  }}
                >
                  Approve
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    onSupply(false)
                  }}
                >
                  Supply
                </button>
              </td>
            </tr>
            <tr>
              <td>USDC</td>
              <td>{toUnscaled(usdcBalance, 6, 2)} USDC</td>
              <td>
                <button
                  onClick={() => {
                    onApprove(true)
                  }}
                >
                  Approve
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    onSupply(true)
                  }}
                >
                  Supply
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default AssetToSupply
