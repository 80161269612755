import { gql } from '@apollo/client'

export const VAULT_HISTORY_ITEM_QUERY = gql`
  query ($vaultId: String, $first: Int, $skip: Int) {
    vaultHistoryItems(
      first: $first
      skip: $skip
      where: { vault: $vaultId }
      orderBy: openAt
      orderDirection: desc
    ) {
      id
      action
      metadata
      size
      subVault {
        lpts {
          isCollateral
          rangeId
          liquidity
          liquidityHistory {
            liquidity
            createdAt
          }
        }
        feeAmount0
        feeAmount1
        totalAmount0
        totalAmount1
      }
      openAt
      closeAt
      openTxHash
      closeTxHash
    }
  }
`

export type VaultHistoryItems = {
  vaultHistoryItems: {
    id: string
    action: string
    metadata: string | null
    size: string
    subVault: {
      lpts: {
        isCollateral: boolean
        rangeId: string
        liquidity: string
        liquidityHistory: {
          liquidity: string
          createdAt: string
        }[]
      }[]
      feeAmount0: string
      feeAmount1: string
      totalAmount0: string
      totalAmount1: string
    }
    openAt: string
    closeAt: string | null
    openTxHash: string
    closeTxHash: string | null
  }[]
}
