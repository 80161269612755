import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../../network'
import { useEffect, useState } from 'react'
import { useAddresses } from '../../useAddress'
import { Controller__factory } from '../../../typechain'
import { STALE_TIME } from '../../../constants'

type Context = {
  isMarginZero: boolean
  nextSubVaultId: number
  uniswapPool: string
  accumulatedProtocolFee0: BigNumber
  accumulatedProtocolFee1: BigNumber
}

export function usePredyContext() {
  const { provider } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  const [context, setContext] = useState<Context>({
    isMarginZero: false,
    nextSubVaultId: 0,
    uniswapPool: '',
    accumulatedProtocolFee0: BigNumber.from(0),
    accumulatedProtocolFee1: BigNumber.from(0)
  })

  const contextQuery = useQuery<Context>(
    ['context', chainId],

    async () => {
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = Controller__factory.connect(
        addresses.Controller,
        provider
      )
      const context = await contract.getContext()

      return {
        isMarginZero: context[0],
        nextSubVaultId: context[1].toNumber(),
        uniswapPool: context[2],
        accumulatedProtocolFee0: context[3],
        accumulatedProtocolFee1: context[4]
      }
    },

    {
      enabled: supportedChain && !!provider && !!addresses,
      refetchOnWindowFocus: false,
      staleTime: STALE_TIME
    }
  )

  useEffect(() => {
    if (contextQuery.isSuccess) {
      setContext(contextQuery.data)
    }
  }, [contextQuery.isSuccess, contextQuery.data])

  return context
}
