import React, { useState } from 'react'
import { toUnscaled } from '../../../utils/bn'
import pendingStore from '../../../store/pending'
import { TradeType } from '../../../constants/enum'
import { useVaultStatus } from '../../../hooks/query/useVaultStatus'
import { useCachedPrice } from '../../../hooks/usePrice'
import { useCloseSubVault } from '../../../hooks/contracts/useCloseSubVault'
import {
  tradeTypeToString,
  computeLowerSqrtPrice,
  computeUpperSqrtPrice,
  roundContractSize
} from '../../../utils'
import {
  CloseQuoterResult,
  useClosePositionQuoter
} from '../../../hooks/query/useQuoter'
import { BigNumber } from 'ethers'
import { toTimeString } from '../../../utils/string'
import { toAmountString, toPriceString } from '../../../utils/number'
import { usePositionInterestEst } from '../../../hooks/query/interest/useInterestEst'
import { SubVaultItem } from '../../../hooks/query/useSubVaults'
import { Position } from '../../../utils/uni'
import PendingIndicator from '../../common/PendingIndicator'
import { reasonToErrorMessage } from '../../../utils/error'

interface Props {
  vaultId: number
  subVaultIndex: number
  isPending: boolean
  size: BigNumber
  strikes: number[]
  tradeType: TradeType
  pnlValue: BigNumber
  feeValue: BigNumber
  position: Position
  positionValue: BigNumber
  tickIndexes: number[]
  closeQuote: CloseQuoterResult
  timestamp: number
  error: null | string
  onClose: (swapRatio: number) => void
}

const PositionItem = (props: Props) => {
  const interestEst = usePositionInterestEst(props.position)

  const positionValue =
    props.closeQuote.error === null
      ? props.closeQuote.data.positionAmounts[1]
      : props.positionValue

  return (
    <tr className="h-12">
      <td className="py-2">{tradeTypeToString(props.tradeType)}</td>
      <td>{toTimeString(props.timestamp)}</td>
      <td>
        {props.strikes
          .sort()
          .map(strikePrice => strikePrice.toString())
          .join(',')}
      </td>
      <td>{toAmountString(roundContractSize(props.size))}</td>
      <td>
        {interestEst.isSuccess ? toPriceString(interestEst.data) : 0} USDC
      </td>
      <td>
        {props.feeValue.eq(0) ? 0 : toUnscaled(props.feeValue, 6, 2)} USDC
      </td>
      <td>{toUnscaled(positionValue.add(props.pnlValue), 6, 2)} USDC</td>
      <td>
        <button
          className="w-24 h-10 p-1 rounded-lg border-[1px] border-deepgreen text-xs text-deepgreen font-semibold leading-5"
          onClick={() => props.onClose(100)}
        >
          {props.isPending ? <PendingIndicator /> : 'Close'}
        </button>
        {props.error ? (
          <span className="mt-2 text-xs text-red">
            {reasonToErrorMessage(props.error)}
          </span>
        ) : (
          <div />
        )}
      </td>
    </tr>
  )
}

interface OpenPositionsProps {
  vaultId: number
  subVaults: SubVaultItem[] | null
}

const OpenPositions = (props: OpenPositionsProps) => {
  const [priceCheckError, setPriceCheckError] = useState({
    error: null,
    subVaultId: 0
  })
  const { pendingCloseSubVaultId, setPendingCloseTx } = pendingStore()

  const price = useCachedPrice()

  const closePosition = useCloseSubVault()

  const vaultStatus = useVaultStatus(props.vaultId)

  const closeQuote = useClosePositionQuoter(
    props.vaultId,
    props.subVaults
      ? props.subVaults.map(subVault => subVault.subVaultIndex)
      : []
  )

  const onClose = async (
    vaultId: number,
    subVaultId: number,
    swapRatio: number
  ) => {
    try {
      const tx = await closePosition.mutateAsync({
        vaultId,
        subVaultId,
        lowerSqrtPrice: computeLowerSqrtPrice(price.sqrtPrice),
        upperSqrtPrice: computeUpperSqrtPrice(price.sqrtPrice),
        swapRatio
      })

      await setPendingCloseTx(subVaultId, tx)
    } catch (e: any) {
      setPriceCheckError({
        subVaultId,
        error: e.reason || e.data.message
      })
      setTimeout(() => {
        setPriceCheckError({
          error: null,
          subVaultId
        })
      }, 5000)
    }
  }

  return (
    <div className="px-8 py-6">
      <table className="border-collapse table-fixed text-left">
        <thead className="text-sm text-subtext font-normal">
          <tr>
            <th className="w-40 pb-2 font-normal">Contract/Action</th>
            <th className="w-40 font-normal">Opening Date</th>
            <th className="w-40 font-normal">Strike</th>
            <th className="w-40 font-normal">Size</th>
            <th className="w-40 font-normal">24h interest est.</th>
            <th className="w-40 font-normal">Interest Paid/Earned</th>
            <th className="w-40 font-normal">Payoff est.</th>
          </tr>
        </thead>
        <tbody className="text-xs text-subtext font-normal items-center align-center">
          {vaultStatus.isSuccess && props.subVaults !== null ? (
            props.subVaults.length > 0 ? (
              props.subVaults
                .filter(vaultData => vaultData.tradeType !== TradeType.NONE)
                .map((subVaultItem, i) => {
                  const latestPremiumValue = vaultStatus.data.subVaults[
                    subVaultItem.subVaultIndex
                  ]
                    ? vaultStatus.data.subVaults[subVaultItem.subVaultIndex]
                        .values.premiumValue
                    : BigNumber.from(0)

                  return (
                    <PositionItem
                      key={i}
                      vaultId={subVaultItem.vaultId}
                      subVaultIndex={subVaultItem.subVaultIndex}
                      strikes={subVaultItem.strikes}
                      size={subVaultItem.size}
                      pnlValue={subVaultItem.pnlValue}
                      feeValue={subVaultItem.feeValue.add(latestPremiumValue)}
                      position={subVaultItem.position}
                      positionValue={subVaultItem.positionValue}
                      tickIndexes={subVaultItem.tickIndexes}
                      tradeType={subVaultItem.tradeType}
                      timestamp={subVaultItem.timestamp}
                      closeQuote={
                        closeQuote.find(
                          quote => quote.subVaultId === subVaultItem.subVaultId
                        ) || { error: 'no data', data: null, subVaultId: -1 }
                      }
                      isPending={
                        pendingCloseSubVaultId === subVaultItem.subVaultId ||
                        subVaultItem.isClosing
                      }
                      error={
                        priceCheckError.error &&
                        subVaultItem.subVaultId === priceCheckError.subVaultId
                          ? priceCheckError.error
                          : null
                      }
                      onClose={(swapRatio: number) => {
                        onClose(
                          subVaultItem.vaultId,
                          subVaultItem.subVaultId,
                          swapRatio
                        )
                      }}
                    />
                  )
                })
            ) : (
              <tr>
                <td>Please trade first.</td>
              </tr>
            )
          ) : (
            <tr>
              <td>Loading.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default OpenPositions
