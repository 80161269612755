import { gql } from '@apollo/client'

export const VAULTS_QUERY = gql`
  query ($owner: String) {
    vaultEntities(where: { owner: $owner }, orderBy: vaultId) {
      id
      vaultId
      owner
      originalOwner
    }
  }
`

export type VaultEntities = {
  vaultEntities: {
    id: string
    vaultId: string
    owner: string
    originalOwner: string
  }[]
}
