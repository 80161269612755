import React, { useState } from 'react'
import { toScaled, toUnscaled } from '../../utils/bn'
import tradeStore from '../../store/trade'
import pendingStore from '../../store/pending'
import pvaultSVG from '../../assets/pvault.svg'
import { AmountForm } from '../options/trade/AmountForm'
import { toPriceString } from '../../utils/number'
import { usePrice } from '../../hooks/usePrice'
import { computeLowerSqrtPrice, computeUpperSqrtPrice } from '../../utils'
import { createPositionFromTradeData } from '../../utils/position'
import { decodeMetadata } from '../../utils/metadata'
import { ensureVaultId, useOwnVault } from '../../hooks/query/useOwnVault'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'
import { BigNumber } from 'ethers'
import { TradeType } from '../../constants/enum'
import { useMarginUtilizing } from '../../hooks/query/useMarginUtilizing'
import { usePredyContext } from '../../hooks/query/protocol/usePredyContext'
import { PrimaryButton } from '../common/Button'
import { useTrade } from '../../hooks/contracts/useTrade'
import { useSubVaults } from '../../hooks/query/useSubVaults'
import { reasonToErrorMessage } from '../../utils/error'
import { ZERO } from '../../constants'

const TradeForm = () => {
  const [priceCheckError, setPriceCheckError] = useState<string | null>(null)
  const { strategyType, rangeIds, optionAmount, setOptionAmount } = tradeStore()
  const { setPendingTx } = pendingStore()

  const vaultIds = useOwnVault()
  const vaultId = ensureVaultId(vaultIds)
  const subVaults = useSubVaults(vaultId) || []

  const tradeData = {
    subVaultIndex: 0,
    strategyType,
    rangeIds,
    optionAmount
  }

  const price = usePrice()
  const context = usePredyContext()
  const position = createPositionFromTradeData(
    tradeData,
    price.isSuccess ? price.data.price : BigNumber.from(0),
    context.isMarginZero
  )
  const vaultStatus = useVaultStatus(vaultId, false)

  const marginUtilizing = useMarginUtilizing(vaultId, position)

  const tradeMutation = useTrade()

  function getHedgeSubVaultId() {
    if (!vaultIds.isAvailable || vaultIds.data.length == 0) {
      return -1
    }
    const hedgeSubVault = subVaults.filter(
      subVault => decodeMetadata(subVault.metadata) === TradeType.HEDGE
    )

    if (hedgeSubVault.length == 0) {
      return 0
    }

    console.log(toUnscaled(hedgeSubVault[0].swapRatio, 6))

    return hedgeSubVault[0].subVaultId
  }

  const onLong = async () => {
    await trade(true)
  }
  const onShort = async () => {
    await trade(false)
  }
  const trade = async (isLong: boolean) => {
    if (
      !vaultIds.isAvailable ||
      optionAmount <= 0 ||
      !price.isSuccess ||
      !vaultStatus.isSuccess
    ) {
      console.warn('Application has not been initialized for trade')
      return
    }

    const subVaultId = getHedgeSubVaultId()

    const sqrtPrice = price.data.sqrtPrice

    const targetSubVault = vaultStatus.data.subVaults.filter(
      subVault => subVaultId === subVault.id.toNumber()
    )

    try {
      const tx = await tradeMutation.mutateAsync({
        vaultId,
        subVaultId,
        isLong,
        amount: toScaled(optionAmount, 18),
        subVaultPosition:
          targetSubVault.length > 0
            ? targetSubVault[0].amount
            : {
                collateralAmount0: ZERO,
                collateralAmount1: ZERO,
                debtAmount0: ZERO,
                debtAmount1: ZERO
              },
        lowerSqrtPrice: computeLowerSqrtPrice(sqrtPrice),
        upperSqrtPrice: computeUpperSqrtPrice(sqrtPrice)
      })

      // Set pending
      await setPendingTx(tx)
    } catch (e: any) {
      setPriceCheckError(e.reason || e.data.message)
      setTimeout(() => {
        setPriceCheckError(null)
      }, 5000)
    }
  }

  return (
    <div className="rounded-lg bg-black w-full py-4 pr-8 pl-2">
      <div className="pt-2">
        <img src={pvaultSVG} className="w-[72px] h-[24px]"></img>
      </div>
      <div className="pt-4">
        $
        {toPriceString(
          price.isSuccess ? toUnscaled(price.data.price, 6, 2) : 0
        )}
      </div>
      <div className="pt-4">
        <div className="grid grid-cols-2 grid-rows-2 text-sm justify-items-end">
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Positions</div>
            <div className="text-right pr-2">
              $
              {toPriceString(
                vaultStatus.isSuccess
                  ? toUnscaled(vaultStatus.data.positionValue, 6, 2)
                  : 0
              )}
            </div>
          </div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Margin</div>
            <div className="text-right pr-2">
              $
              {toPriceString(
                vaultStatus.isSuccess
                  ? toUnscaled(vaultStatus.data.marginValue, 6, 2)
                  : 0
              )}
            </div>
          </div>
          <div className="space-x-2"></div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Total</div>
            <div className="text-right pr-2">
              $
              {toPriceString(
                vaultStatus.isSuccess
                  ? toUnscaled(
                      vaultStatus.data.positionValue.add(
                        vaultStatus.data.marginValue
                      ),
                      6,
                      2
                    )
                  : 0
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="pt-4">
        <AmountForm
          title={'Contract Size'}
          amount={optionAmount}
          onChange={amount => {
            setOptionAmount(amount)
          }}
        />
      </div>

      {/* Margin Utilizing */}
      <div className="pt-4">
        <h3>Margin Utilizing</h3>
        <div className="grid grid-cols-2 grid-rows-2 text-sm justify-items-end">
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">Before</div>
            <div className="text-right pr-2">
              $
              {vaultStatus.isSuccess
                ? toPriceString(toUnscaled(vaultStatus.data.minCollateral, 6))
                : 0}
            </div>
          </div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">After</div>
            <div className="text-right pr-2">
              ${toPriceString(marginUtilizing.after)}
            </div>
          </div>
          <div className="space-x-2"></div>
          <div className="space-x-2">
            <div className="text-[8px] text-subtext text-right">
              Margin Utilizing
            </div>
            <div className="text-green text-right pr-2">
              ${toPriceString(marginUtilizing.marginUtilizing)}
            </div>
          </div>
        </div>
      </div>

      {/* Interest est. */}
      <div className="py-4">
        <h3>Delta</h3>
        {vaultStatus.isSuccess
          ? toUnscaled(
              vaultStatus.data.subVaults.reduce((acc, item) => {
                return acc.add(
                  item.amount.collateralAmount0.sub(item.amount.debtAmount0)
                )
              }, BigNumber.from(0)),
              18
            )
          : 0}
      </div>

      <div className="flex justify-between">
        <PrimaryButton disabled={false} onClick={onShort}>
          Short
        </PrimaryButton>
        <PrimaryButton disabled={false} onClick={onLong}>
          Long
        </PrimaryButton>
      </div>

      {priceCheckError ? (
        <div className="mt-2 text-xs text-red">
          {reasonToErrorMessage(priceCheckError)}
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default TradeForm
