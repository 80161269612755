import React from 'react'
import { useRoute } from 'wouter'
import VaultStatus from '../components/vault/VaultStatus'

const VaultView = () => {
  const params = useRoute('/vault/:vaultId')[1]
  const vaultId = Number(params?.vaultId || 0)

  return (
    <>
      <div className="max-w-7xl mx-auto content-start">
        <div className="flex items-center justify-center">
          <VaultStatus vaultId={vaultId} />
        </div>
      </div>
    </>
  )
}

export default VaultView
