import React from 'react'
import cardLongCall from '../../assets/cards/LongCall.svg'
import cardLongPut from '../../assets/cards/LongPut.svg'
import cardShortCall from '../../assets/cards/ShortCall.svg'
import cardShortPut from '../../assets/cards/ShortPut.svg'
import cardLongStrangle from '../../assets/cards/LongStrangle.svg'
import cardShortStrangle from '../../assets/cards/ShortStrangle.svg'
import cardCallSpread from '../../assets/cards/BullCallSpread.svg'
import cardPutSpread from '../../assets/cards/BearCallSpread.svg'
import { TradeType } from '../../constants/enum'
import tradeStore from '../../store/trade'
import { getIndexOfCenter } from '../../utils/ranges'
import { useCachedPrice } from '../../hooks/usePrice'

type Props = {
  titleFormer: string
  titleLatter: string
  isLong: boolean
  chart: string
  selected?: boolean
  onClick: () => void
}

const Card = ({
  titleFormer,
  titleLatter,
  isLong,
  chart,
  selected,
  onClick
}: Props) => {
  return (
    <div
      onClick={onClick}
      className={`rounded-lg h-[104px] ${
        selected ? 'final-gradient-outline' : 'bg-secondaly'
      } cursor-pointer`}
    >
      <div className="pt-1 flex justify-center">
        <div>
          <span
            className={`text-sm font-normal ${
              isLong ? 'text-green' : 'text-red'
            }`}
          >
            {titleFormer}
          </span>
          &nbsp;
          <span className="text-sm font-normal">{titleLatter}</span>
        </div>
      </div>
      <div className="flex justify-center mx-2 my-2 h-[62px]">
        <img src={chart} alt={'ETH'} width={100} height={62}></img>
      </div>
    </div>
  )
}

const CardList = () => {
  const { strategyType, setStrategyType, setRangeIds } = tradeStore()

  const price = useCachedPrice()
  const tickIndex = getIndexOfCenter(price.indexPrice)

  return (
    <div className="grid grid-cols-2 grid-rows-4 gap-2 pl-4 py-2">
      <div className="col-span-1">
        <Card
          titleFormer="Long"
          titleLatter="Call"
          isLong={true}
          chart={cardLongCall}
          selected={strategyType === TradeType.LONG_CALL}
          onClick={() => {
            setStrategyType(TradeType.LONG_CALL)
            setRangeIds([tickIndex])
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          titleFormer="Short"
          titleLatter="Call"
          isLong={false}
          chart={cardShortCall}
          selected={strategyType === TradeType.SHORT_CALL}
          onClick={() => {
            setStrategyType(TradeType.SHORT_CALL)
            setRangeIds([tickIndex])
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          titleFormer="Long"
          titleLatter="Put"
          isLong={true}
          chart={cardLongPut}
          selected={strategyType === TradeType.LONG_PUT}
          onClick={() => {
            setStrategyType(TradeType.LONG_PUT)
            setRangeIds([tickIndex + 1])
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          titleFormer="Short"
          titleLatter="Put"
          isLong={false}
          chart={cardShortPut}
          selected={strategyType === TradeType.SHORT_PUT}
          onClick={() => {
            setStrategyType(TradeType.SHORT_PUT)
            setRangeIds([tickIndex + 1])
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          titleFormer="Long"
          titleLatter="Strangle"
          isLong={true}
          chart={cardLongStrangle}
          selected={strategyType === TradeType.LONG_STRANGLE}
          onClick={() => {
            setStrategyType(TradeType.LONG_STRANGLE)
            setRangeIds([tickIndex, tickIndex + 1])
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          titleFormer="Short"
          titleLatter="Strangle"
          isLong={false}
          chart={cardShortStrangle}
          selected={strategyType === TradeType.SHORT_STRANGLE}
          onClick={() => {
            setStrategyType(TradeType.SHORT_STRANGLE)
            setRangeIds([tickIndex, tickIndex + 1])
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          titleFormer="Bull"
          titleLatter="Call Spread"
          isLong={true}
          chart={cardCallSpread}
          selected={strategyType === TradeType.BULL_CALL_SPREAD}
          onClick={() => {
            setStrategyType(TradeType.BULL_CALL_SPREAD)
            setRangeIds([tickIndex, tickIndex + 1])
          }}
        />
      </div>
      <div className="col-span-1">
        <Card
          titleFormer="Bear"
          titleLatter="Call Spread"
          isLong={false}
          chart={cardPutSpread}
          selected={strategyType === TradeType.BEAR_CALL_SPREAD}
          onClick={() => {
            setStrategyType(TradeType.BEAR_CALL_SPREAD)
            setRangeIds([tickIndex, tickIndex + 1])
          }}
        />
      </div>
    </div>
  )
}

export default CardList
