import React, { useState } from 'react'
import { BigNumber } from 'ethers'
import { useApproveMutation } from '../../hooks/contracts/useApprove'
import { useUpdateMargin } from '../../hooks/contracts/useUpdateMargin'
import { useERC20BalanceQuery } from '../../hooks/query/balance'
import { ensureVaultId, useOwnVault } from '../../hooks/query/useOwnVault'
import { useAddressesAnyway } from '../../hooks/useAddress'
import { toScaled, toUnscaled } from '../../utils/bn'
import { PrimaryButton } from '../common/Button'
import { AmountForm } from '../options/trade/AmountForm'
import pendingStore from '../../store/pending'
import { useAllowanceQuery } from '../../hooks/query/allowance'
import PendingIndicator from '../common/PendingIndicator'
import crossIcon from '../../assets/close.png'
import { useVaultStatus } from '../../hooks/query/useVaultStatus'

type Props = {
  onClose: () => void
}

export const DepositModal = ({ onClose }: Props) => {
  const addresses = useAddressesAnyway()
  const balanceQuery = useERC20BalanceQuery(addresses.QuoteToken)
  const allowanceQuery = useAllowanceQuery(
    addresses.Controller,
    addresses.QuoteToken
  )

  const updateMargin = useUpdateMargin()
  const approve = useApproveMutation()
  const { isPendingApproval, isPendingTx, setPendingApproval, setPendingTx } =
    pendingStore()

  const vaultIds = useOwnVault()
  const vaultId = ensureVaultId(vaultIds)
  const vaultStatusQuery = useVaultStatus(vaultId)

  const [amount, setAmount] = useState(0)

  const onApprove = async (amount: BigNumber) => {
    const tx = await approve.mutateAsync({
      amount,
      address: addresses.QuoteToken,
      spender: addresses.Controller
    })

    await setPendingApproval(tx)
  }

  const onDepositMargin = async (amount: BigNumber) => {
    if (vaultId < 0) {
      return
    }

    const tx = await updateMargin.mutateAsync({
      vaultId,
      deltaMarginAmount: amount
    })

    await setPendingTx(tx)

    // clear amount

    setAmount(0)
  }

  const enoughUSDCApproved = allowanceQuery.gte(toScaled(amount, 6))

  return (
    <div className="space-y-2">
      <div className="flex items-center justify-end">
        <div
          className="rounded-lg bg-white text-[#809fb8] w-6 h-6 flex items-center justify-center"
          onClick={onClose}
        >
          <img src={crossIcon} />
        </div>
      </div>
      <div className="flex items-center justify-start text-xl">Deposit</div>
      <div className="px-6">
        <div className="flex items-center justify-center text-sm text-subtext font-normal">
          Predy is a non-custodial protocol; only the wallet can access the
          vault account. You are responsible for your wallet security and can
          withdraw the available cryptocurrency anytime.
        </div>
        <div className="flex items-center justify-center pt-8">
          <AmountForm
            title={'Amount'}
            unit={'USDC'}
            amount={amount}
            onChange={amount => {
              setAmount(amount)
            }}
          />
        </div>
        <div className="flex items-center justify-between text-subtext px-4 pt-4">
          <div>In Wallet</div>
          <div>{toUnscaled(balanceQuery, 6, 2)} USDC</div>
        </div>
        <div className="flex items-center justify-between text-subtext px-4 pt-4">
          {vaultId === 0 ? (
            <>
              <div>In Vault</div>
              <div>no vault</div>
            </>
          ) : (
            <>
              <div>In Vault</div>
              <div>
                {vaultStatusQuery.isSuccess
                  ? toUnscaled(vaultStatusQuery.data.marginValue, 6, 2)
                  : '-'}{' '}
                USDC
              </div>
            </>
          )}
        </div>
        <div className="flex items-center justify-between pt-8">
          <div className="w-36 p-1">
            <PrimaryButton
              disabled={isPendingApproval || enoughUSDCApproved}
              onClick={() => {
                onApprove(toScaled(amount, 6))
              }}
            >
              {isPendingApproval ? <PendingIndicator /> : 'Approve'}
            </PrimaryButton>
          </div>
          <div className="w-36 p-1">
            <PrimaryButton
              disabled={!enoughUSDCApproved || isPendingTx || amount === 0}
              onClick={() => {
                onDepositMargin(toScaled(amount, 6))
              }}
            >
              {isPendingTx ? <PendingIndicator /> : 'Deposit'}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  )
}
