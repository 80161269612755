import { BigNumber, ethers } from 'ethers'
import { useWeb3React } from '@web3-react/core'
import { useQuery } from 'react-query'
import { useChainId, useIsSupportedChain } from '../network'
import { useAddresses } from '../useAddress'
import { Controller__factory } from '../../typechain'
import { useEffect, useState } from 'react'

export type IRMParams = {
  baseRate: BigNumber
  kinkRate: BigNumber
  slope1: BigNumber
  slope2: BigNumber
}

export function useIRMParams() {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  const [irmParams, setIRMParams] = useState<IRMParams>({
    baseRate: BigNumber.from('5000000000000000'),
    kinkRate: BigNumber.from('800000000000000000'),
    slope1: BigNumber.from('48000000000000000'),
    slope2: BigNumber.from('1200000000000000000')
  })

  const irmParamsQuery = useQuery<IRMParams>(
    ['irm', chainId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const irmParams = await contract.irmParams()

      return {
        baseRate: irmParams[0],
        kinkRate: irmParams[1],
        slope1: irmParams[2],
        slope2: irmParams[3]
      }
    },

    {
      enabled: !!account && supportedChain && !!provider && !!addresses,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (irmParamsQuery.isSuccess) {
      setIRMParams(irmParamsQuery.data)
    }
  }, [irmParamsQuery.isSuccess, irmParamsQuery.data])

  return irmParams
}

export function useLPTIRMParams() {
  const { provider, account } = useWeb3React<ethers.providers.Web3Provider>()
  const supportedChain = useIsSupportedChain()
  const chainId = useChainId()
  const addresses = useAddresses()

  const [lptIRMParams, setIRMParams] = useState<IRMParams>({
    baseRate: BigNumber.from('10000000000000000'),
    kinkRate: BigNumber.from('450000000000000000'),
    slope1: BigNumber.from('60000000000000000'),
    slope2: BigNumber.from('750000000000000000')
  })

  const irmParamsQuery = useQuery<IRMParams>(
    ['lpt_irm', chainId],

    async () => {
      if (!account) throw new Error('Account not set')
      if (!provider) throw new Error('provider not set')
      if (!addresses) throw new Error('addresses not set')

      const contract = Controller__factory.connect(
        addresses.Controller,
        provider
      )

      const lptInterestParams = await contract.ypParams()

      return {
        baseRate: lptInterestParams[1][0],
        kinkRate: lptInterestParams[1][1],
        slope1: lptInterestParams[1][2],
        slope2: lptInterestParams[1][3]
      }
    },

    {
      enabled: !!account && supportedChain && !!provider && !!addresses,
      staleTime: Infinity,
      refetchOnWindowFocus: false
    }
  )

  useEffect(() => {
    if (irmParamsQuery.isSuccess) {
      setIRMParams(irmParamsQuery.data)
    }
  }, [irmParamsQuery.isSuccess, irmParamsQuery.data])

  return lptIRMParams
}
