import { TickMath, maxLiquidityForAmounts } from '@uniswap/v3-sdk'
import { BigNumber } from 'ethers'
import JSBI from 'jsbi'
import { UNDERLYING_ONE } from '../constants'
import { sqrt } from './bn'
import { EmptyPosition } from './position'

export const Q96 = BigNumber.from(2).pow(96)
export const Q128 = BigNumber.from(2).pow(128)

export type LPT = {
  isCollateral: boolean
  liquidity: BigNumber
  lowerTick: number
  upperTick: number
}

export type Position = {
  subVaultId: number
  asset0: BigNumber
  asset1: BigNumber
  debt0: BigNumber
  debt1: BigNumber
  lpts: LPT[]
}

enum PositionUpdateType {
  DEPOSIT_TOKEN,
  WITHDRAW_TOKEN,
  BORROW_TOKEN,
  REPAY_TOKEN,
  DEPOSIT_LPT,
  WITHDRAW_LPT,
  BORROW_LPT,
  REPAY_LPT,
  SWAP_EXACT_IN,
  SWAP_EXACT_OUT
}

export type PositionUpdate = {
  positionUpdateType: PositionUpdateType
  zeroForOne: boolean
  liquidity: BigNumber
  lowerTick: number
  upperTick: number
  param0: BigNumber
  param1: BigNumber
}

export function concatPosition(positions: Position[]) {
  return positions.reduce((acc, item) => {
    return {
      subVaultId: 0,
      asset0: acc.asset0.add(item.asset0),
      asset1: acc.asset1.add(item.asset1),
      debt0: acc.debt0.add(item.debt0),
      debt1: acc.debt1.add(item.debt1),
      lpts: concatLPT(acc.lpts, item.lpts)
    }
  }, EmptyPosition)
}

export function plusPosition(a: Position, b: Position) {
  return {
    subVaultId: a.subVaultId,
    asset0: a.asset0.add(b.asset0),
    asset1: a.asset1.add(b.asset1),
    debt0: a.debt0.add(b.debt0),
    debt1: a.debt1.add(b.debt1),
    lpts: concatLPT(a.lpts, b.lpts)
  }
}

function concatLPT(a: LPT[], b: LPT[]) {
  const newLpts: LPT[] = a.map(aa => Object.assign({}, aa))

  b.forEach(lpt => {
    const index = a.findIndex(aItem => {
      if (
        lpt.isCollateral === aItem.isCollateral &&
        lpt.lowerTick === aItem.lowerTick &&
        lpt.upperTick === aItem.upperTick
      ) {
        return true
      } else {
        return false
      }
    })

    if (index >= 0) {
      newLpts[index].liquidity = newLpts[index].liquidity.add(lpt.liquidity)
    } else {
      newLpts.push(Object.assign({}, lpt))
    }
  })

  return [...newLpts]
}

export function getAmount0ForLiquidity(
  sqrtPriceA: BigNumber,
  sqrtPriceB: BigNumber,
  liquidity: BigNumber
) {
  if (sqrtPriceA.gt(sqrtPriceB)) {
    const t = sqrtPriceA
    sqrtPriceA = sqrtPriceB
    sqrtPriceB = t
  }

  return liquidity
    .mul(Q96)
    .mul(sqrtPriceB.sub(sqrtPriceA))
    .div(sqrtPriceB)
    .div(sqrtPriceA)
}

export function getAmount1ForLiquidity(
  sqrtPriceA: BigNumber,
  sqrtPriceB: BigNumber,
  liquidity: BigNumber
) {
  if (sqrtPriceA.gt(sqrtPriceB)) {
    const t = sqrtPriceA
    sqrtPriceA = sqrtPriceB
    sqrtPriceB = t
  }

  return liquidity.mul(sqrtPriceB.sub(sqrtPriceA)).div(Q96)
}

export function getAmountsForLiquidity(
  sqrtPrice: BigNumber,
  sqrtPriceA: BigNumber,
  sqrtPriceB: BigNumber,
  liquidity: BigNumber
) {
  let amount0 = BigNumber.from(0)
  let amount1 = BigNumber.from(0)

  if (sqrtPriceA.gt(sqrtPriceB)) {
    const t = sqrtPriceA
    sqrtPriceA = sqrtPriceB
    sqrtPriceB = t
  }

  if (sqrtPrice.lte(sqrtPriceA)) {
    amount0 = getAmount0ForLiquidity(sqrtPriceA, sqrtPriceB, liquidity)
  } else if (sqrtPrice.lt(sqrtPriceB)) {
    amount0 = getAmount0ForLiquidity(sqrtPrice, sqrtPriceB, liquidity)
    amount1 = getAmount1ForLiquidity(sqrtPriceA, sqrtPrice, liquidity)
  } else {
    amount1 = getAmount1ForLiquidity(sqrtPriceA, sqrtPriceB, liquidity)
  }

  return [amount0, amount1]
}

export function getAmounts(position: Position, sqrtPrice: BigNumber) {
  let amount0 = BigNumber.from(0)
  let amount1 = BigNumber.from(0)
  amount0 = amount0.add(position.asset0)
  amount1 = amount1.add(position.asset1)
  amount0 = amount0.sub(position.debt0)
  amount1 = amount1.sub(position.debt1)

  for (const lpt of position.lpts) {
    const amounts = getAmountsForLiquidity(
      sqrtPrice,
      getSqrtRatioAtTick(lpt.lowerTick),
      getSqrtRatioAtTick(lpt.upperTick),
      lpt.liquidity
    )
    if (lpt.isCollateral) {
      amount0 = amount0.add(amounts[0])
      amount1 = amount1.add(amounts[1])
    } else {
      amount0 = amount0.sub(amounts[0])
      amount1 = amount1.sub(amounts[1])
    }
  }

  return [amount0, amount1]
}

export function getDebtAmounts(position: Position, sqrtPrice: BigNumber) {
  let amount0 = BigNumber.from(0)
  let amount1 = BigNumber.from(0)
  amount0 = amount0.add(position.debt0)
  amount1 = amount1.add(position.debt1)

  for (const lpt of position.lpts) {
    const amounts = getAmountsForLiquidity(
      sqrtPrice,
      getSqrtRatioAtTick(lpt.lowerTick),
      getSqrtRatioAtTick(lpt.upperTick),
      lpt.liquidity
    )
    if (!lpt.isCollateral) {
      amount0 = amount0.add(amounts[0])
      amount1 = amount1.add(amounts[1])
    }
  }

  return [amount0, amount1]
}

export function getValue(
  position: Position,
  tick: number,
  isMarginZero: boolean
) {
  const amounts = getAmounts(position, getSqrtRatioAtTick(tick))
  const price = tickToPrice(tick)

  if (isMarginZero) {
    return amounts[1].mul(price).div(UNDERLYING_ONE).add(amounts[0])
  } else {
    return amounts[0].mul(price).div(UNDERLYING_ONE).add(amounts[1])
  }
}

export function getDelta(
  position: Position,
  tick: number,
  isMarginZero: boolean
) {
  const amounts = getAmounts(position, getSqrtRatioAtTick(tick))

  if (isMarginZero) {
    // token0 is USDC
    return amounts[1]
  } else {
    // token1 is USDC
    return amounts[0]
  }
}

/**
 * Calculates gamma scaled by 1e18
 * @param position The position to calculate gamma
 * @param tick The tick corresponding to the price used in the calculation
 * @param isMarginZero
 * @returns
 */
export function getGamma(
  position: Position,
  tick: number,
  isMarginZero = false
) {
  let gamma = BigNumber.from(0)
  const sqrtPrice = getSqrtRatioAtTick(tick)

  // p^(3/2) scaled by 1e6
  let intermediate = BigNumber.from(1)

  if (isMarginZero) {
    intermediate = Q96.mul(Q96)
      .div(sqrtPrice)
      .mul(Q96)
      .div(sqrtPrice)
      .mul(UNDERLYING_ONE)
      .mul('1000000')
      .div(sqrtPrice)
  } else {
    intermediate = sqrtPrice
      .mul(sqrtPrice)
      .div(Q96)
      .mul(sqrtPrice)
      .div(Q96)
      .mul(UNDERLYING_ONE)
      .mul('1000000')
      .div(Q96)
  }

  for (const lpt of position.lpts) {
    if (
      getSqrtRatioAtTick(lpt.lowerTick).lte(sqrtPrice) &&
      sqrtPrice.lte(getSqrtRatioAtTick(lpt.upperTick))
    ) {
      // baseGamma scaled by 1e18
      const baseGamma = lpt.liquidity
        .mul(UNDERLYING_ONE)
        .div(2)
        .div(intermediate)
        .div('1000000')
      if (lpt.isCollateral) {
        gamma = gamma.sub(baseGamma)
      } else {
        gamma = gamma.add(baseGamma)
      }
    }
  }

  return gamma
}

export function getLiquidity(
  neutralPoint: number,
  lowerTick: number,
  upperTick: number,
  amount: BigNumber
) {
  return BigNumber.from(
    maxLiquidityForAmounts(
      TickMath.getSqrtRatioAtTick(neutralPoint),
      TickMath.getSqrtRatioAtTick(lowerTick),
      TickMath.getSqrtRatioAtTick(upperTick),
      amount.toString(),
      0,
      true
    ).toString()
  )
}

export function getSqrtRatioAtTick(tick: number) {
  return BigNumber.from(TickMath.getSqrtRatioAtTick(tick).toString())
}

export function getTickAtSqrtRatio(sqrtPrice: BigNumber) {
  return TickMath.getTickAtSqrtRatio(JSBI.BigInt(sqrtPrice.toString()))
}

export function tickToPrice(tick: number) {
  const sqrtPrice = BigNumber.from(TickMath.getSqrtRatioAtTick(tick).toString())

  return sqrtPriceToPrice(sqrtPrice)
}

export function priceToTick(price: BigNumber) {
  const sqrtPrice = priceToSqrtPrice(price)

  return getTickAtSqrtRatio(sqrtPrice)
}

export function sqrtPriceToPrice(sqrtPrice: BigNumber) {
  return sqrtPrice.mul(sqrtPrice).div(Q96).mul(UNDERLYING_ONE).div(Q96)
}

export function priceToSqrtPrice(price: BigNumber) {
  const sqrtPrice = sqrt(price.mul(Q96).div(UNDERLYING_ONE).mul(Q96))

  return sqrtPrice
}
