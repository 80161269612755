import React, { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { metaMask } from '../../connectors'
import ArrowIcon from '../../assets/arrow.svg'
import ArbitrumIcon from '../../assets/arbitrum.svg'
import { usePrevious } from 'react-use'
import { switchNetwork } from '../../utils/switchNetwork'
import { useIsSupportedChain } from '../../hooks/network'
import { DropdownMenu, DropdownMenuItem } from './DropdownMenu'
import { DepositModal } from './DepositModal'
import { WithdrawModal } from './WithdrawModal'
import Modal from 'react-modal'

Modal.setAppElement('#root')

export const HeaderConnectButton = ({
  children
}: {
  children?: React.ReactNode
}) => {
  const { isActive } = useWeb3React()
  const [walletMenuOpened, setWalletMenuOpened] = useState(false)
  const [isMouseOnMenu, setIsMouseOnMenu] = useState(false)
  const [dropdownMenuSelected, setDropdownMenuSelected] = useState(
    DropdownMenuItem.NO_SELECTED
  )

  const [isOpen, setIsOpen] = useState(false)

  // The handler called when user try to close modal
  const onCloseModal = () => {
    setIsOpen(false)
  }

  return (
    <div className="w-40 h-14">
      <BaseConnectButton
        onClick={() => {
          setWalletMenuOpened(!walletMenuOpened)
        }}
        onMouseEnter={() => {
          setWalletMenuOpened(true)
        }}
        onMouseLeave={() => {
          setTimeout(() => {
            setWalletMenuOpened(false)
          }, 200)
        }}
      >
        {children}
      </BaseConnectButton>

      {isActive ? (
        <>
          <DropdownMenu
            isShow={walletMenuOpened || isMouseOnMenu}
            onMouseEnter={() => {
              setIsMouseOnMenu(true)
            }}
            onMouseLeave={() => {
              setTimeout(() => {
                setIsMouseOnMenu(false)
              }, 200)
            }}
            onSelected={async (item: number) => {
              if (item === DropdownMenuItem.DISCONNECT) {
                console.log('close', 1)
                if (metaMask.deactivate) {
                  console.log('close', 2)
                  await metaMask.deactivate()
                }
                await metaMask.resetState()
                localStorage.removeItem('predy.connected')
                setWalletMenuOpened(false)
              } else {
                setDropdownMenuSelected(item)
                setIsOpen(true)
              }
            }}
          />
          <Modal
            isOpen={isOpen}
            onRequestClose={onCloseModal}
            shouldCloseOnOverlayClick={true}
            style={{
              overlay: {
                backgroundColor: '#ffffff70'
              },
              content: {
                borderWidth: 0,
                backgroundColor: '#1f2126',
                width: 380,
                height: 432,
                margin: 'auto',
                padding: 24
              }
            }}
          >
            {dropdownMenuSelected === DropdownMenuItem.DEPOSIT ? (
              <DepositModal onClose={onCloseModal} />
            ) : dropdownMenuSelected === DropdownMenuItem.WITHDRAW ? (
              <WithdrawModal onClose={onCloseModal} />
            ) : (
              <div />
            )}
          </Modal>
        </>
      ) : (
        <div />
      )}
    </div>
  )
}

export const ConnectButton = ({ children }: { children?: React.ReactNode }) => {
  return (
    <button className="w-full h-10 final-gradient rounded-2xl text-xl font-normal">
      <BaseConnectButton>{children}</BaseConnectButton>
    </button>
  )
}

const BaseConnectButton = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave
}: {
  children?: React.ReactNode
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}) => {
  const { account, isActive } = useWeb3React()
  const [activateClicked, setActivateClicked] = useState(false)
  const prevClicked = usePrevious(activateClicked)
  const supported = useIsSupportedChain()

  useEffect(() => {
    if (!prevClicked && activateClicked && isActive && account) {
      // To tract user connection, please put code here
      localStorage.setItem('predy.connected', 'true')
    }
  }, [activateClicked, isActive]) // eslint-disable-line

  const onConnectClicked = async () => {
    await switchNetwork()
    await metaMask.activate()
    // await activate(injected)
    setActivateClicked(true)
  }

  if (isActive && account && supported) {
    return (
      <>
        <div
          className="w-full h-full background-black text-white rounded-md flex flex-row items-center"
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <img className="mr-1 basis-1/6 my-auto" src={ArbitrumIcon} />
          <div className="basis-5/6 my-auto">
            {account.slice(0, 6)}…{account.slice(38)}
          </div>
          <img src={ArrowIcon} />
        </div>
      </>
    )
  }

  return (
    <div
      className="w-full h-full background-black text-white rounded-md flex justify-center items-center cursor-pointer"
      onClick={onConnectClicked}
    >
      {supported ? (children ? children : 'Connect Wallet') : 'Switch Network'}
    </div>
  )
}
