import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { ERRORS } from '../../../constants/messages'
import pendingStore from '../../../store/pending'
import PendingIndicator from '../../common/PendingIndicator'
import { PrimaryButton } from '../../common/Button'
import { ConnectButton } from '../../header/ConnectButton'
import { reasonToErrorMessage } from '../../../utils/error'

type Props = {
  hasEnoughUSDC: boolean
  extraError: string | null
  isMarginEnough: boolean
  isSubVaultsLoaded: boolean
  isTradeAmountsZero: boolean
  isEnoughRangeSelected: boolean
  isPositionHasMaxLPTs: boolean
  onTrade: () => void
}

export const TradeButtonArea = ({
  hasEnoughUSDC,
  extraError,
  isMarginEnough,
  isSubVaultsLoaded,
  isTradeAmountsZero,
  isEnoughRangeSelected,
  isPositionHasMaxLPTs,
  onTrade
}: Props) => {
  const { isActive } = useWeb3React()
  const { isPendingTx } = pendingStore()

  const buyButtonEnabled =
    hasEnoughUSDC &&
    extraError === null &&
    isMarginEnough &&
    isActive &&
    !isPendingTx &&
    isSubVaultsLoaded &&
    !isTradeAmountsZero &&
    isEnoughRangeSelected &&
    !isPositionHasMaxLPTs

  return (
    <div>
      <div className="flex items-center justify-center">
        {isActive ? (
          <PrimaryButton disabled={!buyButtonEnabled} onClick={onTrade}>
            {isPendingTx ? <PendingIndicator /> : 'Confirm'}
          </PrimaryButton>
        ) : (
          <ConnectButton />
        )}
      </div>

      {!hasEnoughUSDC ? (
        <div className="mt-2 text-xs text-red">
          {ERRORS.INSUFFICIENT_BALANCE}
        </div>
      ) : (
        <div />
      )}
      {extraError && hasEnoughUSDC ? (
        <div className="mt-2 text-xs text-red">
          {reasonToErrorMessage(extraError)}
        </div>
      ) : (
        <div />
      )}
      {!isMarginEnough ? (
        <div className="mt-2 text-xs text-red">{ERRORS.LESS_MARGIN}</div>
      ) : (
        <div />
      )}
      {isPositionHasMaxLPTs ? (
        <div className="mt-2 text-xs text-red">
          {ERRORS.REACH_LPT_NUMBER_LIMIT}
        </div>
      ) : (
        <div />
      )}
      {!isActive ? (
        <div className="mt-2 text-xs text-red">{ERRORS.CONNECT_NETWORK}</div>
      ) : (
        <div />
      )}
      {!isSubVaultsLoaded ? (
        <div className="mt-2 text-xs text-white">
          {ERRORS.SUBVAULTS_ARE_LOADING_NOW}
        </div>
      ) : (
        <div />
      )}
      {!isEnoughRangeSelected ? (
        <div className="mt-2 text-xs text-red">
          {ERRORS.RANGE_MUST_BE_SELECTED}
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}
